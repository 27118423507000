<template>
  <div
    class="primary d-flex flex-column"
    style="width: 100%; max-width: 1200px"
  >
    <div class="d-flex px-2" v-if="!loader">
      <v-btn icon @click="$router.push('/pois')">
        <v-icon color="secondary">mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="secondary" size="60">
      </v-progress-circular>
    </div>

    <div
      v-else-if="!loader && pois.length > 0"
      v-for="(poi, i) in pois"
      :key="i"
      class="d-flex flex-column px-4"
      style="width: 100%; cursor: pointer"
    >
      <div
        @click="openDialog(poi)"
        class="d-flex justify-start align-center text--text route body-1 mb-2"
      >
        <span class="mr-auto">{{ poi.name }}</span>
        <v-btn icon color="secondary">
          <v-icon> mdi-arrow-right</v-icon>
        </v-btn>
      </div>

      <v-divider
        v-if="i != pois.length - 1"
        class="mb-2"
        style="width: 100% !important"
        color="white"
      ></v-divider>
    </div>

    <span
      class="px-4 white--text body-1 font-weight-thin"
      v-else-if="!loader && pois.length == 0"
    >
      {{ $t("validation.no_pois") }}
    </span>

    <v-dialog
      persistent
      v-model="dialog.open"
      max-width="100%"
      fullscreen
      style="z-index: 900 !important"
    >
      <PoiDialog :ppoi="dialog.poi" v-if="dialog.open" @close="closeDialog" />
    </v-dialog>
  </div>
</template>

<script>
import PoiDialog from "@/components/PoiDialog.vue";

import axios from "axios";
import { mapMutations } from "vuex";
export default {
  components: {
    PoiDialog,
  },

  async created() {
    try {
      const categories = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/categories`
      );

      const areas = await axios.get(`${process.env.VUE_APP_BASE_URL}/areas`);

      let pois = [];
      if (this.filters.category == 17) {
        pois = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/areas/${this.filters.island}/pois`
        );
        this.pois = pois.data.pois.filter((el) => {
          if (el.category_id != 21) return el;
        });
      } else {
        pois = await axios.get(`${process.env.VUE_APP_BASE_URL}/pois`, {
          params: {
            area: this.filters.island,
            category:
              this.filters.category != 17 ? this.filters.category : null,
          },
        });
        this.pois = pois.data.filter((el) => {
          if (el.category_id != 21) return el;
        });
      }

      const sortedAreas = areas.data.areas.sort((a, b) => {
        return a.weight - b.weight;
      });

      this.categories = categories.data.filter((el) => {
        if (el.id != 21) return el;
      });

      this.islands = sortedAreas;

      this.loader = false;
      //todo -> add category query param
    } catch (e) {
      this.loader = false;
      console.log(e);
    }
  },

  data() {
    return {
      showFilters: false,
      loader: true,
      pois: [],
      islands: [],

      dialog: {
        open: false,
        poi: null,
      },
      categories: [],
    };
  },

  computed: {
    filters: {
      get() {
        return this.$store.state.pois.filters;
      },
      set(newValue) {
        this.setPoisFilters(newValue);
      },
    },
  },

  methods: {
    ...mapMutations(["setPoisFilters"]),

    async filterApply() {
      try {
        this.loader = true;

        this.pois = [];
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/pois`, {
          params: {
            area: this.filters.island,
            category:
              this.filters.category != 17 ? this.filters.category : null,
          },
        });
        this.pois = res.data;
        this.loader = false;
      } catch (e) {
        console.log(e);
      }
    },

    openDialog(poi) {
      this.dialog = {
        open: true,
        poi,
      };
    },

    closeDialog() {
      this.dialog = {
        open: false,
        poi: null,
      };
    },
  },
};
</script>

<style scoped lang="scss"></style>
