<template>
  <div
    class="component-wrapper d-flex flex-column"
    style="width: 100%; max-width: 1200px"
  >
    <div class="d-flex flex-column px-2 mb-2">
      <div class="d-flex flex-column">
        <v-btn icon @click="$router.push('/settings')">
          <v-icon color="secondary">mdi-arrow-left</v-icon>
        </v-btn>
        <h1 class="text--text font-weight-bold text-h5">
          {{ $t("info_settings.terms") }}
        </h1>
      </div>

      <v-divider class="mb-2"></v-divider>
      <div class="d-flex flex-column px-4" v-html="$t('terms')"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss"></style>
