<template>
  <div>
    <h1>Privacy Policy</h1>
    <p><strong>Introduction</strong></p>
    <p>
      Explore Ionian Islands ("we", "our", or "us") is committed to protecting
      your privacy. This Privacy Policy explains how we collect, use, disclose,
      and safeguard your information when you use our mobile application (the
      "App"). Please read this privacy policy carefully. If you do not agree
      with the terms of this privacy policy, please do not access the App.
    </p>

    <h2>Information We Collect</h2>
    <p><strong>1. Personal Data:</strong></p>
    <p>
      We do not collect any personal data that identifies you as an individual.
    </p>

    <p><strong>2. Location Data:</strong></p>
    <p>
      Our App collects precise location data to provide location-based services.
      This data is used to optimize battery life and improve tracking accuracy
      by automatically turning location services on and off based on user
      activity.
    </p>
    <p>
      The App uses the Motion API to detect user activity and manage location
      services accordingly.
    </p>

    <p><strong>3. Background Geolocation:</strong></p>
    <p>
      We utilize background location services to enhance the user experience by
      comparing their location to predefined points of interest during an active
      'route' in our app.
    </p>
    <p>
      When the user approaches a point of interest, they receive a notification
      to inform them of their proximity.
    </p>
    <p>
      Upon visiting all the points of interest along the route, the user is
      awarded an achievement, celebrating their accomplishment and marking the
      route as complete.
    </p>

    <h2>Use of Your Information</h2>
    <p>We use the information we collect in the following ways:</p>
    <ul>
      <li>
        To provide and maintain our services, including location-based services.
      </li>
      <li>To improve our App and enhance user experience.</li>
      <li>
        To monitor and analyze usage and trends to improve your experience with
        the App.
      </li>
    </ul>

    <h2>Handling of Location Data</h2>
    <p>
      The location data collected, including background geolocation data, is
      used solely for providing location-based services within the App.
    </p>
    <p>We do not share location data with third parties.</p>
    <p>
      We do not send location data to any server or save it locally. The data is
      processed in real-time within the App to compare the user's location to
      predefined points of interest.
    </p>
    <p>
      Location data is stored securely and is only accessible by the App for the
      purpose of delivering the services described.
    </p>

    <h2>Sharing Your Information</h2>
    <p>
      We do not share your personal data or location data with any third
      parties, except as required by law or to protect our rights.
    </p>

    <h2>Security of Your Information</h2>
    <p>
      We use administrative, technical, and physical security measures to help
      protect your personal information and location data. While we have taken
      reasonable steps to secure the personal information you provide to us,
      please be aware that despite our efforts, no security measures are perfect
      or impenetrable, and no method of data transmission can be guaranteed
      against any interception or other type of misuse.
    </p>

    <h2>Changes to This Privacy Policy</h2>
    <p>
      We may update this Privacy Policy from time to time. We will notify you of
      any changes by posting the new Privacy Policy on this page. You are
      advised to review this Privacy Policy periodically for any changes.
    </p>
  </div>
</template>

<script>
export default {
  data() {},
};
</script>

<style></style>
