import Area from '../views/Area/Area.vue'

import Review from '../views/Area/Review.vue'

import Routes from '../views/Area/Routes/Routes.vue'
import Route from '../views/Area/Routes/Route.vue'

import Pois from '../views/Area/Pois/Pois.vue'
import PoiMap from '../views/Area/Map/Map.vue'

import Map from '../views/Area/Map/Map.vue'



export default [
    {
        path: '/islands/area/:area_id',
        component: Area,
        children: [
            {
                path: '/',
                component: Review
            },

            {
                path: 'routes',
                component: Routes,
            },

            {
                path: 'routes/:route_id',
                component: Route,
            },

            {
                path: 'pois',
                component: Pois,
            },


            {
                path: 'pois/:poi_id/map',
                component: PoiMap,
            },

            {
                path: 'map',
                component: Map,
            },







        ]
    },
]