<template>
  <div class="primary" style="width: 100%; max-width: 1200px">
    <h1 class="text--text font-weight-bold text-h5 px-2">
      {{ $t("videos.title") }}
    </h1>
    <span class="caption text--text mb-2 px-2">
      {{ $t("videos.step1") }}
    </span>
    <div
      v-for="(video, i) in videos"
      :key="i + video"
      class="elevation-3 mt-4 d-flex flex-column justify-center primary"
      style="width: 100%"
    >
      <youtube
        v-if="video.videoId != null"
        class="iframe mb-2"
        :video-id="video.videoId"
        :player-width="'100%'"
        :player-height="$vuetify.breakpoint.mobile ? '250' : '600'"
        :player-vars="{ autoplay: 0, rel: 0, start: 0 }"
      ></youtube>

      <div
        v-else
        style="width: 100%; height: 150px"
        class="d-flex align-center justify-center"
      >
        <v-icon size="100" color="secondary"> mdi-video-box-off </v-icon>
      </div>

      <div
        class="body-1 px-2 mb-2 font-weight-bold text--text"
        v-html="video.title"
      ></div>

      <div class="body-2 px-2 mb-2 text--text" v-html="video.description"></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    videos() {
      return this.$t("all_videos");
    },
  },
};
</script>

<style scoped lang="scss">
.iframe {
  width: 100% !important;
  height: 600px;
  aspect-ratio: 4/3 !important;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .iframe {
    height: 250px;
  }
}
</style>
