<template>
  <div class="d-flex flex-column mt-2" style="width: 100%; max-width: 1200px">
    <div class="d-flex flex-column">
      <v-btn icon @click="$router.push('/settings')">
        <v-icon color="secondary">mdi-arrow-left</v-icon>
      </v-btn>
      <h1 class="text--text font-weight-bold text-h5 ml-2">
        {{ $t("info_settings.about") }}
      </h1>
    </div>

    <v-divider class="mb-2"></v-divider>
    <div class="d-flex flex-column px-4">
      <p class="body-2 mt-2" v-if="mobileLang.code == 'el'">
        Η εφαρμογή αυτή δημιουργήθηκε στο πλαίσιο της Πράξης CULTURALION στο
        Επιχειρησιακό Πρόγραμμα «Ιόνια Νησιά 2014-2020».
        <br />
        <br />
        <b> Τίτλος Πράξης: </b>«Σχεδιασμός Νησιωτικών και Διανησιωτικών
        Θεματικών Πολιτιστικών Διαδρομών και Ανάπτυξη Εφαρμογής Διαδραστικής
        Τουριστικής Ξενάγησης των Ιονίων Νήσων»
        <br />
        <br />
        <b>Εταίροι της Πράξης:</b>Περιφερειακή Ένωση Δήμων Ιονίων Νήσων (ΠΕΔ-ΙΝ)
        και Εφορεία Αρχαιοτήτων Κέρκυρας.
        <br />
        Η πράξη συγχρηματοδοτήθηκε από το Ευρωπαϊκό Ταμείο Περιφερειακής
        Ανάπτυξης (ΕΤΠΑ).
        <br />
        <br />

        <b> Σχετικά με το CULTURALION: </b>
        <br />
        Το έργο αναπτύσσει για πρώτη φορά στα Ιόνια Νησιά τριάντα συν μία (30+1)
        ολοκληρωμένες νησιωτικές & διανησιωτικές πολιτιστικές διαδρομές,
        παρέχοντας παράλληλα, με την ανάπτυξη μίας καινοτόμου ψηφιακής
        εφαρμογής, τη δυνατότητα στους επισκέπτες να αυτο-ξεναγούνται στις
        διαδρομές από τις κινητές τους συσκευές ή από τον υπολογιστή τους. Το
        έργο φιλοδοξεί να απογειώσει το περιφερειακό τουριστικό προϊόν και να
        παρουσιάσει τα Ιόνια Νησιά ως ένα μοναδικό εναλλακτικό διεθνή τουριστικό
        προορισμό.
      </p>

      <p class="body-2 mt-2" v-else>
        This app was created within the framework of the CULTURALION Project of
        the Regional Operational Programme “Ionian Islands 2014-2020.”

        <br />
        <br />
        <b> Project Title: </b> "Designing Insular and Cross-Insular Thematic
        Cultural Routes and Development of Interactive Touristic Guiding
        Application of Ionian Islands."

        <br />
        <br />
        <b>Project Partners:</b> Regional Union of Municipalities of Ionian
        Islands (PED-IN) and Ephorate of Antiquities of Corfu.
        <br />
        The action was co-financed by the European Regional Development Fund
        (ERDF)
        <br />
        <br />

        <b> About CULTURALION </b>
        <br />
        The project develops, for the first time in the Ionian Islands, thirty
        plus one (30+1) integrated insular and Cross-Insular cultural routes,
        while also providing, through the development of an innovative digital
        application, visitors with the opportunity to self-guide along the
        routes from their mobile devices or computers. The project aims to
        enhance the regional tourism product and present the Ionian Islands as a
        unique alternative international tourist destination.
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },

  computed: {
    ...mapState({
      mobileLang: (state) => state.base.mobileLang,
    }),
  },
};
</script>

<style></style>
