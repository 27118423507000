<template>
  <v-overlay color="primary" opacity="1">
    <div class="d-flex flex-column align-center">
      <span class="logo mb-4">
        <img
          src="@/assets/logo.png"
          alt=""
          style="width: 300px; height: 180px"
        />
      </span>
      <v-progress-circular
        size="100"
        indeterminate
        color="white"
      ></v-progress-circular>
    </div>
  </v-overlay>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.logo {
  filter: brightness(0) invert(1);
}
</style>
