<template>
  <v-card height="100%" width="100%" color="primary" class="d-flex flex-column">
    <v-btn icon @click="$emit('close')">
      <v-icon color="white">mdi-close</v-icon>
    </v-btn>

    <p class="pa-2 white--text">
      To install the voice data for your selected language on your device,
      please follow these steps:<br /><br />

      1. Open your device's <b>'Settings'</b> menu. <br /><br />
      2. Navigate to <b>'General Management'</b>. <br /><br />
      3. Locate and tap on the <b>'Text to Speech'</b> option. <br /><br />
      4. Select <b>'Preferred engine'</b>.<br /><br />
      5. Choose your desired text to speech engine option. <br /><br />
      6. Tap the gear icon next to the selected option. <br /><br />
      7. Press the <b>'Install voice data'</b> tab. <br /><br />
      8. Set your desired language for the voice data installation.
      <br /><br />

      The installation instructions may be slightly different in your device.
    </p>
  </v-card>
</template>

<script>
export default {};
</script>

<style style="scoped">
</style>