import axios from "axios";
export default {
  state: {
    areas: [],
    area: null,
    currentArea: null,
  },

  mutations: {
    getAreas(state, areas) {
      state.areas = areas;
    },

    getArea(state, area) {
      state.area = area;
    },

    setCurrentArea(state, currentArea) {
      state.currentArea = currentArea;
    },
  },

  actions: {
    async getAreas({ commit }) {
      try {
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/areas`);
        const sortedAreas = res.data.areas.sort((a, b) => {
          return a.weight - b.weight;
        });

        commit("getAreas", sortedAreas);
      } catch (e) {
        throw e;
      }
    },
  },
};
