<template>
  <div class="d-flex flex-column" style="width: 100%; max-width: 1200px">
    <div v-if="loader"></div>
    <router-view v-else :key="$route.fullPath"></router-view>

    <v-bottom-navigation app class="text-uppercase primary">
      <v-btn v-for="(item, i) in bottomNavigation" :key="i" :to="item.path">
        <span class="text--text caption">{{ item.label }}</span>
        <v-icon color="secondary"> {{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  async created() {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/areas/${this.$route.params.area_id}`
      );
      this.area = res.data.area;

      this.setCurrentArea(this.area);

      this.loader = false;
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      area: null,
      route: null,
      loader: true,
    };
  },

  computed: {
    ...mapState({
      currentRoute: (state) => state.routes.currentRoute,
      currentPoi: (state) => state.pois.currentPoi,
    }),

    bottomNavigation() {
      return [
        {
          label: this.$t("islands.routes_button"),
          path: `/islands/area/${this.$route.params.area_id}/routes`,
          icon: "mdi-map-marker-path",
        },
        {
          label: this.$t("islands.pois_button"),
          path: `/islands/area/${this.$route.params.area_id}/pois`,
          icon: "mdi-map-marker-multiple",
        },

        {
          label: this.$t("islands.map_button"),
          path: `/islands/area/${this.$route.params.area_id}/map`,
          icon: "mdi-map-outline",
        },
      ];
    },
  },

  methods: {
    ...mapMutations(["setCurrentArea"]),
  },
};
</script>

<style scoped lang="scss">
.v-btn--active > .v-btn__content .v-icon {
  color: #50a6c8 !important;
}
</style>
