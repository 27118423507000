<template>
  <div
    class="primary d-flex flex-column"
    style="width: 100%; max-width: 1200px"
  >
    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="white" size="60">
      </v-progress-circular>
    </div>
    <div v-else>
      <h1 class="text--text font-weight-bold text-h5 px-2">
        {{ $t("gastronomy.title") }}
      </h1>
      <span class="caption text--text mb-3 px-2">
        {{ $t("gastronomy.step1") }}
      </span>
      <!-- <v-icon size="50"> mdi-checkbox-multiple-outline</v-icon>
          <span> Όλες </span> -->
      <v-container>
        <v-row>
          <v-col
            v-for="(island, i) in islands"
            :key="i"
            cols="6"
            class="card d-flex flex-column"
            @click="onIslandSelect(island)"
          >
            <div class="card-2 d-flex flex-column align-center justify-center">
              <v-img
                :src="returnImage(island)"
                width="100px"
                height="60px"
              ></v-img>
              <!-- <v-icon color="white" size="45">{{ returnImage(island) }}</v-icon> -->
              <span class="secondary--text body-1 text-center font-weight-bold">
                {{
                  island.name.charAt(0).toUpperCase() +
                  island.name.slice(1).toLowerCase()
                }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  async created() {
    this.loader = true;
    const gastronomy = await axios.get(
      `${process.env.VUE_APP_BASE_URL}/gastronomy`
    );
    const areas = await axios.get(`${process.env.VUE_APP_BASE_URL}/areas`);
    const sortedAreas = areas.data.areas.sort((a, b) => {
      return a.weight - b.weight;
    });
    this.initialIslands = sortedAreas;

    const islandsWithGastronomy = this.initialIslands.filter((island) => {
      return gastronomy.data.some((poi) => poi.area_id === island.id);
    });
    this.islands = [...islandsWithGastronomy];
    this.loader = false;
  },

  data() {
    return {
      loader: false,
      initialIslands: [],
      islands: [],
    };
  },

  methods: {
    ...mapMutations(["setGastronomyFilters"]),

    onIslandSelect(island) {
      this.setGastronomyFilters({ island: island.id });

      this.$router.push(`/gastronomy/results`);
    },

    returnImage(island) {
      switch (island.id) {
        case 5:
          return require("@/assets/island-icons/Kerkira.png");
        case 8:
          return require("@/assets/island-icons/Ithaki.png");
        case 6:
          return require("@/assets/island-icons/Kefalonia.png");
        case 7:
          return require("@/assets/island-icons/Lefkada.png");
        case 13:
          return require("@/assets/island-icons/Meganisi.png");
        case 9:
          return require("@/assets/island-icons/Paxos.png");
        case 4:
          return require("@/assets/island-icons/Zakinthos.png");
        default:
          return "mdi-island";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  height: 150px !important;
  width: 100px !important;
}

.card-2 {
  height: 100% !important;
  width: 100% !important;
  background: #e3f3fa;

  border-radius: 8px;
  cursor: pointer;
}
</style>
