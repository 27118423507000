<template>
  <div
    class="white d-flex flex-column align-center pa-4"
    style="width: 100%; max-width: 1200px"
  >
    <div
      v-if="!loader"
      class="d-flex align-self-start mb-2"
      :style="'width:100%'"
    >
      <span class="text--text font-weight-bold text-h6">
        {{ $t("pois.title") }}
      </span>

      <v-btn
        icon
        color="secondary"
        class="ml-auto"
        @click="$router.push(`/islands/area/${$route.params.area_id}`)"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </div>
    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="secondary" size="60">
      </v-progress-circular>
    </div>
    <div
      v-else-if="!loader && pois.length > 0"
      class="px-4"
      style="width: 100%"
    >
      <v-chip
        @click:close="
          filterApply();
          currentCategory = null;
        "
        v-if="!loader && currentCategory != null"
        close
        close-icon="mdi-close"
        color="secondary"
        class="my-2 white--text"
      >
        {{ poiCategory(currentCategory.id).name }}
      </v-chip>
      <div
        v-for="(poi, i) in pois"
        :key="i"
        class="d-flex flex-column"
        style="width: 100%; max-width: 1200px; cursor: pointer"
      >
        <div
          @click="openDialog(poi)"
          class="d-flex justify-start align-center text--text route font-weight body-1 mb-2"
        >
          <span class="mr-auto">{{ poi.name }}</span>
          <v-btn icon color="secondary">
            <v-icon> mdi-arrow-right</v-icon>
          </v-btn>
        </div>

        <v-divider
          v-if="i != pois.length - 1"
          class="mb-2"
          style="width: 100% !important"
          color="white"
        ></v-divider>
      </div>

      <span
        class="px-4 white--text body-1 font-weight-thin"
        v-if="!loader && pois.length == 0"
      >
        {{ $t("validation.no_pois") }}
      </span>
    </div>
    <v-dialog
      persistent
      v-model="dialog.open"
      max-width="100%"
      fullscreen
      style="z-index: 900 !important"
    >
      <PoiDialog :ppoi="dialog.poi" v-if="dialog.open" @close="closeDialog" />
    </v-dialog>

    <v-bottom-sheet v-model="sheet" inset style="position: relative" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="position: fixed; bottom: 80px; right: 15px"
          color="white"
          fab
          dark
          medium
        >
          <v-icon size="30" color="secondary" v-bind="attrs" v-on="on"
            >mdi-tune</v-icon
          >
        </v-btn>
      </template>

      <v-card color="white" height="auto" class="px-6 pb-16 mb-4">
        <div class="text-h5 font-weight-bold pt-6">
          {{ $t("general.categories_label") }}
        </div>
        <v-divider class="my-4"></v-divider>
        <div
          v-for="(category, i) in filteredCategories"
          :key="i + category.name"
          @click="filterApply(category)"
          class="mb-4"
          style="cursor: pointer"
        >
          <span
            :class="
              currentCategory && currentCategory.id == category.id
                ? 'font-weight-bold'
                : 'font-weight-regular'
            "
            class="body-1 d-flex align-center"
          >
            <span class="mr-auto">
              {{ category.name }}
            </span>
            <img
              v-if="currentCategory && currentCategory.id == category.id"
              class="check-icon"
              src="@/assets/icons/check.png"
            />
          </span>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import PoiDialog from "@/components/PoiDialog.vue";

import axios from "axios";
export default {
  components: {
    PoiDialog,
  },

  async created() {
    try {
      const categories = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/categories`
      );
      this.categories = categories.data.filter((el) => {
        if (el.id != 21) return el;
      });
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/areas/${this.$route.params.area_id}/pois`
      );
      this.pois = res.data.pois.filter((el) => {
        if (el.category_id != 21) return el;
      });
      this.initialPois = [...this.pois];
      this.loader = false;
    } catch (e) {
      throw e;
    }
  },

  data() {
    return {
      loader: true,
      pois: [],
      initialPois: [],
      categories: [],
      currentCategory: null,
      sheet: false,
      dialog: {
        open: false,
        poi: null,
      },
    };
  },
  computed: {
    filteredCategories() {
      const categoryIds = Array.from(
        new Set(this.initialPois.map((obj) => obj.category_id))
      );
      const matchingCategories = this.categories.filter((category) =>
        categoryIds.includes(category.id)
      );
      return matchingCategories;
    },
  },

  methods: {
    poiCategory(category_id) {
      return this.categories.find((el) => el.id == category_id);
    },

    async filterApply(category) {
      this.loader = true;
      this.currentCategory = category;

      this.sheet = false;
      let res = [];

      res = await axios.get(`${process.env.VUE_APP_BASE_URL}/pois`, {
        params: {
          area: this.$route.params.area_id,
          category: category?.id,
        },
      });

      this.pois = res.data.filter((el) => {
        if (el.category_id != 21) return el;
      });

      this.loader = false;
    },

    openDialog(poi) {
      this.dialog = {
        open: true,
        poi,
      };
    },

    closeDialog() {
      this.dialog = {
        open: false,
        poi: null,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.route {
  width: 100%;
}
</style>
