export default {
  state: {
    filters: {
      island: null,
    },
  },

  mutations: {
    setGastronomyFilters(state, filters) {
      state.filters = filters;
    },
  },
};
