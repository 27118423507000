<template>
  <div>
    <title>Account Deletion</title>

    <div class="container">
      <h1>Account Deletion</h1>
      <p>
        We understand that you may wish to delete your account with
        <strong>Explore Ionian Islands</strong>. Below are the steps you need to
        follow to request the deletion of your account, as well as important
        information about what happens to your data.
      </p>

      <h2>Steps to Request Account Deletion:</h2>
      <ol>
        <li>
          <strong>Log In:</strong> Please log into your account on the
          <strong>Explore Ionian Islands</strong> application.
        </li>
        <li>
          <strong>Navigate to Account Settings:</strong> Go to the 'Settings'
          section, which you can find by clicking the 'Settings' option in the
          main menu.
        </li>
        <li>
          <strong>Request Deletion:</strong> Select the 'Delete Account' option
          and follow the on-screen instructions to confirm your request.
        </li>
        <li>
          <strong>Confirmation:</strong> You will receive a confirmation email.
          Please click on the link provided in the email to finalize the
          deletion of your account.
        </li>
      </ol>

      <h2>Data Deletion Policy</h2>
      <p>
        When you request the deletion of your account, the following data will
        be permanently deleted:
      </p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Your name, email address, and
          any other personal details provided.
        </li>

        <li>
          <strong>Usage Data:</strong> Data related to your usage of the app,
          including activity logs and preferences.
        </li>
      </ul>

      <h2>Data Retention Policy</h2>
      <p>
        Certain types of data may be retained for a specified period to comply
        with legal obligations or for legitimate business purposes:
      </p>
      <ul>
        <li>
          <strong>Support Correspondence:</strong> Any communications with our
          support team may be kept for up to <strong>[Y]</strong> years to
          ensure we can address any follow-up queries or issues.
        </li>
      </ul>
      <p>
        After the specified retention period, all retained data will be
        permanently deleted from our systems.
      </p>

      <h2>Additional Information</h2>
      <p>
        Please note that once your account is deleted, it cannot be recovered.
        If you have any questions or need assistance with the deletion process,
        please contact our support team at
        <strong>info@ped-in.gr</strong>.
      </p>
      <p>
        We value your privacy and are committed to protecting your personal
        information. For more details, please refer to our
        <a href="#">Privacy Policy</a>.
      </p>

      <div class="contact">
        <p>Thank you for using <strong>Explore Ionian Islands</strong>.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      email: null,
      password: null,
      loader: false,
    };
  },

  methods: {
    ...mapActions(["login"]),

    async onSubmit() {
      try {
        this.loader = true;
        await this.login({
          email: this.email,
          password: this.password,
          device_name: "phone",
        });

        this.loader = false;

        this.$router.push("/islands");
      } catch (e) {
        this.loader = false;

        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
h1 {
  text-align: center;
  color: #444;
}
h2 {
  color: #444;
}
p {
  margin-bottom: 20px;
}
.contact {
  text-align: center;
  margin-top: 20px;
}
</style>
