<template>
  <div class="component-wrapper d-flex flex-column align-center">
    <v-btn
      @click="$router.push('/login')"
      icon
      color="secondary"
      class="align-self-start"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-form
      v-if="step == 1"
      @submit.prevent="onSendEmail"
      this.loader="false;"
      class="d-flex flex-column align-center mt-7"
      style="width: 80vw"
    >
      <h1 class="secondary--text font-weight-bold text-h5" style="width: 272px">
        {{ $t("reset.title") }}
      </h1>
      <span style="width: 272px" class="caption grey--text text--darken-3 mb-4">
        {{ $t("reset.subtitle") }}
      </span>

      <v-text-field
        v-model="email"
        solo
        type="email"
        color="primary"
        style="width: 272px"
        :error-messages="emailErrors"
      >
        <template v-slot:label>
          <div class="body-2">
            {{ $t("reset.email_label") }}
          </div>
        </template>
      </v-text-field>

      <v-btn
        color="secondary body-2 "
        type="submit"
        style="width: 272px"
        :loading="buttonLoader"
      >
        {{ $t("reset.button") }}
      </v-btn>
    </v-form>

    <v-form
      v-if="step == 2"
      @submit.prevent="onSetEmailPassword"
      class="d-flex flex-column mt-7"
    >
      <h1 class="secondary--text font-weight-bold text-h5">
        {{ $t("reset.title") }}
      </h1>
      <span style="width: 272px" class="caption grey--text text--darken-3 mb-4">
        {{ $t("reset.subtitle") }}
      </span>

      <v-otp-input
        v-model="otp"
        length="6"
        type="text"
        width="300px"
        style="width: 300px"
        :error-messages="otpErrors"
      ></v-otp-input>

      <v-btn :loading="ste" color="secondary body-2" type="submit">
        {{ $t("general.next") }}
      </v-btn>
    </v-form>

    <v-form
      v-if="step == 3"
      @submit.prevent="onSetNewPassword"
      class="d-flex flex-column mt-7"
    >
      <h1 class="secondary--text font-weight-bold text-h5">
        {{ $t("reset.title") }}
      </h1>
      <span style="width: 272px" class="caption grey--text text--darken-3 mb-4">
        {{ $t("reset.subtitle") }}
      </span>

      <v-text-field
        v-model="password"
        solo
        type="password"
        color="primary"
        :error-messages="passwordErrors"
      >
        <template v-slot:label>
          <div class="body-2">
            {{ $t("register.password_label") }}
          </div>
        </template>
      </v-text-field>

      <v-text-field
        v-model="confirmPassword"
        solo
        type="password"
        color="primary"
        :error-messages="confirmPasswordErrors"
      >
        <template v-slot:label>
          <div class="body-2">
            {{ $t("register.password_confirm_label") }}
          </div>
        </template>
      </v-text-field>

      <v-btn color="secondary body-2" type="submit" :loading="buttonLoader">
        {{ $t("reset.button") }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  data() {
    return {
      otp: null,
      password: null,
      confirmPassword: null,
      email: null,
      step: 1,
      buttonLoader: false,
    };
  },

  validations: {
    email: {
      required,
      email,
    },

    otp: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },

    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(12),
    },

    confirmPassword: {
      required,
      sameAs: sameAs("password"),
    },
  },

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) errors.push("Required field");
      if (!this.$v.email.email)
        errors.push("Please enter a valid email address");
      return errors;
    },

    otpErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required)
        errors.push(this.$t("validation.required"));
      if (!this.$v.password.minLength)
        errors.push(this.$t("validation.password"));
      if (!this.$v.password.maxLength)
        errors.push(this.$t("validation.password"));
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required)
        errors.push(this.$t("validation.required"));
      if (!this.$v.password.minLength)
        errors.push(this.$t("validation.password"));
      if (!this.$v.password.maxLength)
        errors.push(this.$t("validation.password"));
      return errors;
    },

    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      if (!this.$v.confirmPassword.required)
        errors.push(this.$t("validation.required"));

      if (!this.$v.confirmPassword.sameAs)
        errors.push(this.$t("validation.confirm_password"));

      return errors;
    },
  },

  methods: {
    async onSendEmail() {
      this.$v.$touch();

      if (this.$v.email.$invalid) return;
      try {
        this.buttonLoader = true;
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/user/forgot-password`,
          {
            email: this.email,
          }
        );
        this.step = 2;
        this.$v.$reset();
      } catch (e) {
        this.$store.commit("toggleSnackbar", {
          open: true,
          text: "Invalid Email",
          color: "error",
        });
      }

      this.buttonLoader = false;
    },

    async onSetEmailPassword() {
      this.$v.$touch();

      if (this.$v.otp.$invalid) return;
      try {
        this.buttonLoader = true;

        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/user/temp-reset`,
          {
            email: this.email,
            password: this.otp,
          }
        );
        if (res.data.message == "The temporary password is correct.") {
          this.step = 3;
          this.$v.$reset();
          console.log(this.step, "hey?");
        } else {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Wrong Password",
            color: "error",
          });
        }
      } catch (e) {
        console.log(e);
      }
      this.buttonLoader = false;
    },

    async onSetNewPassword() {
      this.$v.$touch();

      if (this.$v.password.$invalid || this.$v.confirmPassword.$invalid) return;
      try {
        this.buttonLoader = true;

        await axios.post(`${process.env.VUE_APP_BASE_URL}/user/reset`, {
          email: this.email,
          password: this.otp,
          new_password: this.password,
        });

        this.$store.commit("toggleSnackbar", {
          open: true,
          text: "You have successfully changed your password",
          color: "success",
        });
        this.$router.push("/login");
        this.buttonLoader = false;
      } catch (e) {
        this.$store.commit("toggleSnackbar", {
          open: true,
          text: "An error has been occured",
          color: "error",
        });
      }
      this.buttonLoader = false;
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 0 !important;
}
</style>
