<template>
  <div style="width: 100%; max-width: 1200px">
    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="secondary" size="60">
      </v-progress-circular>
    </div>
    <div>
      <v-btn v-if="!loader" icon @click="$router.push('/routes')" small>
        <v-icon color="secondary">mdi-arrow-left</v-icon>
      </v-btn>
      <div v-if="!loader" class="d-flex align-center">
        <h1 class="text--text font-weight-bold text-h5 px-2">
          {{ $t("routes.title") }}
        </h1>
      </div>

      <span v-if="!loader" class="caption text--text px-2 mb-2">
        {{ $t("routes.step2") }}
      </span>
      <v-container v-if="!loader">
        <v-row>
          <v-col
            v-for="(island, i) in islands"
            :key="i"
            cols="6"
            class="card d-flex flex-column"
            @click="onIslandSelect(island)"
          >
            <div class="card-2 d-flex flex-column align-center justify-center">
              <v-img
                :src="returnImage(island)"
                width="100px"
                height="60px"
              ></v-img>
              <span class="secondary--text body-1 text-center font-weight-bold">
                {{
                  island.name.charAt(0).toUpperCase() +
                  island.name.slice(1).toLowerCase()
                }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  async created() {
    this.loader = true;
    const areas = await axios.get(`${process.env.VUE_APP_BASE_URL}/areas`);
    this.islands = areas.data.areas.sort((a, b) => {
      return a.weight - b.weight;
    });
    const path = await axios.get(
      `${process.env.VUE_APP_BASE_URL}/paths/${this.$route.params.route_id}`
    );
    const currentRoute = path.data.path;
    const paths = await axios.get(`${process.env.VUE_APP_BASE_URL}/paths`);

    // Initialize an empty object to store unique routes
    let uniqueRoutes = {};
    // Initialize a Set to keep track of unique route names
    let uniqueNames = new Set();

    // Iterate through the routes array
    paths.data.forEach((route) => {
      // Check if the route name already exists in uniqueNames
      if (!uniqueNames.has(route.name)) {
        // If it doesn't exist, add the route to uniqueRoutes and add the name to uniqueNames
        uniqueRoutes[route.name] = {
          ...route,
          area_ids: [{ area_id: route.area_id, route_id: route.id }],
        };
        uniqueNames.add(route.name);
      } else {
        // If it exists, add the area_id to the existing entry in uniqueRoutes
        uniqueRoutes[route.name].area_ids.push({
          area_id: route.area_id,
          route_id: route.id,
        });
      }
    });

    // Convert uniqueRoutes object back to an array
    let filteredRoutes = Object.values(uniqueRoutes);
    this.allRoutes = [...filteredRoutes];

    // Output the filteredRoutes array
    console.log(filteredRoutes, "FILTERED ROUTES");
    this.selectedAll = true;
    // this.islands = this.islands;
    const route = this.allRoutes.find((el) => el.name == currentRoute.name);

    console.log("route", route, this.$route.params.route_id);
    const filteredIslands = this.islands.filter((island) =>
      route.area_ids.some((area) => {
        if (area.area_id === island.id) {
          island.route_id = area.route_id;
          return island;
        }
      })
    );

    this.islands = filteredIslands;
    this.routeLoader = false;

    this.loader = false;
  },

  data() {
    return {
      islands: [],
      loader: false,
      route: null,
    };
  },

  methods: {
    returnImage(island) {
      switch (island.id) {
        case 5:
          return require("@/assets/island-icons/Kerkira.png");
        case 8:
          return require("@/assets/island-icons/Ithaki.png");
        case 6:
          return require("@/assets/island-icons/Kefalonia.png");
        case 7:
          return require("@/assets/island-icons/Lefkada.png");
        case 13:
          return require("@/assets/island-icons/Meganisi.png");
        case 9:
          return require("@/assets/island-icons/Paxos.png");
        case 4:
          return require("@/assets/island-icons/Zakinthos.png");
        default:
          return "mdi-island";
      }
    },

    onIslandSelect(island) {
      if (this.selectedAll) {
        this.$router.push(`/routes/results/${island.route_id}`);
        if (this.category != null)
          this.setRoutesFilters({
            island: island.id,
            category: this.category.id,
          });
        else
          this.setRoutesFilters({
            island: island.id,
            category: this.filters.category,
          });
        return;
      }
      if (this.category != null)
        this.setRoutesFilters({
          island: island.id,
          category: this.category.id,
        });
      else
        this.setRoutesFilters({
          island: island.id,
          category: this.filters.category,
        });

      this.$router.push(`/routes/results`);
    },
  },
};
</script>

<style scoped>
.card {
  height: 150px !important;
  width: 100px !important;
}

.card-2 {
  height: 100% !important;
  width: 100% !important;
  background: #e3f3fa;
  border-radius: 8px;
  cursor: pointer;
}
</style>
