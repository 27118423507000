<template>
  <div
    fixed
    app
    color="primary"
    class="elevation-0 d-flex align-center justify-center pa-0"
  >
    <div
      class="footer pa-10 d-flex flex-column"
      :style="
        !$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs
          ? '  border-top-left-radius: 32px; border-top-right-radius: 32px; '
          : '  border-top-left-radius: 0; border-top-right-radius: 0; '
      "
    >
      <div
        class="mb-10 text-center text-md-start"
        style="font-size: 42px; font-weight: 400; color: #fff"
      >
        Explore Ionian Islands
      </div>

      <div
        class="d-flex align-start justify-space-between flex-column flex-md-row"
      >
        <div
          class="d-flex flex-column align-start mb-8"
          style="
            font-size: 15px;
            color: #fff;
            font-weight: 200;
            line-height: 22px;
            width: 315px;
            padding: 25px 40px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 16px;
          "
          :style="
            !$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs
              ? 'width: 315px'
              : 'width: auto'
          "
        >
          <p>
            Το Explore Ionian Islands είναι μια δωρεάν Εφαρμογή Διαδραστικής
            Τουριστικής Ξενάγησης, που δημιουργήθηκε από την Περιφερειακή Ένωση
            Δήμων Ιονίων Νήσων (ΠΕΔ-ΙΝ) με σκοπό την προώθηση του εναλλακτικού
            τουρισμού στα Ιόνια Νησιά
          </p>
          <img
            src="@/assets/pedin-logo.png"
            width="159px"
            height="auto"
            class="mr-4"
          />
        </div>

        <div class="d-flex flex-column flex-sm-row align-start">
          <div class="d-flex flex-column mr-10 mb-8">
            <div
              style="
                font-size: 18px;
                color: #fff;
                font-weight: 400;
                text-transform: uppercase;
              "
              class="mb-8"
            >
              {{ $t("footer.links") }}
            </div>
            <div
              v-for="(item, i) in menu"
              :key="i"
              style="
                font-size: 16px;
                color: #fff;
                font-weight: 200;
                cursor: pointer;
              "
              class="mb-4"
              @click="$router.push(item.path)"
            >
              {{ item.label }}
            </div>
          </div>

          <div class="d-flex flex-column white--text">
            <div
              style="
                font-size: 18px;
                color: #fff;
                font-weight: 400;
                text-transform: uppercase;
              "
              lang="el"
              class="mb-8"
            >
              {{ $t("info_settings.contact") }}
            </div>

            <div
              class="mb-4"
              style="font-size: 16px; font-weight: 400; width: 200px"
            >
              Περιφερειακή Ένωση Δήμων Ιονίων Νήσων <br />(ΠΕΔ-ΙΝ)
            </div>

            <div class="mb-4" style="font-size: 16px; font-weight: 200">
              Τ. +30 2645023330
            </div>

            <div class="mb-4" style="font-size: 16px; font-weight: 200">
              lefkada@ped-in.gr
            </div>

            <div class="d-flex mt-3">
              <img
                @click="onSocialOpen('fb')"
                style="height: 20px"
                class="social-icon"
                src="@/assets/icons/facebook.svg"
              />
              <img
                @click="onSocialOpen('insta')"
                style="height: 20px"
                class="social-icon"
                src="@/assets/icons/instagram.svg"
              />

              <img
                @click="onSocialOpen('x')"
                style="height: 20px"
                class="social-icon"
                src="@/assets/icons/x.svg"
              />
            </div>
          </div>
        </div>
      </div>

      <v-divider
        class="mt-10 mb-5"
        style="background-color: rgba(255, 255, 255, 0.2)"
      ></v-divider>

      <div
        class="text-center"
        style="font-size: 16px; font-weight: 200; color: #90a0b3"
      >
        Πνευματικά δικαιώματα © {{ new Date().getFullYear() }} . Με την
        επιφύλαξη παντός δικαιώματος. Περιφερειακή Ένωση Δήμων Ιονίων Νήσων.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    menu() {
      return [
        {
          label: this.$t("menu.islands"),
          icon: "mdi-island",
          path: "/islands",
        },

        {
          label: this.$t("menu.pois"),
          icon: "mdi-map-marker-multiple",
          path: "/pois",
        },

        {
          label: this.$t("menu.routes"),
          icon: "mdi-map-marker-path",
          path: "/routes",
        },

        {
          label: this.$t("menu.gastronomy"),
          icon: "mdi-pot-steam",
          path: "/gastronomy",
        },

        {
          label: this.$t("menu.videos"),
          icon: "mdi-video",
          path: "/videos",
        },

        {
          label: this.$t("menu.info_settings"),
          icon: "mdi-information",
          path: "/settings",
        },
      ];
    },
  },

  methods: {
    onSocialOpen(type) {
      switch (type) {
        case "fb":
          window.open(
            "https://www.facebook.com/profile.php?id=100083186786023"
          );
          break;
        case "insta":
          window.open("https://www.instagram.com/exploreionianislands/");
          break;
        case "x":
          window.open("https://x.com/IslandsIonian");
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  max-width: 1200px;
}

.social-icon {
  filter: invert(100%) sepia(0%) saturate(9%) hue-rotate(128deg)
    brightness(101%) contrast(105%);
  width: 20px;
  margin-right: 20px;
  cursor: pointer;
}
</style>
