<template>
  <div style="width: 100%">
    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="primary" size="60">
      </v-progress-circular>
    </div>

    <l-map
      ref="map"
      v-else
      style="height: 80vh; width: 100%; z-index: 0; border-radius: 40px"
      :zoom="zoom"
      :center="
        pois.length > 0
          ? [parseFloat(pois[0].latitude), parseFloat(pois[0].longitude)]
          : [39.0742, 21.8243]
      "
      :options="{ zoomControl: false }"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
        :detectRetina="true"
      ></l-tile-layer>

      <l-control position="topleft">
        <div
          style="cursor: pointer"
          class="secondary rounded-pill px-2 d-flex align-center"
          @click="
            $route.path.split('/')[4] == 'map'
              ? $router.push(`/islands/area/${currentArea.id}`)
              : $router.push(`/islands/area/${currentArea.id}/pois`)
          "
        >
          <v-btn icon v-if="$route.path.split('/')[1] != 'pois'">
            <v-icon color="white">mdi-arrow-left-thin</v-icon>
          </v-btn>
          <span
            v-if="$route.path.split('/')[1] != 'pois'"
            class="body-2 white--text pr-1"
            >{{
              $route.path.split("/")[4] == "map"
                ? currentArea.name
                : $t("pois.title")
            }}</span
          >
        </div>
        <div
          class="secondary rounded-pill px-2 d-flex align-center"
          @click="$router.push(`/pois/results`)"
        >
          <v-btn icon v-if="$route.path.split('/')[1] == 'pois'">
            <v-icon color="white">mdi-arrow-left-thin</v-icon>
          </v-btn>
          <span
            v-if="$route.path.split('/')[1] == 'pois'"
            class="body-2 white--text pr-1"
            >{{ $t("pois.title") }}</span
          >
        </div>

        <v-chip
          @click:close="
            filterApply();
            currentCategory = null;
          "
          v-if="!loader && currentCategory != null"
          close
          close-icon="mdi-close"
          color="secondary"
          class="my-2 white--text"
        >
          {{ poiCategory(currentCategory.id).name }}
        </v-chip>
      </l-control>

      <l-control position="bottomright">
        <v-bottom-sheet
          v-model="sheet"
          inset
          style="position: relative"
          scrollable
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="position: fixed; bottom: 80px; right: 15px"
              color="white"
              fab
              dark
              medium
            >
              <v-icon size="30" color="secondary" v-bind="attrs" v-on="on"
                >mdi-tune</v-icon
              >
            </v-btn>
          </template>

          <v-card color="white" height="auto" class="px-6 pb-16 mb-4">
            <div class="text-h5 font-weight-bold pt-6">
              {{ $t("general.categories_label") }}
            </div>
            <v-divider class="my-4"></v-divider>
            <div
              style="cursor: pointer"
              v-for="(category, i) in filteredCategories"
              :key="i + category.name"
              @click="filterApply(category)"
              class="mb-4"
            >
              <span
                :class="
                  currentCategory && currentCategory.id == category.id
                    ? 'font-weight-bold'
                    : 'font-weight-regular'
                "
                class="body-1 d-flex align-center"
              >
                <span class="mr-auto">
                  {{ category.name }}
                </span>
                <img
                  v-if="currentCategory && currentCategory.id == category.id"
                  class="check-icon"
                  src="@/assets/icons/check.png"
                />
              </span>
            </div>
          </v-card>
        </v-bottom-sheet>
      </l-control>

      <custom-marker
        v-for="(poi, i) in pois"
        :key="i"
        :marker="{
          lat: parseFloat(poi.latitude),
          lng: parseFloat(poi.longitude),
        }"
      >
        <div
          @click="poi.category_id != 21 ? openDialog(poi, 'poi_dialog') : ''"
          class="pin d-flex align-center justify-center"
        >
          <v-icon size="20" color="white" style="transform: rotate(-45deg)">{{
            poiIcon(poi)
          }}</v-icon>
        </div>
      </custom-marker>
    </l-map>

    <v-dialog
      persistent
      v-model="dialog.open"
      max-width="100%"
      fullscreen
      style="z-index: 900 !important"
    >
      <PoiDialog :ppoi="dialog.poi" v-if="dialog.open" @close="closeDialog" />
    </v-dialog>
  </div>
</template>

<script>
import PoiDialog from "../../../components/PoiDialog.vue";
import axios from "axios";
import { LMap, LTileLayer, LControl } from "vue2-leaflet";
import CustomMarker from "vue-leaflet-custom-marker";

import { Icon } from "leaflet";

import { mapState } from "vuex";

// Icon fix
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  components: {
    PoiDialog,
    LMap,
    LTileLayer,
    LControl,
    CustomMarker,
  },

  async created() {
    try {
      const categories = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/categories`
      );
      this.categories = categories.data.filter((el) => {
        if (el.id != 21) return el;
      });
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/areas/${this.$route.params.area_id}/pois`
      );

      this.pois = res.data.pois.filter((el) => el.category_id != 21);
      this.initialPois = [...this.pois];
      // console.log(this.pois);

      this.loader = false;
    } catch (e) {
      console.log(e);
    }
  },

  mounted() {},

  data() {
    return {
      initialPois: [],
      categories: [],
      currentCategory: null,
      sheet: false,
      pois: [],
      map: null,

      loader: true,

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: "",
      zoom: 9,

      dialog: {
        open: false,
        poi: null,
      },
    };
  },

  computed: {
    ...mapState({
      currentArea: (state) => state.areas.currentArea,
    }),

    filteredCategories() {
      const categoryIds = Array.from(
        new Set(this.initialPois.map((obj) => obj.category_id))
      );
      const matchingCategories = this.categories.filter((category) =>
        categoryIds.includes(category.id)
      );
      return matchingCategories;
    },
  },

  methods: {
    poiCategory(category_id) {
      return this.categories.find((el) => el.id == category_id);
    },

    async filterApply(category) {
      this.loader = true;
      this.currentCategory = category;

      this.sheet = false;
      let res = [];

      res = await axios.get(`${process.env.VUE_APP_BASE_URL}/pois`, {
        params: {
          area: this.$route.params.area_id,
          category: category?.id,
        },
      });
      console.log(res.data);
      this.pois = res.data.filter((el) => {
        if (el.category_id != 21) return el;
      });

      this.loader = false;
    },

    poiIcon(poi) {
      switch (poi.category_id) {
        case 21:
          return "mdi-circle-outline";
        case 6:
          return "mdi-image-filter-hdr";
        case 8:
          return "mdi-castle";

        case 10:
          return "mdi-script";
        case 11:
          return "mdi-book-open-page-variant";
        case 12:
          return "mdi-forest";
        case 13:
          return "mdi-run";
        case 14:
          return "mdi-city";
        case 2:
          return "mdi-waves";
        case 7:
          return "mdi-church";
        case 18:
          return "mdi-bank";
        case 19:
          return "mdi-glass-wine";
        case 22:
          return "mdi-home-group";
        default:
          return "mdi-circle-outline";
      }
    },

    openDialog(poi) {
      this.dialog = {
        open: true,
        poi,
      };
    },

    closeDialog() {
      this.dialog = {
        open: false,
        poi: null,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.pin {
  width: 30px;
  height: 30px;
  background-color: #2980ca;
  border-radius: 60px 60px 0px 60px;
  position: relative;
  transform: rotate(45deg);
  margin-left: 5px;
  bottom: 20px;
}
</style>
