<template>
  <div class="component-wrapper secondary d-flex flex-column">
    <div class="d-flex align-center">
      <h1 class="white--text font-weight-bold text-h5 px-2">
        {{ $t("menu.achievements") }}
      </h1>
    </div>

    <div
      v-if="loader"
      class="d-flex flex-column align-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="white" size="60">
      </v-progress-circular>
    </div>

    <v-expansion-panels v-else-if="!loader && achievements.length > 0">
      <v-expansion-panel
        class="secondary elevation-0"
        v-for="(route, i) in achievements"
        :key="i"
      >
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon color="white"> mdi-chevron-down </v-icon>
          </template>
          <span class="white--text elevation-0">
            {{ route.name }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="white">
          <v-img
            class="my-2"
            :aspect-ratio="1"
            :src="'https://iguide.mobics.gr/' + route.main_image"
          ></v-img>
          <span class="secondary--text" v-html="route.description"> </span>
          <v-btn
            @click="$router.push(`${$route.path}/${route.id}`)"
            color="secondary"
            outlined
            class="ml-auto"
            style="text-transform: none"
          >
            {{ $t("general.continue") }}
            <v-icon> mdi-arrow-right </v-icon>
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div
      class="white--text px-2"
      v-else-if="!loader && achievements.length == 0"
    >
      {{ $t("validation.no_achievements") }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  async created() {
    this.loader = true;
    const res = await axios.post(
      `${process.env.VUE_APP_BASE_URL}/clients/get-achievements`,
      {
        client_id: this.user_id,
      }
    );
    if (res.data?.achieved_paths) {
      this.achievements = res.data.achieved_paths;
    }

    this.loader = false;
  },

  data() {
    return {
      achievements: [],
      loader: false,
    };
  },

  computed: {
    ...mapState({
      user_id: (state) => state.auth.user_id,
    }),
  },
};
</script>

<style></style>
