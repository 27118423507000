<template>
  <div
    class="d-flex flex-column align-center flex-grow-1"
    style="width: 100%; max-width: 1200px"
  >
    <div>
      <div
        class="secondary rounded-pill px-2 d-flex align-center ml-2 my-2"
        @click="$router.push('/islands')"
        style="width: fit-content; cursor: pointer"
      >
        <v-btn icon>
          <v-icon color="white">mdi-arrow-left-thin</v-icon>
        </v-btn>
        <span class="body-2 white--text pr-1">
          {{ $t("islands.back_button") }}
        </span>
      </div>
      <v-carousel
        hide-delimiters
        height="auto"
        :continuous="false"
        v-if="
          currentArea && currentArea.images && currentArea.images.length > 0
        "
        style="border-radius: 40px"
        width="100%"
      >
        <v-carousel-item
          v-for="(image, i) in currentArea.images"
          :key="image + i"
        >
          <v-img
            :aspect-ratio="16 / 9"
            :src="'https://iguide.mobics.gr/images/' + image.image[0].file_name"
          ></v-img>
        </v-carousel-item>
      </v-carousel>
      <div
        v-else
        style="width: 100%; height: 150px"
        class="d-flex align-center justify-center"
      >
        <v-icon size="100" color="primary"> mdi-image-off-outline </v-icon>
      </div>
      <div class="d-flex flex-column px-2">
        <!-- <v-progress-linear
          :active="true"
          :indeterminate="true"
          top
          color="primary accent-4"
        ></v-progress-linear> -->
        <div class="d-flex align-center ma-2">
          <span class="text--text text-h6 mr-auto">{{ currentArea.name }}</span>
        </div>
        <v-divider></v-divider>

        <div
          class="d-flex flex-column align-start justify-space-between px-2 py-4"
        >
          <span
            class="body-2 mb-16 text--text"
            v-html="currentArea.description"
          >
          </span>
        </div>
      </div>
    </div>

    <!-- COMMENTS -->
    <!-- COMMENTS -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { decodeEntities } from "../../utils/decodeEntities";
export default {
  async destroyed() {
    try {
      await TextToSpeech.stop();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      failed_image: false,

      playDescirption: false,
      playing: false,
    };
  },

  computed: {
    ...mapState({
      currentArea: (state) => state.areas.currentArea,
      mobileLang: (state) => state.base.mobileLang,
    }),

    image() {
      return this.failed_image
        ? "https://iguide.mobics.gr/images/no-image.jpg"
        : `https://iguide.mobics.gr/images/${
            this.currentArea.image.file_name.split(".")[0]
          }_1200.${this.currentArea.image.file_name.split(".")[1]}`;
    },
  },

  methods: {
    onImageError() {
      this.failed_image = true;
    },

    async play() {
      try {
        this.playDescirption = true;
        this.playing = true;
        const decodedText = decodeEntities(this.currentArea.description);
        await TextToSpeech.speak({
          text: `${decodedText}`,
          lang: this.mobileLang.code,
          rate: 0.9,
          pitch: 1.0,
          volume: 1.0,
          category: "ambient",
        });
        this.playing = false;
      } catch (e) {
        console.log(e);
      }
    },

    async stop() {
      try {
        await TextToSpeech.stop();
        this.playing = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.return-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.pulse-button {
  -webkit-animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
}

.pulse-button:hover {
  -webkit-animation: none;
}

@-webkit-keyframes pulse {
  0% {
    // transform: scale(1);
  }
  70% {
    // transform: scale(1.05);
    box-shadow: 0 0 0 5px rgba(90, 153, 212, 0);
  }
  100% {
    // transform: scale(1);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
</style>
