import Vue from "vue";
import Vuex from "vuex";
import areas from "./areas.js";
import routes from "./routes.js";
import pois from "./pois.js";
import base from "./base.js";
import auth from "./auth.js";
import favorites from "./favorites.js";
import gastronomy from "./gastronomy.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    base,
    areas,
    routes,
    pois,
    auth,
    favorites,
    gastronomy,
  },
});
