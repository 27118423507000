<template>
  <Route />
</template>

<script>
import Route from "../Area/Routes/Route.vue";
import { mapState } from "vuex";
export default {
  components: {
    Route,
  },

  computed: {
    ...mapState({
      currentRoute: (state) => state.routes.currentRoute,
    }),
  },
};
</script>

<style scoped lang="scss"></style>
