import axios from "axios";
export default {
  state: {
    currentRoute: null,
    step: 1,
    selectedAll: false,
    filters: {
      category: null,
      island: null,
    },
  },

  mutations: {
    setSelectedAll(state, selectedAll) {
      state.selectedAll = selectedAll;
    },

    setRoutesStep(state, step) {
      state.step = step;
    },

    setRoutesFilters(state, filters) {
      state.filters = filters;
    },
    setCurrentRoute(state, currentRoute) {
      state.currentRoute = currentRoute;
    },
  },
};
