<template>
  <div class="d-flex flex-column" style="width: 100%; max-width: 1200px">
    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="primary" size="60">
      </v-progress-circular>
    </div>

    <div v-else>
      <!-- map -->
      <!-- map -->
      <!-- map -->
      <div>
        <div>
          <l-map
            ref="map"
            style="width: 100%; z-index: 0; height: 75vh; border-radius: 40px"
            :zoom="zoom"
            :center="route.polyline[0]"
            :options="{
              zoomControl: false,
            }"
          >
            <l-tile-layer :url="url" :detectRetina="true"></l-tile-layer>

            <l-polyline
              v-if="route.path_category_id != 12 && route.id != 106"
              :lat-lngs="
                route.multi_day
                  ? JSON.parse(currentDay.polyline)
                  : route.polyline
              "
              color="var(--v-secondary-base)"
              :dashArray="route.path_category_id == 4 ? '5, 10' : null"
            ></l-polyline>

            <l-control
              position="topleft"
              class="ml-4 mt-4"
              style="cursor: pointer"
            >
              <div
                class="secondary rounded-pill px-2 d-flex align-center"
                @click="
                  $route.path.split('/')[1] == 'routes' ||
                  $route.path.split('/')[1] == 'favorites' ||
                  $route.path.split('/')[1] == 'achievements'
                    ? $router.go(-1)
                    : $router.push(`/islands/area/${currentArea.id}`)
                "
              >
                <v-btn icon>
                  <v-icon color="white">mdi-arrow-left-thin</v-icon>
                </v-btn>
                <span class="body-2 white--text pr-1">{{
                  $route.path.split("/")[1] == "routes"
                    ? $t("routes.title")
                    : $route.path.split("/")[1] == "favorites"
                    ? $t("menu.favorites")
                    : $route.path.split("/")[1] == "achievements"
                    ? $t("menu.achievements")
                    : currentArea.name
                }}</span>
              </div>
            </l-control>
            <l-control position="topright">
              <div class="d-flex flex-column mr-1">
                <!-- <v-btn
                  v-if="isLoggedIn"
                  class="secondary mb-2"
                  icon
                  @click="onToggleFavorite"
                >
                  <v-icon :color="favorite ? 'red' : 'white'">{{
                    favorite ? "mdi-heart" : "mdi-heart-outline"
                  }}</v-icon>
                </v-btn> -->

                <v-btn class="secondary" icon @click="sheet = !sheet">
                  <v-icon color="white"> mdi-share</v-icon>
                </v-btn>
              </div>
            </l-control>

            <div v-if="route.multi_day">
              <custom-marker
                v-for="(poi, i) in currentDay.pois"
                :key="i"
                :marker="{
                  lat: parseFloat(poi.poi.latitude),
                  lng: parseFloat(poi.poi.longitude),
                }"
              >
                <div
                  @click="
                    poi.poi.category_id != 21
                      ? openDialog(poi.poi, 'poi_dialog')
                      : ''
                  "
                  class="pin d-flex align-center justify-center"
                  :class="poi.poi.id == currentPoi.poi.id ? 'active' : ''"
                >
                  <v-icon
                    size="20"
                    color="white"
                    style="transform: rotate(-45deg)"
                    >{{ poiIcon(poi.poi) }}</v-icon
                  >
                </div>
              </custom-marker>
            </div>
            <div v-else>
              <custom-marker
                v-for="(poi, i) in route.path_pois"
                :key="i"
                :marker="{
                  lat: parseFloat(poi.poi.latitude),
                  lng: parseFloat(poi.poi.longitude),
                }"
              >
                <div
                  @click="
                    poi.poi.category_id != 21
                      ? openDialog(poi.poi, 'poi_dialog')
                      : ''
                  "
                  class="pin d-flex align-center justify-center"
                  :class="poi.poi.id == currentPoi.poi.id ? 'active' : ''"
                >
                  <v-icon
                    size="20"
                    color="white"
                    style="transform: rotate(-45deg)"
                    >{{ poiIcon(poi.poi) }}</v-icon
                  >
                </div>
              </custom-marker>
            </div>

            <custom-marker
              :marker="{ lat: location.latitude, lng: location.longitude }"
              v-if="hasLocation"
            >
              <v-icon
                color="secondary"
                class="rounded-circle pulse-button"
                size="15"
                >mdi-circle</v-icon
              >
            </custom-marker>
          </l-map>
        </div>
      </div>

      <!-- map -->
      <!-- map -->
      <!-- map -->

      <!-- Route details -->
      <!-- Route details -->
      <!-- Route details -->
      <!-- Route details -->

      <div
        class="page-body"
        :style="$route.path.split('/')[1] == 'routes' ? 'top:72%' : 'top: 72%'"
      >
        <div class="d-flex">
          <div
            class="d-flex align-center white chevron-button px-2"
            v-if="route.multi_day"
          >
            <v-tooltip v-model="informationTooltip" top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-item-group
                  v-model="window"
                  class="shrink d-flex"
                  mandatory
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-item
                    v-for="(day, i) in route.days"
                    :key="day.name + i"
                    v-slot="{ active, toggle }"
                  >
                    <div>
                      <v-btn
                        :input-value="active"
                        icon
                        @click="
                          toggle();
                          changeCurrentDay();
                        "
                        color="secondary "
                      >
                        {{ i + 1 }}
                      </v-btn>
                    </div>
                  </v-item>
                </v-item-group>
              </template>
              <span>Επιλογή Hμέρας</span>
            </v-tooltip>
          </div>
        </div>

        <div class="d-flex flex-column px-4 py-2 white">
          <div class="d-flex align-center justify-space-between mb-2">
            <span class="text--text font-weight-regular text-h6">
              {{
                route.multi_day
                  ? `${route.name} - ${$t("routes.day")} ${window + 1}`
                  : route.name
              }}
            </span>
          </div>
          <div v-if="route.multi_day" class="text--text d-flex">
            <div class="mr-2">
              {{ getLevel(currentDay.level_id) }}
            </div>
            <div v-if="currentDay.length_chilometer != 0.0">
              {{ "- " + currentDay.length_chilometer + "km" }}
            </div>
          </div>
          <div v-else class="text--text d-flex">
            <div>
              {{ getLevel(route.level_id) }}
            </div>
            <div v-if="route.length_chilometer != 0.0">
              {{ "- " + route.length_chilometer + "km" }}
            </div>
          </div>

          <v-divider class="my-4"></v-divider>

          <!-- poi change -->
          <div class="d-flex align-center mb-4" v-if="currentPoi">
            <v-btn
              icon
              :disabled="
                route.multi_day
                  ? currentPoi == currentDay.pois[0]
                  : currentPoi == route.path_pois[0]
              "
              @click="previousPoi"
            >
              <v-icon color="secondary" size="35">mdi-skip-previous</v-icon>
            </v-btn>

            <div
              class="d-flex align-center justify-center px-2"
              ref="startPageHeight"
            >
              <!-- <v-avatar size="60" class="mr-4">
                <img src="@/assets/avatar.jpg" alt="John" />
              </v-avatar> -->

              <span
                v-if="currentPoi"
                class="body-1 text--text"
                style="cursor: pointer"
                @click="
                  currentPoi.poi.category_id != 21
                    ? openDialog(currentPoi.poi, 'poi_dialog')
                    : ''
                "
                >{{
                  currentPoi.poi.name.includes("#")
                    ? currentPoi.poi.name.split("#")[0]
                    : currentPoi.poi.name
                }}
              </span>
            </div>

            <v-btn
              icon
              class="ml-auto"
              @click="nextPoi"
              :disabled="
                route.multi_day
                  ? currentPoi == currentDay.pois[currentDay.pois.length - 1]
                  : currentPoi == route.path_pois[route.path_pois.length - 1]
              "
            >
              <v-icon color="secondary" size="35">mdi-skip-next</v-icon>
            </v-btn>
          </div>

          <v-divider class="mb-4"></v-divider>

          <span
            v-if="
              currentPoi && currentPoi.poi && currentPoi.poi.category_id != 21
            "
            class="body-2 mb-4 description"
            v-html="currentPoi.poi.description"
          >
          </span>

          <span>
            {{ currentPoi.poi.subtitle }}
          </span>

          <div class="d-flex flex-column align-self-start mb-4"></div>

          <div
            v-if="currentPoi.informations"
            class="d-flex flex-column align-self-start"
          >
            <div>Πληροφορίες</div>
            <div v-html="currentPoi.informations"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Route details -->
    <!-- Route details -->
    <!-- Route details -->
    <!-- Route details -->

    <v-dialog persistent v-model="sailingDialog" max-width="500px">
      <div
        class="pa-4 primary text-center text--text d-flex align-center justify-center body-1"
        style="width: 100%; height: 150px"
        v-html="$t('general.sailingDialog')"
      ></div>
      <div class="primary d-flex align-center justify-center pb-5">
        <v-btn color="text primary--text" @click="sailingDialog = false">
          OK
        </v-btn>
      </div>
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'poi_dialog'"
      max-width="100%"
      fullscreen
    >
      <PoiDialog
        :ppoi="dialog.poi"
        v-if="dialog.open && dialog.type == 'poi_dialog'"
        @close="closeDialog"
      />
    </v-dialog>

    <v-bottom-sheet v-model="sheet">
      <v-sheet height="auto" class="pb-4">
        <v-btn class="mt-6" text color="secondary" @click="sheet = !sheet">
          <v-icon> mdi-close </v-icon>
        </v-btn>
        <ShareDialog
          v-if="route"
          :url="`https://exploreionianislands-49ec3.web.app${$route.path}`"
          :title="route.name"
        />
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { decodeEntities } from "@/utils/decodeEntities";
import PoiDialog from "@/components/PoiDialog.vue";

import L from "leaflet";
import { LMap, LTileLayer, LPolyline, LControl } from "vue2-leaflet";
import { Icon } from "leaflet";
import CustomMarker from "vue-leaflet-custom-marker";

import { LocalNotifications } from "@capacitor/local-notifications";
import { Share } from "@capacitor/share";

import { Haptics } from "@capacitor/haptics";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { Network } from "@capacitor/network";
import { Filesystem, Directory } from "@capacitor/filesystem";
import ShareDialog from "@/components/ShareDialog.vue";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    PoiDialog,
    LMap,
    LTileLayer,
    LPolyline,
    LControl,
    CustomMarker,
    ShareDialog,
  },

  mounted() {
    this.$nextTick(() => {
      this.map = this.$refs.map?.mapObject;
      // setTimeout(() => {
      //   this.$refs?.startPageHeight.scrollIntoView();
      // }, 2000);
    });
  },

  async created() {
    try {
      this.greenIcon = L.icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      });
      this.status = await Network.getStatus();

      this.loader = true;
      let route = null;
      if (!this.status.connected && this.$route.path.includes("saved-routes")) {
        route = await axios.get(`/savedRoute.json`);
      } else {
        route = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/paths/${this.$route.params.route_id}`
        );
      }

      this.route = route.data.path;
      console.log(this.route, "hey I am the route");
      this.route.polyline = JSON.parse(this.route.polyline);
      this.setCurrentRoute(this.route);
      if (this.isFavorite) this.favorite = true;

      // console.log(this.route.path_pois);
      if (this.route && this.route.path_category_id == 4)
        this.sailingDialog = true;
      if (this.route.multi_day) {
        this.currentDay = this.route.days[0];
        this.currentPoi = this.route.days[0].pois[0];
        this.route.polyline = JSON.parse(this.route.days[0].polyline);
      } else this.currentPoi = this.route.path_pois[0];
      this.loader = false;
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      sheet: false,
      achievementDialog: false,
      favorite: false,
      playDescirption: false,
      instructionsDialog: false,
      window: 0,
      map: null,
      route: null,
      currentDay: null,
      currentPoi: null,
      loader: true,
      playing: false,
      start: false,
      locationLoader: false,
      status: false,
      informationTooltip: false,
      sailingDialog: false,
      greenIcon: null,
      icon: L.icon({
        iconUrl: "/circle.svg",
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),

      marker: {
        lat: 0,
        lng: 0,
      },

      dialog: {
        open: false,
        poi: null,
        type: null,
      },

      options: {
        duration: 300,
        offset: 10,
        easing: "linear",
      },

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10,
    };
  },

  computed: {
    ...mapState({
      currentArea: (state) => state.areas.currentArea,
      location: (state) => state.base.location,
      mobileLang: (state) => state.base.mobileLang,
      isLanguageSupported: (state) => state.base.isLanguageSupported,
      insets: (state) => state.base.insets,
      favoriteRoutes: (state) => state.favorites.favoriteRoutes,
      user_id: (state) => state.auth.user_id,
    }),
    ...mapGetters(["isLoggedIn"]),

    hasLocation() {
      return !!location;
    },

    isFavorite() {
      let found = null;
      console.log(this.route, this.favoriteRoutes, "favoriteRoutes");
      if (this.route != null) {
        found = this.favoriteRoutes.find((route) => route.id === this.route.id);
        if (found) return true;
        else return false;
      } else return false;
    },
  },

  methods: {
    ...mapMutations(["setCurrentRoute"]),
    ...mapActions(["toggleFavorite"]),

    getLevel(level_id) {
      if (level_id != null)
        switch (level_id) {
          case 1:
            return this.$t("levels.easy");
          case 2:
            return this.$t("levels.medium");
          case 3:
            return this.$t("levels.hard");
          default:
            break;
        }
    },

    async onCheckPoint(poi_id) {
      const res = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/clients/achievement`,
        {
          client_id: this.user_id,
          path_id: this.route.id,
          poi_id,
        }
      );
      if (this.route.multi_day) {
        if (res.data.day_path_achieved) {
          this.achievementDialog = true;
        }
      } else if (!this.route.multi_day) {
        if (res.data.path_achieved) {
          this.achievementDialog = true;
        }
      }
    },

    async getBase64(url) {
      return axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) =>
          Buffer.from(response.data, "binary").toString("base64")
        );
    },

    async onShare() {
      const b64image = await this.getBase64(
        "https://iguide.mobics.gr" + this.route.main_image
      );

      //2.  save file in cache
      await Filesystem.writeFile({
        path: "https://iguide.mobics.gr" + this.route.main_image,
        data: b64image,
        directory: Directory.Cache,
        recursive: true,
      });

      //3. get full uri of the saved image
      let imgData = await Filesystem.getUri({
        path: "https://iguide.mobics.gr/" + this.route.main_image,
        directory: Directory.Cache,
      });

      await Share.share({
        title: this.route.name,
        text: this.route.name + "\n\n" + decodeEntities(this.route.description),
        url: imgData.uri,
        dialogTitle: `Share ${this.route.name}`,
      });
    },

    async onToggleFavorite() {
      this.favorite = !this.favorite;
      await this.toggleFavorite({
        action: this.favorite ? "add" : "delete",
        type: "favourite_paths",
        id: this.route.id,
        route: this.route,
      });
    },
    poiIcon(poi) {
      switch (poi.category_id) {
        case 21:
          return "mdi-circle-outline";
        case 6:
          return "mdi-image-filter-hdr";
        case 8:
          return "mdi-castle";

        case 10:
          return "mdi-script";
        case 11:
          return "mdi-book-open-page-variant";
        case 12:
          return "mdi-forest";
        case 13:
          return "mdi-run";
        case 14:
          return "mdi-city";
        case 2:
          return "mdi-waves";
        case 7:
          return "mdi-church";
        case 18:
          return "mdi-bank";
        case 19:
          return "mdi-glass-wine";
        default:
          return "mdi-circle-outline";
      }
    },
    changeCurrentDay() {
      this.currentDay = this.route.days[this.window];
      this.currentPoi = this.currentDay.pois[0];
      const parsedPolyline = JSON.parse(this.currentDay.polyline);
      this.$refs.map.mapObject.setView(
        [parsedPolyline[0].lat, parsedPolyline[0].lng],
        10,
        {
          animate: true,
          duration: 0.6,
        }
      );
    },

    async getLocation() {
      try {
        this.locationLoader = true;
        // await this.$store.dispatch("getLocation");
        this.locationLoader = false;

        this.setView(this.location);
      } catch (e) {
        console.log(e);
      }
    },

    async previousPoi() {
      await TextToSpeech.stop();

      if (this.route.multi_day) {
        const currentIndex = this.currentDay.pois.indexOf(this.currentPoi, 0);
        if (currentIndex - 1 >= 0) {
          this.currentPoi = this.currentDay.pois[currentIndex - 1];
          this.setView(this.currentPoi.poi);
        }
      } else {
        const currentIndex = this.route.path_pois.indexOf(this.currentPoi, 0);
        if (currentIndex - 1 >= 0) {
          this.currentPoi = this.route.path_pois[currentIndex - 1];
          this.setView(this.currentPoi.poi);
        }
      }
    },

    async nextPoi() {
      await TextToSpeech.stop();

      if (this.route.multi_day) {
        const currentIndex = this.currentDay.pois.indexOf(this.currentPoi, 0);
        if (currentIndex + 1 < this.currentDay.pois.length) {
          this.currentPoi = this.currentDay.pois[currentIndex + 1];
          this.setView(this.currentPoi.poi);
        }
      } else {
        const currentIndex = this.route.path_pois.indexOf(this.currentPoi, 0);
        if (currentIndex + 1 < this.route.path_pois.length) {
          this.currentPoi = this.route.path_pois[currentIndex + 1];
          this.setView(this.currentPoi.poi);
        }
      }
    },

    setView(coords) {
      this.$refs.map.mapObject.setView(
        [coords.latitude, coords.longitude],
        16,
        {
          animate: true,
          duration: 0.6,
        }
      );
    },

    async play() {
      try {
        this.playing = true;
        this.playDescirption = true;
        const decodedText = decodeEntities(this.currentPoi.poi.description);
        await TextToSpeech.speak({
          text: `${decodedText}`,
          lang: this.mobileLang.code,
          rate: 0.9,
          pitch: 1.0,
          volume: 1.0,
          category: "ambient",
        });
        this.playing = false;
      } catch (e) {
        console.log(e);
      }
    },

    async stop() {
      try {
        await TextToSpeech.stop();
        this.playing = false;
      } catch (e) {
        console.log(e);
      }
    },

    openDialog(poi, type) {
      this.dialog = {
        open: true,
        poi,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        open: false,
        poi: null,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.chevron-button {
  border-top-right-radius: 10px !important;
}

.description {
  p {
    width: 100px;
    word-wrap: break-word;
  }
}

.view-more {
  width: 100% !important;
}

.pulse-button-start {
  -webkit-animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 var(--v-secondary-base);
}

.leaflet-marker-icon .pulse-button-start:hover {
  -webkit-animation: none;
}

@-webkit-keyframes pulse {
  0% {
  }
  70% {
    box-shadow: 0 0 0 15px rgba(90, 153, 212, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

.pulse-button {
  -webkit-animation: pulse-location 1.5s infinite;
  box-shadow: 0 0 0 8px rgba(#5a99d4, 0.5);
  border: 0.5px solid white;
}

.pulse-button:hover {
  -webkit-animation: none;
}

@-webkit-keyframes pulse-location {
  0% {
  }
  70% {
    // box-shadow: 0 0 0 8px rgba(90, 153, 212, 0);
  }
  100% {
    // box-shadow: 0 0 0 4px rgba(90, 153, 212, 0);
  }
}

.pin {
  width: 30px;
  height: 30px;
  background-color: #2980ca;
  border-radius: 60px 60px 0px 60px;
  position: relative;
  transform: rotate(45deg);
  margin-left: 5px;
  bottom: 20px;
}

.pin.active {
  background-color: #00518b;
}
</style>
