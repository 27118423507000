<template>
  <div
    class="white d-flex flex-column align-center justify-center"
    style="width: 100%; max-width: 1200px"
  >
    <h1 class="text--text font-weight-bold text-h5 px-2 mb-4 align-self-start">
      {{ $t("menu.home") }}
    </h1>

    <!-- <span class="caption white--text px-2 mb-4">
      {{ $t("login.title") }}
    </span> -->
    <v-carousel
      v-model="carousel"
      hide-delimiters
      :show-arrows="true"
      height="auto"
      :continuous="true"
      :cycle="true"
      interval="4000"
      style="width: 100%; border-radius: 40px; max-width: 1200px"
      class="mx-auto"
    >
      <v-carousel-item v-for="(image, i) in images" :key="image + i">
        <v-img :aspect-ratio="16 / 9" :src="image.path"></v-img>
      </v-carousel-item>
    </v-carousel>
    <div class="text--text body-2 mb-4 px-4 mt-4" style="max-width: 1200px">
      {{ $t("home.welcome_description") }}
    </div>

    <div
      class="d-flex align-center justify-center flex-wrap"
      style="gap: 10px; width: 100%; max-width: 1200px"
    >
      <div
        v-for="(item, i) in menu"
        :key="i"
        class="d-flex flex-column"
        style="border-radius: 20px; cursor: pointer; background: #e3f3fa"
        @click="$router.push(item.path)"
      >
        <div
          class="d-flex align-center justify-center rounded-lg px-2"
          style="
            height: 60px;
            width: 250px;
            background: rgba(255, 255, 255, 0.2);
          "
        >
          <v-icon color="secondary" size="30" class="mr-4">
            {{ item.icon }}</v-icon
          >
          <span class="secondary--text subtitle-2 text-center font-weight-bold">
            {{ item.label }}
          </span>
        </div>
      </div>
    </div>

    <div class="text--text body-2 px-4 mt-6 d-flex justify-center">
      {{ $t("home.welcome_outro") }}
    </div>

    <div class="d-flex justify-center mt-8">
      <img
        style="cursor: pointer"
        @click="onGoogleOpen"
        src="@/assets/play-store.png"
        width="auto"
        height="auto"
        class="mr-4"
      />
      <img
        src="@/assets/app-store.png"
        width="auto"
        height="auto"
        style="cursor: pointer"
        @click="onAppleOpen"
      />
    </div>

    <div class="d-flex justify-center mt-8">
      <img
        src="@/assets/pedin-logo.png"
        width="250px"
        height="auto"
        class="mr-4"
      />
    </div>

    <div class="d-flex justify-center mt-8 mr-3 pb-4">
      <img src="@/assets/espa.png" width="350" height="auto" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carousel: 0,
    };
  },

  computed: {
    images() {
      return [
        {
          path: require("@/assets/island-images/1.Κέρκυρα-min.jpg"),
        },
        {
          path: require("@/assets/island-images/2.Παξοί-min.jpg"),
        },
        {
          path: require("@/assets/island-images/3.Λευκάδα-min.jpg"),
        },
        {
          path: require("@/assets/island-images/4.Μεγανήσι-min.jpg"),
        },
        {
          path: require("@/assets/island-images/5.Κεφαλονιά-min.jpg"),
        },
        {
          path: require("@/assets/island-images/6.Ιθάκη-min.jpg"),
        },
        {
          path: require("@/assets/island-images/7.Ζάκυνθος-min.jpg"),
        },
      ];
    },

    menu() {
      return [
        {
          label: this.$t("menu.islands"),
          icon: "mdi-island",
          path: "/islands",
        },

        {
          label: this.$t("menu.pois"),
          icon: "mdi-map-marker-multiple",
          path: "/pois",
        },

        {
          label: this.$t("menu.routes"),
          icon: "mdi-map-marker-path",
          path: "/routes",
        },

        {
          label: this.$t("menu.gastronomy"),
          icon: "mdi-pot-steam",
          path: "/gastronomy",
        },

        {
          label: this.$t("menu.videos"),
          icon: "mdi-video",
          path: "/videos",
        },

        {
          label: this.$t("menu.info_settings"),
          icon: "mdi-information",
          path: "/settings",
        },
      ];
    },
  },

  methods: {
    onGoogleOpen() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.ionianislands.explore"
      );
    },

    onAppleOpen() {
      window.open(
        "https://apps.apple.com/us/app/explore-ionian-islands/id6478534675"
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
