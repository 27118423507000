import axios from "axios";

export default {
  state: {
    token: localStorage.getItem("token") || null,
    email: localStorage.getItem("email") || null,
    user_id: localStorage.getItem("user_id") || null,
  },

  getters: {
    isLoggedIn: (state) => !!state.token,
  },

  mutations: {
    login(state, credentials) {
      state.token = credentials.token;
      state.email = credentials.email;
      state.user_id = credentials.user_id;
    },
    logout(state) {
      state.token = null;
    },
  },
  actions: {
    async login({ commit, dispatch }, credentials) {
      try {
        const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/login`, {
          ...credentials,
        });

        if (res.data?.error) {
          commit("toggleSnackbar", {
            open: true,
            text: "Login failed. Your username and/or password do not match.",
            color: "error",
          });
          throw "error";
        }
        console.log(res.data);
        const token = res.data.token;
        const user_id = res.data.user_id;
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        console.log(token);
        localStorage.setItem("token", token);
        localStorage.setItem("email", credentials.email);
        localStorage.setItem("user_id", user_id);
        commit("login", { token, email: credentials.email, user_id });

        // await dispatch("getFavorites");
        // commit("toggleSnackbar", {
        //   open: true,
        //   text: "Login Success",
        //   color: "success",
        // });
      } catch (e) {
        dispatch("logout");
        throw e;
      }
    },
    logout({ commit }) {
      commit("logout");
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("user_id");
      delete axios.defaults.headers.common["Authorization"];
    },
  },
};
