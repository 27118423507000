import { render, staticRenderFns } from "./PageLoader.vue?vue&type=template&id=7108f094&scoped=true&"
import script from "./PageLoader.vue?vue&type=script&lang=js&"
export * from "./PageLoader.vue?vue&type=script&lang=js&"
import style0 from "./PageLoader.vue?vue&type=style&index=0&id=7108f094&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7108f094",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VOverlay,VProgressCircular})
