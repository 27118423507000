<template>
  <div class="d-flex flex-column align-center white poi-dialog">
    <div style="width: 100%; max-width: 1200px">
      <div
        class="secondary rounded-circle d-flex align-center return-button ml-2"
        :style="`margin-top: calc(8px + ${insets.top}px)`"
        @click="$emit('close')"
      >
        <v-btn icon>
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </div>

      <div
        class="rounded-circle d-flex flex-column align-center favorite-button mt-2 mr-2"
      >
        <!-- <v-btn
        v-if="isLoggedIn"
        class="secondary mb-2"
        icon
        @click="onToggleFavorite"
      >
        <v-icon :color="favorite ? 'red' : 'white'">{{
          favorite ? "mdi-heart" : "mdi-heart-outline"
        }}</v-icon>
      </v-btn> -->
        <v-btn class="secondary" icon @click="sheet = !sheet">
          <v-icon color="white"> mdi-share</v-icon>
        </v-btn>
      </div>

      <div
        v-if="loader"
        class="d-flex flex-column align-center flex-grow-1"
        style="height: 500px; margin-top: 40vh"
      >
        <v-progress-circular indeterminate color="primary" size="60">
        </v-progress-circular>
      </div>
      <v-card v-else class="elevation-0">
        <v-carousel
          v-model="model"
          hide-delimiters
          height="auto"
          :continuous="false"
          v-if="poi && poi.images && poi.images.length > 0"
          @change="onImageChange()"
          style="width: 100%; border-radius: 40px"
        >
          <v-carousel-item
            v-for="(image, i) in poi.images"
            :key="image + i"
            class="position:relative"
          >
            <v-img
              @load="onLoad"
              @loadstart="imageLoader = true"
              :aspect-ratio="16 / 9"
              :src="
                'https://iguide.mobics.gr/images/' + image.image[0].file_name
              "
            ></v-img>
            <div
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all 600ms ease;
              "
              :style="
                imageLoader
                  ? 'backdrop-filter: blur(100px);background-color:white'
                  : ' backdrop-filter: none;'
              "
            ></div>
          </v-carousel-item>
        </v-carousel>
        <div
          v-else
          style="width: 100%; height: 150px"
          class="d-flex align-center justify-center"
        >
          <v-icon size="100" color="primary"> mdi-image-off-outline </v-icon>
        </div>

        <div class="d-flex flex-column px-4 py-2">
          <div class="d-flex align-center mb-2">
            <span
              v-if="poi"
              class="text--text text--darken-2 font-weight-regular text-h6 mr-auto"
            >
              {{ poi.name }}
            </span>
          </div>

          <v-divider class="mb-4"></v-divider>

          <span
            v-if="poi"
            class="mb-2 text--text"
            :class="'body-2 '"
            v-html="poi.description"
          >
          </span>

          <span
            v-if="poi"
            class="mb-6"
            :class="'body-2 '"
            v-html="poi.subtitle"
          >
          </span>

          <div
            v-if="poi && poi.informations"
            class="d-flex flex-column align-self-start"
          >
            <div>Πληροφορίες</div>
            <div v-html="poi.informations"></div>
          </div>

          <div class="elevation-1 pa-2 mb-4">
            <div class="body-1 mb-1">
              {{ $t("menu.routes") }}
            </div>
            <v-divider></v-divider>
            <div
              v-for="route in routes"
              :key="route.id"
              class="d-flex flex-column align-start py-3"
            >
              <div
                class="body-2 text--text"
                style="text-transform: none; cursor: pointer"
                @click="$router.push(`/routes/results/${route.id}`)"
              >
                {{ `${route.name} ` }}
                {{
                  route.duplicate
                    ? `(${$t("general.from")} ${routeArea(route)})`
                    : ""
                }}
              </div>
            </div>
          </div>
          <l-map
            v-if="poi != null"
            ref="map"
            style="height: 300px; width: 100%; z-index: 0"
            :zoom="zoom"
            :center="[parseFloat(poi.latitude), parseFloat(poi.longitude)]"
            :options="{ zoomControl: false }"
          >
            <l-tile-layer :url="url" :detectRetina="true"></l-tile-layer>

            <custom-marker
              v-if="poi != null"
              :marker="{
                lat: parseFloat(poi.latitude),
                lng: parseFloat(poi.longitude),
              }"
            >
              <div class="pin d-flex align-center justify-center">
                <v-icon
                  size="20"
                  color="white"
                  style="transform: rotate(-45deg)"
                  >{{ poiIcon(poi) }}</v-icon
                >
              </div>
            </custom-marker>
          </l-map>
        </div>
      </v-card>
      <v-dialog v-model="dialog" fullscreen>
        <Instructions @close="dialog = false" />
      </v-dialog>

      <v-bottom-sheet v-model="sheet">
        <v-sheet height="auto" class="pb-4">
          <v-btn class="mt-6" text color="secondary" @click="sheet = !sheet">
            <v-icon> mdi-close </v-icon>
          </v-btn>
          <ShareDialog
            v-if="ppoi"
            :url="`https://explore.ionianislands.net/pois/${ppoi.id}`"
            :title="poi.name"
          />
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { decodeEntities } from "@/utils/decodeEntities";
import axios from "axios";
import { LMap, LTileLayer } from "vue2-leaflet";
import CustomMarker from "vue-leaflet-custom-marker";
import Instructions from "@/components/Instructions.vue";
import { Icon } from "leaflet";
import { Share } from "@capacitor/share";
import { Filesystem, Directory } from "@capacitor/filesystem";
import ShareDialog from "@/components/ShareDialog.vue";

// Icon fix
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  components: {
    LMap,
    LTileLayer,
    CustomMarker,
    Instructions,
    ShareDialog,
  },

  props: {
    ppoi: Object,
  },

  async mounted() {
    try {
      this.loader = true;
      const pois = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/pois/${this.ppoi.id}`
      );
      this.poi = pois.data.poi;

      const routes = await axios.get(`${process.env.VUE_APP_BASE_URL}/paths`, {
        params: {
          containing_poi: this.ppoi.id,
        },
      });
      this.routes = this.markDuplicates(routes.data);
      await this.getAreas();
      if (this.isFavorite) this.favorite = true;
      this.currentImage =
        "https://iguide.mobics.gr/images/" +
        this.poi.images[0].image[0].file_name.split(".")[0] +
        "_1200." +
        this.poi.images[0].image[0].file_name.split(".")[1];

      this.loader = false;
    } catch (e) {
      console.log(e);
      this.loader = false;
    }
  },

  async destroyed() {
    try {
      await TextToSpeech.stop();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      sheet: false,
      currentImage: null,
      favorite: false,
      imageLoader: false,
      dialog: false,
      loader: false,
      routes: [],
      playDescirption: false,
      playing: false,
      model: 0,
      poi: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 13,
    };
  },

  computed: {
    ...mapState({
      areas: (state) => state.areas.areas,
      mobileLang: (state) => state.base.mobileLang,
      isLanguageSupported: (state) => state.base.isLanguageSupported,
      insets: (state) => state.base.insets,
      favoritePois: (state) => state.favorites.favoritePois,
    }),
    ...mapGetters(["isLoggedIn"]),

    isFavorite() {
      let found = null;
      if (this.poi != null) {
        found = this.favoritePois.find((poi) => poi.id === this.poi.id);
        if (found) return true;
        else return false;
      } else return false;
    },

    decodedDescription() {
      return decodeEntities(this.poi.description);
    },
  },

  methods: {
    ...mapActions(["getAreas", "toggleFavorite"]),

    onImageChange() {
      this.currentImage =
        "https://iguide.mobics.gr/images/" +
        this.poi.images[this.model].image[0].file_name.split(".")[0] +
        "_1200." +
        this.poi.images[this.model].image[0].file_name.split(".")[1];

      console.log(this.currentImage);
    },

    async getBase64(url) {
      return axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) =>
          Buffer.from(response.data, "binary").toString("base64")
        );
    },

    async onShare() {
      // await Share.share({
      //   title: this.poi.name,
      //   text: this.poi.name + "\n\n" + decodeEntities(this.poi.description),
      //   url: imgData.uri,
      //   dialogTitle: `Share ${this.poi.name}`,
      // });
    },
    onLoad(event) {
      this.imageLoader = false;
    },

    markDuplicates(array) {
      const titleMap = {};

      array.forEach((obj, index) => {
        const name = obj.name;

        if (titleMap[name] === undefined) {
          titleMap[name] = index; // Store the index of the first occurrence
        } else {
          // Duplicate found, mark both the current and the first occurrence
          obj.duplicate = true;
          array[titleMap[name]].duplicate = true;
        }
      });

      return array;
    },
    routeArea(route) {
      const found = this.areas.find((area) => area.id == route.area_id);
      return found.name;
    },

    poiIcon(poi) {
      switch (poi.category_id) {
        case 21:
          return "mdi-circle-outline";
        case 6:
          return "mdi-image-filter-hdr";
        case 8:
          return "mdi-castle";

        case 10:
          return "mdi-script";
        case 11:
          return "mdi-book-open-page-variant";
        case 12:
          return "mdi-forest";
        case 13:
          return "mdi-run";
        case 14:
          return "mdi-city";
        case 2:
          return "mdi-waves";
        case 7:
          return "mdi-church";
        case 18:
          return "mdi-bank";
        case 19:
          return "mdi-glass-wine";
        case 22:
          return "mdi-home-group";
        default:
          return "mdi-circle-outline";
      }
    },

    async play() {
      try {
        this.playDescirption = true;
        this.playing = true;
        const decodedText = decodeEntities(this.poi.description);
        await TextToSpeech.speak({
          text: `${decodedText}`,
          lang: this.mobileLang.code,
          rate: 0.9,
          pitch: 1.0,
          volume: 1.0,
          category: "ambient",
        });
        this.playing = false;
      } catch (e) {
        console.log(e);
      }
    },

    async stop() {
      try {
        await TextToSpeech.stop();
        this.playing = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.poi-dialog {
  padding: 20px 40px;
}

@media only screen and (max-width: 600px) {
  .poi-dialog {
    padding: 5px 10px;
  }
}

.return-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.favorite-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.pulse-button {
  -webkit-animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
}

.pulse-button:hover {
  -webkit-animation: none;
}

@-webkit-keyframes pulse {
  0% {
    // transform: scale(1);
  }
  70% {
    // transform: scale(1.05);
    box-shadow: 0 0 0 5px rgba(90, 153, 212, 0);
  }
  100% {
    // transform: scale(1);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

.pin {
  width: 30px;
  height: 30px;
  background-color: #2980ca;
  border-radius: 60px 60px 0px 60px;
  position: relative;
  transform: rotate(45deg);
  margin-left: 5px;
  bottom: 20px;
}
</style>
