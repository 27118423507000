<template>
  <div>
    <div
      class="primary d-flex flex-column align-center pa-4"
      style="width: 100%; max-width: 1200px"
    >
      <div
        v-if="!loader"
        class="d-flex align-self-start mb-2"
        :style="'width:100%'"
      >
        <span class="text--text font-weight-bold text-h6">
          {{ $t("routes.title") }}
        </span>

        <v-btn
          v-if="!loader"
          icon
          color="secondary"
          class="ml-auto"
          @click="$router.push(`/islands/area/${$route.params.area_id}`)"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>
      <div
        v-if="loader"
        class="d-flex flex-column align-center justify-center flex-grow-1"
        style="height: 500px"
      >
        <v-progress-circular indeterminate color="secondary" size="60">
        </v-progress-circular>
      </div>

      <v-expansion-panels v-else-if="!loader && routes.length > 0">
        <v-expansion-panel
          class="white elevation-0"
          v-for="(route, i) in routes"
          :key="i"
        >
          <v-expansion-panel-header>
            <template v-slot:actions>
              <v-icon color="secondary"> mdi-chevron-down </v-icon>
            </template>
            <span class="text--text elevation-0">
              {{ route.name }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <v-img
              class="my-2"
              :aspect-ratio="16 / 9"
              :src="'https://iguide.mobics.gr/' + route.main_image"
            ></v-img>
            <span class="text--text" v-html="route.description"> </span>
            <v-btn
              @click="$router.push(`${$route.path}/${route.id}`)"
              color="secondary"
              outlined
              class="ml-auto secondary--text"
              style="text-transform: none"
            >
              {{ $t("general.continue") }}
              <v-icon> mdi-arrow-right </v-icon>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <span
        class="px-4 white--text body-1 font-weight-thin"
        v-else-if="!loader && routes.length == 0"
      >
        {{ $t("validation.no_paths") }}
      </span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  async created() {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/areas/${this.$route.params.area_id}/paths`
      );
      this.routes = res.data.paths.filter((route) => route.id != 171);
      this.loader = false;
    } catch (e) {
      throw e;
    }
  },

  data() {
    return {
      loader: true,
      routes: [],
    };
  },
};
</script>

<style scoped lang="scss">
.route {
  width: 100%;
}
</style>
