<template>
  <div>
    <!-- appbar -->
    <v-app-bar
      color="primary"
      fixed
      app
      :height="height"
      elevation="0"
      class="px-10"
    >
      <span
        class="pa-1 mr-auto ml-2 mt-2 logo"
        @click="$router.push('/')"
        style="cursor: pointer"
      >
        <img
          src="@/assets/logo.png"
          alt=""
          style="width: 126px; height: auto"
        />
      </span>

      <div
        class="d-flex justify-center align-center mt-5"
        v-if="
          !$vuetify.breakpoint.sm &&
          !$vuetify.breakpoint.xs &&
          !$vuetify.breakpoint.md
        "
      >
        <div
          class="mb-5 d-flex align-center justify-start active text--text mx-4 nav-item"
          style="cursor: pointer; margin-top: -1.5px"
          v-for="(item, i) in menu"
          :key="i"
          @click="$router.push(item.path)"
        >
          <v-btn
            v-if="item.path != '/'"
            style="
              text-transform: uppercase !important;
              padding: 0 0;
              font-weight: bold;
              font-size: 16px;
              font-family: OpenSans, sans-serif;
              letter-spacing: 0px !important;
            "
            :ripple="false"
            lang="el"
            text
            plain
            :to="item.path"
            active-class="button-active"
          >
            {{ item.label }}
          </v-btn>
          <!-- <span
            v-if="item.path != '/'"
            lang="el"
            class="font-weight-bold"
            style="text-transform: uppercase; font-size: 16px"
            :style="$route.path.includes(item.path) ? 'color: #28aae5' : ''"
            >{{ item.label }}</span
          > -->
        </div>
      </div>

      <div>
        <v-menu
          v-if="
            !$vuetify.breakpoint.sm &&
            !$vuetify.breakpoint.xs &&
            !$vuetify.breakpoint.md
          "
          bottom
          :offset-y="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="text"
              v-bind="attrs"
              v-on="on"
              style="text-transform: uppercase !important"
            >
              <div
                class="font-weight-bold"
                style="margin-bottom: 1px; font-size: 16px"
              >
                {{ language.code }}
              </div>
            </v-btn>
          </template>
          <div class="text">
            <div
              v-for="(lang, i) in languages"
              :key="lang.name + i"
              class="px-5"
            >
              <div
                style="cursor: pointer; text-transform: uppercase"
                @click="changeLanguage(lang)"
                class="body-2 pb-2"
                :class="
                  lang.name == language.name
                    ? 'white--text font-weight-bold'
                    : 'grey--text'
                "
              >
                {{ lang.code }}
              </div>
            </div>
          </div>

          <!-- <v-list>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list> -->
        </v-menu>

        <v-btn
          v-if="
            !$vuetify.breakpoint.sm &&
            !$vuetify.breakpoint.xs &&
            !$vuetify.breakpoint.md
          "
          icon
          @click="openSearchDialog()"
          style="min-width: 0"
        >
          <v-icon color="secondary">mdi-magnify</v-icon>
        </v-btn>

        <v-app-bar-nav-icon
          mobile-break-point="1264"
          v-if="
            $vuetify.breakpoint.sm ||
            $vuetify.breakpoint.xs ||
            $vuetify.breakpoint.md
          "
          color="secondary"
          @click.stop="drawer = !drawer"
        >
          <v-icon>mdi-menu</v-icon>
        </v-app-bar-nav-icon>
      </div>
    </v-app-bar>

    <!-- navigation drawer -->
    <v-navigation-drawer
      v-model="drawer"
      :temporary="true"
      app
      color="primary"
      hide-overlay
      width="auto"
    >
      <div
        class="d-flex align-center nav-header mb-6"
        :style="`padding-top: calc(${insets.top}px + 30px)`"
      >
        <v-btn icon color="secondary" @click="drawer = !drawer" class="ml-1">
          <v-icon>mdi-arrow-left-thin</v-icon>
        </v-btn>

        <span
          class="pa-1 logo"
          @click="$router.push('/')"
          style="margin-left: auto; margin-top: 10px; margin-right: 13.5%"
        >
          <img
            src="@/assets/logo.png"
            alt=""
            style="width: 100px; height: 60px"
          />
        </span>
      </div>

      <div class="d-flex align-start mt-5 px-5">
        <div class="d-flex flex-column align-start">
          <v-btn
            class="mb-5 d-flex align-center justify-start active white--text"
            v-for="(item, i) in menu"
            :key="i"
            :to="item.path"
            color="secondary"
            :ripple="false"
            plain
            :disabled="!status.connected"
            @click.native="
              (item.icon == 'mdi-medal' || item.icon == 'mdi-heart') &&
              !isLoggedIn
                ? $router.push('/login')
                : $router.push(item.path)
            "
          >
            <v-icon size="25" class="mr-2 pa-1">{{ item.icon }}</v-icon>

            <span class="text--text body-1 font-weight-bold">{{
              item.label
            }}</span>
          </v-btn>

          <!-- <v-btn
            color="white"
            :ripple="false"
            plain
            v-if="!isLoggedIn"
            class="mb-5 d-flex align-center justify-start active white--text"
            :to="'/login'"
          >
            <v-icon size="25" class="secondary rounded-circle mr-2 pa-1">
              mdi-lock
            </v-icon>

            <span class="text--text body-1 font-weight">{{ login_menu }}</span>
          </v-btn> -->

          <!-- <v-btn
            color="white"
            :ripple="false"
            plain
            v-if="isLoggedIn"
            class="mb-5 d-flex align-center justify-start active white--text"
            @click="onLogout"
          >
            <v-icon size="25" class="secondary rounded-circle mr-2 pa-1">
              mdi-logout
            </v-icon>

            <span class="text--text body-1 font-weight">{{ logout_menu }}</span>
          </v-btn> -->

          <v-btn
            class="mb-5 d-flex align-center justify-start active white--text"
            color="secondary"
            :ripple="false"
            plain
            @click="langMenuOpen = !langMenuOpen"
          >
            <v-icon size="25" class="mr-2 pa-1"> mdi-translate </v-icon>

            <span class="text--text body-1 font-weight-bold">{{
              $t("info_settings.language")
            }}</span>

            <v-icon size="25" class="mr-2 pa-1"> mdi-chevron-down </v-icon>
          </v-btn>

          <div v-if="langMenuOpen">
            <div
              v-for="(lang, i) in languages"
              :key="lang.name + i"
              class="px-14"
            >
              <div
                @click="changeLanguage(lang)"
                class="mb-2 body-2"
                style="cursor: pointer"
                :class="
                  lang.name == language.name
                    ? 'text--text font-weight-bold'
                    : 'secondary--text'
                "
              >
                {{ lang.name }}
              </div>
            </div>
          </div>

          <v-btn
            class="mb-5 d-flex align-center justify-start active white--text"
            color="secondary"
            :ripple="false"
            plain
            v-if="
              $vuetify.breakpoint.sm ||
              $vuetify.breakpoint.xs ||
              $vuetify.breakpoint.md
            "
            @click="openSearchDialog()"
          >
            <v-icon size="25" class="mr-2 pa-1"> mdi-magnify </v-icon>

            <span class="text--text body-1 font-weight-bold">{{
              $t("general.search")
            }}</span>
          </v-btn>
        </div>
      </div>
    </v-navigation-drawer>

    <v-dialog fullscreen v-model="searchDialog.open">
      <SearchDialog @close="closeSearchDialog" />
    </v-dialog>
  </div>
</template>

<script>
import SearchDialog from "../components/SearchDialog.vue";
import { Network } from "@capacitor/network";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    SearchDialog,
  },

  async created() {
    try {
      console.log(this.insets);
      this.status = await Network.getStatus();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      drawer: false,
      location: null,
      mini: false,
      status: false,
      langMenuOpen: false,
      searchDialog: {
        open: false,
      },

      filters: [
        {
          label: "Περιοχή",
          value: null,
        },
        {
          label: "Τοποθεσίες ενδιαφέροντος",
          value: null,
        },
        {
          label: "Διαδρομή",
          value: null,
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["isLoggedIn"]),

    ...mapState({
      languages: (state) => state.base.languages,
      language: (state) => state.base.mobileLang,
      insets: (state) => state.base.insets,
    }),

    login_menu() {
      return this.$t("menu.login_register");
    },

    logout_menu() {
      return this.$t("menu.logout");
    },

    height() {
      return 100;
    },

    menu() {
      return [
        {
          label: this.$t("menu.home"),
          icon: "mdi-home",
          path: "/",
        },

        {
          label: this.$t("menu.islands"),
          icon: "mdi-island",
          path: "/islands",
        },

        {
          label: this.$t("menu.pois"),
          icon: "mdi-map-marker-multiple",
          path: "/pois",
        },

        {
          label: this.$t("menu.routes"),
          icon: "mdi-map-marker-path",
          path: "/routes",
        },

        {
          label: this.$t("menu.gastronomy"),
          icon: "mdi-pot-steam",
          path: "/gastronomy",
        },

        {
          label: this.$t("menu.videos"),
          icon: "mdi-video",
          path: "/videos",
        },

        {
          label: this.$t("menu.info_settings"),
          icon: "mdi-information",
          path: "/settings",
        },

        // {
        //   label: this.$t("menu.favorites"),
        //   icon: "mdi-heart",
        //   path: "/favorites",
        // },

        // {
        //   label: this.$t("menu.achievements"),
        //   icon: "mdi-medal",
        //   path: "/achievements",
        // },
      ];
    },
  },

  methods: {
    ...mapMutations(["setMobileLang"]),
    ...mapActions(["isLanguageSupported", "logout"]),

    async onLogout() {
      await this.logout();
      this.$store.commit("toggleSnackbar", {
        open: true,
        text: "Logout successful.",
        color: "success",
      });

      this.$router.push("/login");
    },

    async changeLanguage(lang) {
      this.setMobileLang(lang);
      this.$i18n.locale = lang.code;
      this.langMenuOpen = false;
      await this.isLanguageSupported();

      this.$router.go();
    },

    openSearchDialog() {
      this.drawer = !this.drawer; //just for the transition when modal is closed
      this.searchDialog.open = true;
    },

    closeSearchDialog() {
      this.searchDialog.open = false;
    },

    closeDrawer() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped lang="scss">
.nav-header {
  height: 60px !important;
}

.v-btn--active > .v-btn__content .v-icon {
  // color: var(--v-primary-base) !important;
  // background-color: white !important;
}

.navigation-drawer-title {
  position: relative !important;
}

.v-btn {
  text-transform: none !important;
}

.nav-item {
  transition: all 100ms ease;
}

.nav-item:hover {
  color: #28aae5 !important;
}

.button-active {
  color: #28aae5 !important;
  background-color: transparent !important;
  background: transparent !important;
}

.v-btn__content {
  opacity: 1 !important;
}
</style>
