import Vue from "vue";
import VueYouTubeEmbed from "vue-youtube-embed";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import VueSocialSharing from "vue-social-sharing";

Vue.use(Vuelidate);
Vue.use(VueYouTubeEmbed);
Vue.use(VueSocialSharing);

import i18n from "./i18n";
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader

Vue.use(vuetify, {
  iconfont: "md",
});

var filter = function (text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter("truncate", filter);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
