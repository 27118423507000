var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"100%","max-width":"1200px"}},[(_vm.loader)?_c('div',{staticClass:"d-flex flex-column align-center justify-center flex-grow-1",staticStyle:{"height":"500px"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"60"}})],1):_c('div',[_c('div',[_c('div',[_c('l-map',{ref:"map",staticStyle:{"width":"100%","z-index":"0","height":"75vh","border-radius":"40px"},attrs:{"zoom":_vm.zoom,"center":_vm.route.polyline[0],"options":{
            zoomControl: false,
          }}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"detectRetina":true}}),(_vm.route.path_category_id != 12 && _vm.route.id != 106)?_c('l-polyline',{attrs:{"lat-lngs":_vm.route.multi_day
                ? JSON.parse(_vm.currentDay.polyline)
                : _vm.route.polyline,"color":"var(--v-secondary-base)","dashArray":_vm.route.path_category_id == 4 ? '5, 10' : null}}):_vm._e(),_c('l-control',{staticClass:"ml-4 mt-4",staticStyle:{"cursor":"pointer"},attrs:{"position":"topleft"}},[_c('div',{staticClass:"secondary rounded-pill px-2 d-flex align-center",on:{"click":function($event){_vm.$route.path.split('/')[1] == 'routes' ||
                _vm.$route.path.split('/')[1] == 'favorites' ||
                _vm.$route.path.split('/')[1] == 'achievements'
                  ? _vm.$router.go(-1)
                  : _vm.$router.push(("/islands/area/" + (_vm.currentArea.id)))}}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-arrow-left-thin")])],1),_c('span',{staticClass:"body-2 white--text pr-1"},[_vm._v(_vm._s(_vm.$route.path.split("/")[1] == "routes" ? _vm.$t("routes.title") : _vm.$route.path.split("/")[1] == "favorites" ? _vm.$t("menu.favorites") : _vm.$route.path.split("/")[1] == "achievements" ? _vm.$t("menu.achievements") : _vm.currentArea.name))])],1)]),_c('l-control',{attrs:{"position":"topright"}},[_c('div',{staticClass:"d-flex flex-column mr-1"},[_c('v-btn',{staticClass:"secondary",attrs:{"icon":""},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-share")])],1)],1)]),(_vm.route.multi_day)?_c('div',_vm._l((_vm.currentDay.pois),function(poi,i){return _c('custom-marker',{key:i,attrs:{"marker":{
                lat: parseFloat(poi.poi.latitude),
                lng: parseFloat(poi.poi.longitude),
              }}},[_c('div',{staticClass:"pin d-flex align-center justify-center",class:poi.poi.id == _vm.currentPoi.poi.id ? 'active' : '',on:{"click":function($event){poi.poi.category_id != 21
                    ? _vm.openDialog(poi.poi, 'poi_dialog')
                    : ''}}},[_c('v-icon',{staticStyle:{"transform":"rotate(-45deg)"},attrs:{"size":"20","color":"white"}},[_vm._v(_vm._s(_vm.poiIcon(poi.poi)))])],1)])}),1):_c('div',_vm._l((_vm.route.path_pois),function(poi,i){return _c('custom-marker',{key:i,attrs:{"marker":{
                lat: parseFloat(poi.poi.latitude),
                lng: parseFloat(poi.poi.longitude),
              }}},[_c('div',{staticClass:"pin d-flex align-center justify-center",class:poi.poi.id == _vm.currentPoi.poi.id ? 'active' : '',on:{"click":function($event){poi.poi.category_id != 21
                    ? _vm.openDialog(poi.poi, 'poi_dialog')
                    : ''}}},[_c('v-icon',{staticStyle:{"transform":"rotate(-45deg)"},attrs:{"size":"20","color":"white"}},[_vm._v(_vm._s(_vm.poiIcon(poi.poi)))])],1)])}),1),(_vm.hasLocation)?_c('custom-marker',{attrs:{"marker":{ lat: _vm.location.latitude, lng: _vm.location.longitude }}},[_c('v-icon',{staticClass:"rounded-circle pulse-button",attrs:{"color":"secondary","size":"15"}},[_vm._v("mdi-circle")])],1):_vm._e()],1)],1)]),_c('div',{staticClass:"page-body",style:(_vm.$route.path.split('/')[1] == 'routes' ? 'top:72%' : 'top: 72%')},[_c('div',{staticClass:"d-flex"},[(_vm.route.multi_day)?_c('div',{staticClass:"d-flex align-center white chevron-button px-2"},[_c('v-tooltip',{attrs:{"top":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-item-group',_vm._g(_vm._b({staticClass:"shrink d-flex",attrs:{"mandatory":""},model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},'v-item-group',attrs,false),on),_vm._l((_vm.route.days),function(day,i){return _c('v-item',{key:day.name + i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('div',[_c('v-btn',{attrs:{"input-value":active,"icon":"","color":"secondary "},on:{"click":function($event){toggle();
                        _vm.changeCurrentDay();}}},[_vm._v(" "+_vm._s(i + 1)+" ")])],1)]}}],null,true)})}),1)]}}],null,false,3850947124),model:{value:(_vm.informationTooltip),callback:function ($$v) {_vm.informationTooltip=$$v},expression:"informationTooltip"}},[_c('span',[_vm._v("Επιλογή Hμέρας")])])],1):_vm._e()]),_c('div',{staticClass:"d-flex flex-column px-4 py-2 white"},[_c('div',{staticClass:"d-flex align-center justify-space-between mb-2"},[_c('span',{staticClass:"text--text font-weight-regular text-h6"},[_vm._v(" "+_vm._s(_vm.route.multi_day ? ((_vm.route.name) + " - " + (_vm.$t("routes.day")) + " " + (_vm.window + 1)) : _vm.route.name)+" ")])]),(_vm.route.multi_day)?_c('div',{staticClass:"text--text d-flex"},[_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.getLevel(_vm.currentDay.level_id))+" ")]),(_vm.currentDay.length_chilometer != 0.0)?_c('div',[_vm._v(" "+_vm._s("- " + _vm.currentDay.length_chilometer + "km")+" ")]):_vm._e()]):_c('div',{staticClass:"text--text d-flex"},[_c('div',[_vm._v(" "+_vm._s(_vm.getLevel(_vm.route.level_id))+" ")]),(_vm.route.length_chilometer != 0.0)?_c('div',[_vm._v(" "+_vm._s("- " + _vm.route.length_chilometer + "km")+" ")]):_vm._e()]),_c('v-divider',{staticClass:"my-4"}),(_vm.currentPoi)?_c('div',{staticClass:"d-flex align-center mb-4"},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.route.multi_day
                ? _vm.currentPoi == _vm.currentDay.pois[0]
                : _vm.currentPoi == _vm.route.path_pois[0]},on:{"click":_vm.previousPoi}},[_c('v-icon',{attrs:{"color":"secondary","size":"35"}},[_vm._v("mdi-skip-previous")])],1),_c('div',{ref:"startPageHeight",staticClass:"d-flex align-center justify-center px-2"},[(_vm.currentPoi)?_c('span',{staticClass:"body-1 text--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.currentPoi.poi.category_id != 21
                  ? _vm.openDialog(_vm.currentPoi.poi, 'poi_dialog')
                  : ''}}},[_vm._v(_vm._s(_vm.currentPoi.poi.name.includes("#") ? _vm.currentPoi.poi.name.split("#")[0] : _vm.currentPoi.poi.name)+" ")]):_vm._e()]),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","disabled":_vm.route.multi_day
                ? _vm.currentPoi == _vm.currentDay.pois[_vm.currentDay.pois.length - 1]
                : _vm.currentPoi == _vm.route.path_pois[_vm.route.path_pois.length - 1]},on:{"click":_vm.nextPoi}},[_c('v-icon',{attrs:{"color":"secondary","size":"35"}},[_vm._v("mdi-skip-next")])],1)],1):_vm._e(),_c('v-divider',{staticClass:"mb-4"}),(
            _vm.currentPoi && _vm.currentPoi.poi && _vm.currentPoi.poi.category_id != 21
          )?_c('span',{staticClass:"body-2 mb-4 description",domProps:{"innerHTML":_vm._s(_vm.currentPoi.poi.description)}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.currentPoi.poi.subtitle)+" ")]),_c('div',{staticClass:"d-flex flex-column align-self-start mb-4"}),(_vm.currentPoi.informations)?_c('div',{staticClass:"d-flex flex-column align-self-start"},[_c('div',[_vm._v("Πληροφορίες")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.currentPoi.informations)}})]):_vm._e()],1)])]),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.sailingDialog),callback:function ($$v) {_vm.sailingDialog=$$v},expression:"sailingDialog"}},[_c('div',{staticClass:"pa-4 primary text-center text--text d-flex align-center justify-center body-1",staticStyle:{"width":"100%","height":"150px"},domProps:{"innerHTML":_vm._s(_vm.$t('general.sailingDialog'))}}),_c('div',{staticClass:"primary d-flex align-center justify-center pb-5"},[_c('v-btn',{attrs:{"color":"text primary--text"},on:{"click":function($event){_vm.sailingDialog = false}}},[_vm._v(" OK ")])],1)]),(_vm.dialog.type == 'poi_dialog')?_c('v-dialog',{attrs:{"persistent":"","max-width":"100%","fullscreen":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'poi_dialog')?_c('PoiDialog',{attrs:{"ppoi":_vm.dialog.poi},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e(),_c('v-bottom-sheet',{model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"pb-4",attrs:{"height":"auto"}},[_c('v-btn',{staticClass:"mt-6",attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1),(_vm.route)?_c('ShareDialog',{attrs:{"url":("https://exploreionianislands-49ec3.web.app" + (_vm.$route.path)),"title":_vm.route.name}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }