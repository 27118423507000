<template>
  <div
    class="primary d-flex flex-column"
    style="width: 100%; max-width: 1200px"
  >
    <div class="d-flex px-2" v-if="!loader">
      <v-btn icon @click="$router.push('/gastronomy')">
        <v-icon color="secondary">mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="secondary" size="60">
      </v-progress-circular>
    </div>

    <div
      v-else-if="!loader && gastronomy.length > 0"
      v-for="(item, i) in gastronomy"
      :key="i"
      class="d-flex flex-column px-4"
      style="width: 100%; cursor: pointer"
    >
      <div
        @click="openDialog(item)"
        class="d-flex justify-start align-center text--text item body-1 mb-2"
      >
        <span class="mr-auto">{{ item.name }}</span>
        <v-btn icon color="secondary">
          <v-icon> mdi-arrow-right</v-icon>
        </v-btn>
      </div>

      <v-divider
        v-if="i != gastronomy.length - 1"
        class="mb-2"
        style="width: 100% !important"
        color="white"
      ></v-divider>
    </div>

    <span
      class="px-4 white--text body-1 font-weight-thin"
      v-else-if="!loader && gastronomy.length == 0"
    >
      {{ $t("validation.no_gastronomy") }}
    </span>

    <v-dialog fullscreen v-model="dialog.open" v-if="dialog.open">
      <GastronomyModal
        v-if="dialog.open"
        :gastronomy="dialog.gastronomy"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import GastronomyModal from "./GastronomyModal.vue";
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  async created() {
    try {
      const categories = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/categories`
      );

      const areas = await axios.get(`${process.env.VUE_APP_BASE_URL}/areas`);

      const gastronomy = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/gastronomy`,
        {
          params: {
            area: this.filters.island,
            category:
              this.filters.category != 17 ? this.filters.category : null,
          },
        }
      );

      const sortedAreas = areas.data.areas.sort((a, b) => {
        return a.weight - b.weight;
      });

      this.categories = categories.data;

      this.gastronomy = gastronomy.data;

      this.islands = sortedAreas;

      this.loader = false;
      //todo -> add category query param
    } catch (e) {
      this.loader = false;
      console.log(e);
    }
  },

  components: {
    GastronomyModal,
  },

  data() {
    return {
      showFilters: false,
      loader: true,
      gastronomy: [],
      islands: [],

      categories: [],
      dialog: {
        open: false,
        gastronomy: null,
      },
    };
  },

  computed: {
    filters: {
      get() {
        return this.$store.state.gastronomy.filters;
      },
      set(newValue) {
        this.setGastronomyFilters(newValue);
      },
    },
  },

  methods: {
    ...mapMutations(["setGastronomyFilters"]),

    openDialog(item) {
      this.dialog.open = true;
      this.dialog.gastronomy = item;
    },

    closeDialog() {
      this.dialog.open = false;
      this.dialog.gastronomy = null;
    },

    async filterApply() {
      try {
        this.loader = true;

        this.gastronomy = [];
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/gastronomy`,
          {
            params: {
              area: this.filters.island,
            },
          }
        );
        this.gastronomy = res.data;
        this.loader = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
