<template>
  <div>
    <div
      class="d-flex align-center justify-center mb-2"
      style="gap: 10px"
      v-for="network in networks"
      :key="network.network"
    >
      <ShareNetwork :network="network.network" :url="url" :title="title">
        <v-btn
          :color="network.color"
          style="text-transform: none; width: 250px"
        >
          <v-icon class="mr-2" color="white">
            {{ network.icon }}
          </v-icon>
          <div class="white--text">
            {{ network.name }}
          </div>
        </v-btn>
      </ShareNetwork>
    </div>
  </div>
</template>

<script>
export default {
  props: ["url", "title"],

  data() {
    return {
      networks: [
        {
          network: "email",
          name: "Email",
          icon: "mdi-mail",
          color: "#333333",
        },
        {
          network: "facebook",
          name: "Facebook",
          icon: "mdi-facebook",
          color: "#1877f2",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "mdi-whatsapp",
          color: "#25d366",
        },

        {
          network: "messenger",
          name: "Messenger",
          icon: "mdi-facebook-messenger",
          color: "#0084ff",
        },
        // {
        //   network: "pinterest",
        //   name: "Pinterest",
        //   icon: "mdi-pinterest",
        //   color: "#bd081c",
        // },
        // {
        //   network: "reddit",
        //   name: "Reddit",
        //   icon: "mdi-reddit",
        //   color: "#ff4500",
        // },
        {
          network: "skype",
          name: "Skype",
          icon: "mdi-skype",
          color: "#00aff0",
        },
        // {
        //   network: "sms",
        //   name: "SMS",
        //   icon: "mdi-message-processing",
        //   color: "#333333",
        // },

        {
          network: "twitter",
          name: "Twitter",
          icon: "mdi-twitter",
          color: "#1da1f2",
        },

        // {
        //   network: "linkedin",
        //   name: "LinkedIn",
        //   icon: "mdi-linkedin",
        //   color: "#007bb5",
        // },
        {
          network: "viber",
          name: "Viber",
          icon: "mdi-phone-in-talk",
          color: "#59267c",
        },
      ],
    };
  },
};
</script>
