import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#ffffff",
        secondary: "#6EC1E4",
        accent: "#28aae5",
        error: "#FF5252",
        info: "#2196F3",
        success: "#8bc541",
        warning: "#FFC107",
        grey: "#e7e7e7",
        darkgrey: "#808080",
        lightgray: "#AEAEAE",
        text: "#1B4A5E",
        footer: "#1A4A5E",
      },
    },
  },
});
