export default {
  state: {
    currentPoi: null,
    step: 1,
    filters: {
      category: null,
      island: null,
    },
  },

  mutations: {
    setPoisStep(state, step) {
      state.step = step;
    },

    setPoisFilters(state, filters) {
      state.filters = filters;
    },
    setCurrentPoi(state, currentPoi) {
      state.currentPoi = currentPoi;
    },
  },
};
