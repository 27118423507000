import Vue from "vue";
import VueRouter from "vue-router";

import Achievements from "../views/Achievements/Achievements.vue";
import AchievementsRoute from "../views/Achievements/Route.vue";

import Favorites from "../views/Favorites/Favorites.vue";
import FavoriteRoute from "../views/Favorites/Route.vue";

import Home from "../views/Home/Home.vue";

import Islands from "../views/Islands/Islands.vue";
import areaRoutes from "./area.js";

import Pois from "../views/Pois/Pois.vue";
import Poi from "../views/Pois/Poi.vue";
import PoiCategories from "../views/Pois/Categories.vue";

import Routes from "../views/Routes/Routes.vue";
import Route from "../views/Routes/Route.vue";
import RouteById from "../views/Routes/RouteById.vue";
import RouteCategories from "../views/Routes/Categories.vue";

import Gastronomy from "../views/Gastronomy/Gastronomy.vue";
import GastronomyPage from "../views/Gastronomy/GastronomyPage.vue";
import GastronomyCategories from "../views/Gastronomy/Categories.vue";

import Settings from "../views/Settings/Settings.vue";

import TermsAndPolicies from "../views/Settings/TermsAndPolicies.vue";
import About from "../views/Settings/About.vue";
import Thanks from "../views/Settings/Thanks.vue";
import Contact from "../views/Settings/Contact.vue";

import Videos from "../views/Videos/Videos.vue";

import Login from "../views/Auth/Login.vue";
import DeleteAccount from "../views/Auth/DeleteAccount.vue";
import Register from "../views/Auth/Register.vue";
import ResetPassword from "../views/Auth/ResetPassword.vue";
import ChangePassword from "../views/Auth/ChangePassword.vue";
import Privacy from "../views/Settings/Privacy.vue";

import SavedRoutes from "../views/SavedRoutes/SavedRoutes.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
  },

  {
    path: "/favorites",
    component: Favorites,
  },

  {
    path: "/privacy",
    component: Privacy,
  },
  {
    path: "/favorites/:route_id",
    component: FavoriteRoute,
  },

  {
    path: "/achievements",
    component: Achievements,
  },

  {
    path: "/achievements/:route_id",
    component: AchievementsRoute,
  },

  {
    path: "/islands",
    component: Islands,
  },

  {
    path: "/pois",
    component: PoiCategories,
    props: { next_link: "/pois/results", locale: "pois" },
  },

  {
    path: "/pois/results",
    component: Pois,
  },

  {
    path: "/pois/:poi_id",
    component: Poi,
  },

  {
    path: "/routes",
    component: RouteCategories,
    props: { next_link: "/routes/results", locale: "routes" },
  },

  {
    path: "/paths/:route_id",
    component: RouteById,
  },

  {
    path: "/routes/results",
    component: Routes,
  },

  {
    path: "/routes/results/:route_id",
    component: Route,
  },

  {
    path: "/gastronomy",
    component: GastronomyCategories,
  },

  {
    path: "/gastronomy/:gastronomy_id",
    component: GastronomyPage,
  },

  {
    path: "/gastronomy/results",
    component: Gastronomy,
  },

  {
    path: "/settings/terms_and_policies",
    component: TermsAndPolicies,
  },

  {
    path: "/settings/about",
    component: About,
  },
  {
    path: "/settings/thanks",
    component: Thanks,
  },

  {
    path: "/settings",
    component: Settings,
  },

  {
    path: "/settings/contact",
    component: Contact,
  },

  {
    path: "/login",
    component: Login,
  },

  {
    path: "/delete_account",
    component: DeleteAccount,
  },

  {
    path: "/register",
    component: Register,
  },

  {
    path: "/reset_password",
    component: ResetPassword,
  },

  {
    path: "/change_password/:token",
    component: ChangePassword,
  },

  {
    path: "/videos",
    component: Videos,
  },
  {
    path: "/saved-routes",
    component: SavedRoutes,
  },
  ...areaRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name != "NavigationDuplicated") {
      throw err;
    }
  });
};

export default router;
