<template>
  <div class="component-wrapper d-flex flex-column align-center mt-16">
    <v-form @submit.prevent="onSubmit" class="d-flex flex-column">
      <h1 class="secondary--text font-weight-bold text-h5">
        {{ $t("login.title") }}
      </h1>
      <span class="caption grey--text text--darken-3 mb-4">
        {{ $t("login.subtitle") }}
      </span>

      <v-text-field v-model="email" solo type="email" color="primary">
        <template v-slot:label>
          <div class="body-2">
            {{ $t("login.email_label") }}
          </div>
        </template>
      </v-text-field>

      <v-text-field
        v-model="password"
        solo
        type="password"
        color="primary"
        class="mb-1"
        hide-details
      >
        <template v-slot:label>
          <div class="body-2">
            {{ $t("login.password_label") }}
          </div>
        </template>
      </v-text-field>

      <v-btn
        text
        color="primary"
        class="caption align-self-end pa-0"
        style="text-transform: none !important"
        @click="$router.push('/reset_password')"
      >
        {{ $t("login.forget_password_button") }}
      </v-btn>

      <v-btn :loading="loader" color="secondary body-2" type="submit">
        {{ $t("login.login_button") }}
      </v-btn>

      <div class="d-flex align-center mt-1">
        <span class="caption">
          {{ $t("login.no_account_text") }}
        </span>
        <v-btn
          text
          link
          class="caption pa-0 ml-1"
          color="primary"
          style="text-transform: none !important"
          @click="$router.push('/register')"
        >
          {{ $t("login.no_account_button_text") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      email: null,
      password: null,
      loader: false,
    };
  },

  methods: {
    ...mapActions(["login"]),

    async onSubmit() {
      try {
        this.loader = true;
        await this.login({
          email: this.email,
          password: this.password,
          device_name: "phone",
        });

        this.loader = false;

        this.$router.push("/islands");
      } catch (e) {
        this.loader = false;

        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
