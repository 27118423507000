<template>
  <div class="d-flex flex-column align-center white gastronomy-dialog">
    <div
      style="width: 100%; max-width: 1200px"
      class="d-flex flex-column align-center"
    >
      <div
        class="secondary rounded-circle d-flex align-center return-button mt-2 ml-2"
        @click="$emit('close')"
      >
        <v-btn icon>
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </div>

      <div
        class="rounded-circle d-flex flex-column align-center favorite-button mt-2 mr-2"
      >
        <!-- <v-btn
        v-if="isLoggedIn"
        class="secondary mb-2"
        icon
        @click="onToggleFavorite"
      >
        <v-icon :color="favorite ? 'red' : 'white'">{{
          favorite ? "mdi-heart" : "mdi-heart-outline"
        }}</v-icon>
      </v-btn> -->
        <v-btn class="secondary" icon @click="sheet = !sheet">
          <v-icon color="white"> mdi-share</v-icon>
        </v-btn>
      </div>

      <v-card class="elevation-0">
        <v-carousel
          v-model="model"
          hide-delimiters
          height="auto"
          :continuous="false"
          v-if="gastronomy.images.length > 0"
          @change="onImageChange()"
          style="border-radius: 40px"
        >
          <v-carousel-item
            v-for="(image, i) in gastronomy.images"
            :key="image + i"
          >
            <v-img
              :aspect-ratio="16 / 9"
              :src="
                'https://iguide.mobics.gr/images/' + image.image[0].file_name
              "
            ></v-img>
          </v-carousel-item>
        </v-carousel>
        <div
          v-else
          style="width: 100%; height: 150px"
          class="d-flex align-center justify-center"
        >
          <v-icon size="100" color="primary"> mdi-image-off-outline </v-icon>
        </div>

        <div class="d-flex flex-column px-4 py-2">
          <div class="d-flex align-center mb-2">
            <span
              class="text--text text--darken-2 font-weight-regular text-h6 mr-auto"
            >
              {{ gastronomy.name }}
            </span>
          </div>

          <v-divider class="mb-4"></v-divider>

          <span
            class="mb-16"
            :class="'body-2 text--text'"
            v-html="gastronomy.description"
          >
          </span>

          <span
            v-if="gastronomy"
            class="mb-6 body-2 text--text"
            v-html="gastronomy.subtitle"
          >
          </span>

          <div
            v-if="gastronomy.informations"
            class="d-flex flex-column align-self-start text--text"
          >
            <div>Πληροφορίες</div>
            <div v-html="gastronomy.informations"></div>
          </div>
        </div>
      </v-card>

      <v-bottom-sheet v-model="sheet">
        <v-sheet height="auto" class="pb-4">
          <v-btn class="mt-6" text color="secondary" @click="sheet = !sheet">
            <v-icon> mdi-close </v-icon>
          </v-btn>
          <ShareDialog
            v-if="gastronomy"
            :url="`https://explore.ionianislands.net/${$route.path}`"
            :title="gastronomy.name"
          />
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { decodeEntities } from "@/utils/decodeEntities";
import { Share } from "@capacitor/share";
import axios from "axios";
import { Filesystem, Directory } from "@capacitor/filesystem";
import ShareDialog from "../../components/ShareDialog.vue";
export default {
  props: {
    gastronomy: Object,
  },

  components: {
    ShareDialog,
  },

  async mounted() {
    try {
      if (this.isFavorite) this.favorite = true;
      this.currentImage =
        "https://iguide.mobics.gr/images/" +
        this.gastronomy.images[0].image[0].file_name.split(".")[0] +
        "_1200." +
        this.gastronomy.images[0].image[0].file_name.split(".")[1];
    } catch (e) {
      console.log(e);
    }
  },

  async destroyed() {
    try {
      await TextToSpeech.stop();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      sheet: false,
      playDescirption: false,
      playing: false,
      model: 0,
      favorite: false,
      currentImage: null,
    };
  },

  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapState({
      mobileLang: (state) => state.base.mobileLang,
      favoriteGastronomy: (state) => state.favorites.favoriteGastronomy,
      isLanguageSupported: (state) => state.base.isLanguageSupported,
    }),
    isFavorite() {
      console.log(this.favoriteGastronomy, this.gastronomy);
      let found = null;
      if (this.gastronomy != null) {
        found = this.favoriteGastronomy.find(
          (gastronomy) => gastronomy.id === this.gastronomy.id
        );
        if (found) return true;
        else return false;
      } else return false;
    },
  },

  methods: {
    ...mapActions(["toggleFavorite"]),

    onImageChange() {
      this.currentImage =
        "https://iguide.mobics.gr/images/" +
        this.gastronomy.images[this.model].image[0].file_name.split(".")[0] +
        "_1200." +
        this.gastronomy.images[this.model].image[0].file_name.split(".")[1];

      console.log(this.currentImage);
    },

    async getBase64(url) {
      return axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) =>
          Buffer.from(response.data, "binary").toString("base64")
        );
    },

    async onShare() {
      const b64image = await this.getBase64(this.currentImage);

      //2.  save file in cache
      await Filesystem.writeFile({
        path: this.currentImage,

        data: b64image,
        directory: Directory.Cache,
        recursive: true,
      });

      //3. get full uri of the saved image
      let imgData = await Filesystem.getUri({
        path: this.currentImage,

        directory: Directory.Cache,
      });

      await Share.share({
        title: this.gastronomy.name,
        text:
          this.gastronomy.name +
          "\n\n" +
          decodeEntities(this.gastronomy.description),
        url: imgData.uri,
        dialogTitle: `Share ${this.gastronomy.name}`,
      });
    },

    async onToggleFavorite() {
      this.favorite = !this.favorite;
      await this.toggleFavorite({
        action: this.favorite ? "add" : "delete",
        type: "favourite_gastronomies",
        id: this.gastronomy.id,
        gastronomy: this.gastronomy,
      });
    },

    async play() {
      try {
        this.playDescirption = true;
        this.playing = true;
        const decodedText = decodeEntities(this.gastronomy.description);
        await TextToSpeech.speak({
          text: `${decodedText}`,
          lang: this.mobileLang.code,
          rate: 0.9,
          pitch: 1.0,
          volume: 1.0,
          category: "ambient",
        });
        this.playing = false;
      } catch (e) {
        console.log(e);
      }
    },

    async stop() {
      try {
        await TextToSpeech.stop();
        this.playing = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.gastronomy-dialog {
  padding: 20px 40px;
}

@media only screen and (max-width: 600px) {
  .gastronomy-dialog {
    padding: 5px 10px;
  }
}

.favorite-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.return-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.pulse-button {
  -webkit-animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
}

.pulse-button:hover {
  -webkit-animation: none;
}

@-webkit-keyframes pulse {
  0% {
    // transform: scale(1);
  }
  70% {
    // transform: scale(1.05);
    box-shadow: 0 0 0 5px rgba(90, 153, 212, 0);
  }
  100% {
    // transform: scale(1);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
</style>
