<template>
  <Route />
</template>

<script>
import Route from "../Area/Routes/Route.vue";
export default {
  components: {
    Route,
  },
};
</script>

<style scoped lang="scss">
</style>