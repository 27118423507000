<template>
  <div class="d-flex flex-column" style="width: 100%; max-width: 1200px">
    <v-btn icon @click="$router.push('/settings')">
      <v-icon color="secondary">mdi-arrow-left</v-icon>
    </v-btn>

    <div
      class="d-flex flex-column align-center pa-4 white"
      style="min-height: 60vh"
    >
      <v-form @submit.prevent="submit" class="mt-2">
        <h1 class="secondary--text font-weight-bold text-h5">
          {{ $t("contact.title") }}
        </h1>
        <span class="caption grey--text text--darken-3">
          {{ $t("contact.subtitle") }}
        </span>
        <v-text-field
          v-model="email"
          type="email"
          solo
          :error-messages="emailErrors"
          class="mb-2 mt-4"
          color="secondary"
          style="width: 272px"
        >
          <template v-slot:label>
            <div class="body-2">
              {{ $t("contact.email_label") }}
            </div>
          </template>
        </v-text-field>
        <v-text-field
          v-model="phone"
          label="Phone"
          type="number"
          :error-messages="phoneErrors"
          class="mb-2"
          color="secondary"
          solo
          style="width: 272px"
        >
          <template v-slot:label>
            <div class="body-2">
              {{ $t("contact.phone_label") }}
            </div>
          </template>
        </v-text-field>
        <v-textarea
          solo
          size="10"
          label="Message"
          v-model="message"
          :error-messages="messageErrors"
          color="secondary"
          class="mb-2"
          style="width: 272px"
        >
          <template v-slot:label>
            <div class="body-2">
              {{ $t("contact.message_label") }}
            </div>
          </template>
        </v-textarea>
        <v-btn color="secondary body-2" width="100%" type="submit">
          {{ $t("contact.message_button") }}
        </v-btn>
      </v-form>
    </div>

    <div
      class="secondary white--text body-2 font-weight-bold d-flex align-center justify-center pa-2 text-center"
    >
      <span v-if="mobileLang.code == 'el'">
        Περιφερειακή Ένωση Δήμων Ιονίων Νήσων<br />
        (ΠΕΔ-ΙΝ)
      </span>
      <span v-else> Regional Union of Municipalities of Ionian Islands </span>
    </div>

    <div class="d-flex justify-center secondary flex-grow-1 pt-4 px-2">
      <div class="body-2 mr-5 d-flex">
        <div class="d-flex flex-column">
          <span v-if="mobileLang.code == 'el'" class="white--text text-center">
            Φιλαρμονικής 23, Λευκάδα Τ.Κ. 31100
            <br />
            Τηλ: 2645023330
          </span>

          <span v-else class="text-center white--text">
            Filarmonikis 23, Lefkada, 31100
            <br />
            Tel: +30 2645023330
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  data() {
    return {
      message: null,
      email: null,
      phone: null,
    };
  },

  validations: {
    message: {
      required,
    },

    email: {
      required,
      email,
    },

    phone: {
      required,
      numeric,
      minLength: minLength(6),
      maxLength: maxLength(12),
    },
  },

  computed: {
    ...mapState({
      mobileLang: (state) => state.base.mobileLang,
    }),

    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      if (!this.$v.message.required) errors.push("Required field");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) errors.push("Required field");
      if (!this.$v.email.email)
        errors.push("Please enter a valid email address");
      return errors;
    },

    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      if (!this.$v.phone.required) errors.push("Required field");
      if (!this.$v.phone.numeric) errors.push("Only digits allowed");
      if (!this.$v.phone.minLength)
        errors.push("The number should be between 6-12 digits");
      if (!this.$v.phone.maxLength)
        errors.push("The number should be between 6-12 digits");

      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store.commit("toggleSnackbar", {
        open: true,
        text: `${this.$t("contact.success_snackbar")}
        `,
        color: "success",
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
