<template>
  <div
    class="primary d-flex flex-column align-center"
    style="width: 100%; max-width: 1200px"
  >
    <div class="d-flex px-2 align-self-start" v-if="!loader">
      <v-btn icon @click="$router.push('/routes')">
        <v-icon color="secondary">mdi-arrow-left</v-icon>
      </v-btn>
      <!-- <h1 class="white--text font-weight-bold text-h5">Routes</h1> -->
    </div>

    <div
      v-if="loader"
      class="d-flex flex-column align-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="white" size="60">
      </v-progress-circular>
    </div>

    <v-expansion-panels
      v-else-if="!loader && routes.length > 0"
      style="max-width: 1200px; width: 100%"
    >
      <v-expansion-panel
        class="primary elevation-0"
        v-for="(route, i) in routes"
        :key="i"
      >
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon color="secondary"> mdi-chevron-down </v-icon>
          </template>
          <span class="text--text elevation-0">
            {{ route.name }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="white">
          <v-img
            max-width="1200px"
            class="my-2"
            :aspect-ratio="16 / 9"
            :src="'https://iguide.mobics.gr/' + route.main_image"
          ></v-img>
          <div
            v-html="route.description"
            class="text--text"
            style="max-width: 1200px"
          ></div>

          <v-btn
            @click="$router.push(`${$route.path}/${route.id}`)"
            color="secondary"
            outlined
            class="ml-auto"
            style="text-transform: none"
          >
            {{ $t("general.continue") }}
            <v-icon> mdi-arrow-right </v-icon>
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <span
      class="px-4 white--text body-1 font-weight-thin"
      v-else-if="!loader && routes.length == 0"
    >
      {{ $t("validation.no_paths") }}
    </span>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  async created() {
    try {
      const categories = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/path-categories`
      );
      const areas = await axios.get(`${process.env.VUE_APP_BASE_URL}/areas`);

      const paths = await axios.get(`${process.env.VUE_APP_BASE_URL}/paths`, {
        params: {
          area: this.filters.island,
          category: this.filters.category != 15 ? this.filters.category : null,
        },
      });

      const sortedAreas = areas.data.areas.sort((a, b) => {
        return a.weight - b.weight;
      });

      this.categories = categories.data;

      this.routes = paths.data;

      this.islands = sortedAreas;

      this.loader = false;
    } catch (e) {
      this.loader = false;
      console.log(e);
    }
  },

  data() {
    return {
      islands: null,
      showFilters: false,
      categoriesExpanded: false,
      loader: true,
      routes: [],
      categories: [],
    };
  },

  computed: {
    filters: {
      get() {
        return this.$store.state.routes.filters;
      },
      set(newValue) {
        this.setRoutesFilters(newValue);
      },
    },
  },

  methods: {
    ...mapMutations(["setRoutesFilters"]),
    changeRadio(category) {
      this.selectedCategory = category;
    },

    async filterApply() {
      try {
        this.loader = true;

        this.routes = [];
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/paths`, {
          params: {
            area: this.filters.island,
            category:
              this.filters.category != 15 ? this.filters.category : null,
          },
        });
        this.routes = res.data;
        this.loader = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
