<template>
  <div class="component-wrapper secondary d-flex flex-column">
    <v-btn icon @click="step = 1" v-if="step == 2" small>
      <v-icon color="white">mdi-arrow-left</v-icon>
    </v-btn>
    <div class="d-flex align-center">
      <h1 class="white--text font-weight-bold text-h5 px-2">
        {{ $t("menu.favorites") }}
      </h1>
    </div>
    <span v-if="step == 1" class="caption grey--text mb-3 px-2">
      {{ $t("gastronomy.step1") }}
    </span>

    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="white" size="60">
      </v-progress-circular>
    </div>
    <v-container v-if="step == 1 && !loader">
      <v-row>
        <v-col
          v-for="(category, i) in categories"
          :key="i"
          cols="6"
          class="card d-flex flex-column"
          @click="onCategorySelect(category)"
        >
          <div class="card-2 d-flex flex-column align-center justify-center">
            <v-icon color="white" size="45"> {{ category.icon }}</v-icon>
            <span class="white--text body-1 text-center">
              {{ category.label }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="step == 2">
      <!-- pois -->
      <div v-if="type == 'pois'">
        <div
          v-for="(poi, i) in favoritePois"
          :key="i"
          class="d-flex flex-column px-4"
          style="width: 100%"
        >
          <div
            @click="openDialog(poi)"
            class="d-flex justify-start align-center white--text route font-weight-thin body-1 mb-2"
          >
            <span class="mr-auto">{{ poi.name }}</span>
            <v-btn icon color="white">
              <v-icon> mdi-arrow-right</v-icon>
            </v-btn>
          </div>

          <v-divider
            v-if="i != favoritePois.length - 1"
            class="mb-2"
            style="width: 100% !important"
            color="white"
          ></v-divider>
        </div>

        <span
          class="px-4 white--text body-1 font-weight-thin"
          v-if="favoritePois && favoritePois.length == 0"
        >
          {{ $t("validation.no_pois") }}
        </span>
      </div>

      <!-- gastronomy -->
      <div v-if="type == 'gastronomy'">
        <div
          v-for="(gastronomy, i) in favoriteGastronomy"
          :key="i"
          class="d-flex flex-column px-4"
          style="width: 100%"
        >
          <div
            @click="openGastDialog(gastronomy)"
            class="d-flex justify-start align-center white--text route font-weight-thin body-1 mb-2"
          >
            <span class="mr-auto">{{ gastronomy.name }}</span>
            <v-btn icon color="white">
              <v-icon> mdi-arrow-right</v-icon>
            </v-btn>
          </div>

          <v-divider
            v-if="i != favoriteGastronomy.length - 1"
            class="mb-2"
            style="width: 100% !important"
            color="white"
          ></v-divider>
        </div>

        <span
          class="px-4 white--text body-1 font-weight-thin"
          v-if="favoriteGastronomy && favoriteGastronomy.length == 0"
        >
          {{ $t("validation.no_gastronomy") }}
        </span>
      </div>

      <div v-else>
        <v-expansion-panels v-if="!loader && favoriteRoutes.length > 0">
          <v-expansion-panel
            class="secondary elevation-0"
            v-for="(route, i) in favoriteRoutes"
            :key="i"
          >
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon color="white"> mdi-chevron-down </v-icon>
              </template>
              <span class="white--text elevation-0">
                {{ route.name }}
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="white">
              <span class="secondary--text" v-html="route.description"> </span>
              <v-btn
                @click="$router.push(`${$route.path}/${route.id}`)"
                color="secondary"
                outlined
                class="ml-auto"
                style="text-transform: none"
              >
                {{ $t("general.continue") }}
                <v-icon> mdi-arrow-right </v-icon>
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <span class="px-4 white--text body-1 font-weight-thin" v-else>
          {{ $t("validation.no_paths") }}
        </span>
      </div>

      <v-dialog
        persistent
        v-model="dialog.open"
        max-width="100%"
        fullscreen
        style="z-index: 900 !important"
      >
        <PoiDialog :ppoi="dialog.poi" v-if="dialog.open" @close="closeDialog" />
      </v-dialog>

      <v-dialog fullscreen v-model="gastDialog.open" v-if="gastDialog.open">
        <GastronomyModal
          v-if="gastDialog.open"
          :gastronomy="gastDialog.gastronomy"
          @close="closeGastDialog"
        />
      </v-dialog>
      <!-- paths -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import PoiDialog from "@/components/PoiDialog.vue";
import GastronomyModal from "@/views/Gastronomy/GastronomyModal.vue";

export default {
  components: {
    PoiDialog,
    GastronomyModal,
  },
  beforeRouteEnter(to, from, next) {
    if (from?.path.split("/")[1] != "favorites") {
      next((vm) => {
        console.log("before enter");
        // if (vm.$store.state.routes.selectedAll)
        //   vm.$store.commit("setRoutesStep", 2);
        // else
        vm.$store.commit("setFavoriteStep", 1);

        next();
      });
    }
    next();
  },
  async created() {
    this.loader = true;
    try {
      await this.getFavorites();
      console.log(this.favoriteRoutes);
    } catch (e) {
      console.log(e);
    }
    this.loader = false;
  },

  data() {
    return {
      type: null,
      loader: false,
      dialog: {
        open: false,
        poi: null,
      },

      gastDialog: {
        open: false,
        gastronomy: null,
      },
    };
  },

  computed: {
    ...mapState({
      favoritePois: (state) => state.favorites.favoritePois,
      favoriteRoutes: (state) => state.favorites.favoriteRoutes,
      favoriteGastronomy: (state) => state.favorites.favoriteGastronomy,
    }),
    step: {
      get() {
        return this.$store.state.favorites.step;
      },
      set(value) {
        this.setFavoriteStep(value);
      },
    },

    categories() {
      return [
        {
          label: this.$t("menu.pois"),
          icon: "mdi-map-marker-multiple",
          type: "pois",
        },

        {
          label: this.$t("menu.routes"),
          icon: "mdi-map-marker-path",
          type: "paths",
        },

        {
          label: this.$t("menu.gastronomy"),
          icon: "mdi-pot-steam",
          type: "gastronomy",
        },
      ];
    },
  },

  methods: {
    ...mapMutations(["setFavoriteStep"]),

    ...mapActions(["getFavorites"]),

    async onCategorySelect(category) {
      this.type = category.type;
      this.step = 2;
    },

    openDialog(poi) {
      this.dialog = {
        open: true,
        poi,
      };
    },

    closeDialog() {
      this.dialog = {
        open: false,
        poi: null,
      };
    },

    openGastDialog(gastronomy) {
      this.gastDialog = {
        open: true,
        gastronomy,
      };
    },

    closeGastDialog() {
      this.gastDialog = {
        open: false,
        gastronomy: null,
      };
    },
  },
};
</script>

<style scoped>
.card {
  height: 150px !important;
  width: 100px !important;
}

.card-2 {
  height: 100% !important;
  width: 100% !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.card-2:hover {
  transition: 0.3s ease;
  background: rgba(255, 255, 255, 0.3);
}
</style>
