var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[(_vm.loader)?_c('div',{staticClass:"d-flex flex-column align-center justify-center flex-grow-1",staticStyle:{"height":"500px"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"60"}})],1):_c('l-map',{ref:"map",staticStyle:{"height":"80vh","width":"100%","z-index":"0","border-radius":"40px"},attrs:{"zoom":_vm.zoom,"center":_vm.pois.length > 0
        ? [parseFloat(_vm.pois[0].latitude), parseFloat(_vm.pois[0].longitude)]
        : [39.0742, 21.8243],"options":{ zoomControl: false }}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution,"detectRetina":true}}),_c('l-control',{attrs:{"position":"topleft"}},[_c('div',{staticClass:"secondary rounded-pill px-2 d-flex align-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.$route.path.split('/')[4] == 'map'
            ? _vm.$router.push(("/islands/area/" + (_vm.currentArea.id)))
            : _vm.$router.push(("/islands/area/" + (_vm.currentArea.id) + "/pois"))}}},[(_vm.$route.path.split('/')[1] != 'pois')?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-arrow-left-thin")])],1):_vm._e(),(_vm.$route.path.split('/')[1] != 'pois')?_c('span',{staticClass:"body-2 white--text pr-1"},[_vm._v(_vm._s(_vm.$route.path.split("/")[4] == "map" ? _vm.currentArea.name : _vm.$t("pois.title")))]):_vm._e()],1),_c('div',{staticClass:"secondary rounded-pill px-2 d-flex align-center",on:{"click":function($event){return _vm.$router.push("/pois/results")}}},[(_vm.$route.path.split('/')[1] == 'pois')?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-arrow-left-thin")])],1):_vm._e(),(_vm.$route.path.split('/')[1] == 'pois')?_c('span',{staticClass:"body-2 white--text pr-1"},[_vm._v(_vm._s(_vm.$t("pois.title")))]):_vm._e()],1),(!_vm.loader && _vm.currentCategory != null)?_c('v-chip',{staticClass:"my-2 white--text",attrs:{"close":"","close-icon":"mdi-close","color":"secondary"},on:{"click:close":function($event){_vm.filterApply();
          _vm.currentCategory = null;}}},[_vm._v(" "+_vm._s(_vm.poiCategory(_vm.currentCategory.id).name)+" ")]):_vm._e()],1),_c('l-control',{attrs:{"position":"bottomright"}},[_c('v-bottom-sheet',{staticStyle:{"position":"relative"},attrs:{"inset":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{staticStyle:{"position":"fixed","bottom":"80px","right":"15px"},attrs:{"color":"white","fab":"","dark":"","medium":""}},[_c('v-icon',_vm._g(_vm._b({attrs:{"size":"30","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-tune")])],1)]}}]),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-card',{staticClass:"px-6 pb-16 mb-4",attrs:{"color":"white","height":"auto"}},[_c('div',{staticClass:"text-h5 font-weight-bold pt-6"},[_vm._v(" "+_vm._s(_vm.$t("general.categories_label"))+" ")]),_c('v-divider',{staticClass:"my-4"}),_vm._l((_vm.filteredCategories),function(category,i){return _c('div',{key:i + category.name,staticClass:"mb-4",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.filterApply(category)}}},[_c('span',{staticClass:"body-1 d-flex align-center",class:_vm.currentCategory && _vm.currentCategory.id == category.id
                  ? 'font-weight-bold'
                  : 'font-weight-regular'},[_c('span',{staticClass:"mr-auto"},[_vm._v(" "+_vm._s(category.name)+" ")]),(_vm.currentCategory && _vm.currentCategory.id == category.id)?_c('img',{staticClass:"check-icon",attrs:{"src":require("@/assets/icons/check.png")}}):_vm._e()])])})],2)],1)],1),_vm._l((_vm.pois),function(poi,i){return _c('custom-marker',{key:i,attrs:{"marker":{
        lat: parseFloat(poi.latitude),
        lng: parseFloat(poi.longitude),
      }}},[_c('div',{staticClass:"pin d-flex align-center justify-center",on:{"click":function($event){poi.category_id != 21 ? _vm.openDialog(poi, 'poi_dialog') : ''}}},[_c('v-icon',{staticStyle:{"transform":"rotate(-45deg)"},attrs:{"size":"20","color":"white"}},[_vm._v(_vm._s(_vm.poiIcon(poi)))])],1)])})],2),_c('v-dialog',{staticStyle:{"z-index":"900 !important"},attrs:{"persistent":"","max-width":"100%","fullscreen":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open)?_c('PoiDialog',{attrs:{"ppoi":_vm.dialog.poi},on:{"close":_vm.closeDialog}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }