import axios from "axios";
export default {
  state: {
    favoritesFetched: false,
    favoritePois: [],
    favoriteRoutes: [],
    favoriteGastronomy: [],
    step: 1,
  },

  mutations: {
    editFavoritePois(state, payload) {
      if (payload.type == "add") state.favoritePois.push(payload.poi);
      else {
        const index = state.favoritePois.findIndex(
          (poi) => payload.poi.id === poi.id
        );
        if (index !== -1) {
          state.favoritePois.splice(index, 1);
        }
      }
    },

    editFavoriteRoutes(state, payload) {
      if (payload.type == "add") state.favoriteRoutes.push(payload.path);
      else {
        const index = state.favoriteRoutes.findIndex(
          (path) => payload.path.id === path.id
        );
        if (index !== -1) {
          state.favoriteRoutes.splice(index, 1);
        }
      }
    },

    editFavoriteGastronomy(state, payload) {
      if (payload.type == "add")
        state.favoriteGastronomy.push(payload.gastronomy);
      else {
        const index = state.favoriteGastronomy.findIndex(
          (gastronomy) => payload.gastronomy.id === gastronomy.id
        );
        if (index !== -1) {
          state.favoriteGastronomy.splice(index, 1);
        }
      }
    },

    setFavoritePois(state, favoritePois) {
      state.favoritePois = [...favoritePois];
    },

    setFavoriteRoutes(state, favoriteRoutes) {
      state.favoriteRoutes = [...favoriteRoutes];
    },

    setFavoriteGastronomy(state, favoriteGastronomy) {
      state.favoriteGastronomy = [...favoriteGastronomy];
    },

    setFavoriteStep(state, step) {
      state.step = step;
    },

    setFavoriteFetched(state, fetched) {
      state.favoritesFetched = fetched;
    },
  },

  actions: {
    async getFavorites({ state, commit, rootState }) {
      if (state.favoritesFetched) return;

      const fav = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/clients/getfavourite`,
        {
          user_id: rootState.auth.user_id,
        }
      );

      let favoritePois = [];
      let favoritePaths = [];
      let favoriteGastronomy = [];

      const pois = await axios.get(`${process.env.VUE_APP_BASE_URL}/pois`);
      pois.data.forEach((poi) => {
        if (
          Object.values(fav.data.favourite_pois).some(
            (favorite) => favorite.favouritable_id === poi.id
          )
        ) {
          favoritePois.push(poi);
        }
      });

      const paths = await axios.get(`${process.env.VUE_APP_BASE_URL}/paths`);
      paths.data.forEach((path) => {
        if (
          Object.values(fav.data.favourite_paths).some(
            (favorite) => favorite.favouritable_id === path.id
          )
        ) {
          favoritePaths.push(path);
        }
      });

      const gastronomy = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/gastronomy`
      );
      gastronomy.data.forEach((path) => {
        if (
          Object.values(fav.data.favourite_gastronomies).some(
            (favorite) => favorite.favouritable_id === path.id
          )
        ) {
          favoriteGastronomy.push(path);
        }
      });

      commit("setFavoritePois", favoritePois);
      commit("setFavoriteRoutes", favoritePaths);
      commit("setFavoriteGastronomy", favoriteGastronomy);
      commit("setFavoriteFetched", true);
    },

    async toggleFavorite({ commit, dispatch, rootState }, payload) {
      if (payload.action === "add") {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/clients/favourite`, {
          user_id: rootState.auth.user_id,
          favouritable_type: payload.type,
          favouritable_id: payload.id,
        });
        if (payload.type == "favourite_pois")
          commit("editFavoritePois", { poi: payload.poi, type: "add" });
        else if (payload.type == "favourite_paths")
          commit("editFavoriteRoutes", { path: payload.route, type: "add" });
        else if (payload.type == "favourite_gastronomies")
          commit("editFavoriteGastronomy", {
            gastronomy: payload.gastronomy,
            type: "add",
          });
      } else if (payload.action === "delete") {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/clients/deletefavourite`,
          {
            user_id: rootState.auth.user_id,
            favouritable_type: payload.type,
            favouritable_id: payload.id,
          }
        );
        if (payload.type == "favourite_pois")
          commit("editFavoritePois", { poi: payload.poi, type: "delete" });
        else if (payload.type == "favourite_paths")
          commit("editFavoriteRoutes", { path: payload.route, type: "delete" });
        else if (payload.type == "favourite_gastronomies")
          commit("editFavoriteGastronomy", {
            gastronomy: payload.gastronomy,
            type: "delete",
          });
      }

      // commit("setFavoriteFetched", false);
      // await dispatch("getFavorites");
    },
  },
};
