<template>
  <div
    class="primary d-flex flex-column"
    style="width: 100%; max-width: 1200px"
  >
    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="secondary" size="60">
      </v-progress-circular>
    </div>
    <v-btn
      icon
      @click="step == 3 && selectedAll ? (step = 2) : (step = 1)"
      v-if="(step == 2 || step == 3) && !loader"
      small
    >
      <v-icon color="secondary">mdi-arrow-left</v-icon>
    </v-btn>
    <div v-if="!loader" class="d-flex align-center">
      <h1 class="text--text font-weight-bold text-h5 px-2">
        {{ $t("routes.title") }}
      </h1>
    </div>
    <span v-if="!loader" class="caption text--text px-2 mb-2">
      {{
        step == 1
          ? $t("routes.step1")
          : step == 2 && selectedAll
          ? $t("routes.stepAll")
          : $t("routes.step2")
      }}
    </span>
    <v-container v-if="step == 1 && !loader">
      <v-row>
        <v-col
          v-for="(category, i) in categories"
          :key="i"
          cols="6"
          class="card d-flex flex-column"
          @click="onCategorySelect(category)"
        >
          <div class="card-2 d-flex flex-column align-center justify-center">
            <v-icon color="secondary" size="45"> {{ category.icon }}</v-icon>
            <span class="secondary--text body-1 text-center font-weight-bold">
              {{ category.name }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-expansion-panels v-else-if="step == 2 && !loader">
      <v-expansion-panel
        class="primary elevation-0"
        v-for="(route, i) in allRoutes"
        :key="i"
      >
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon color="secondary"> mdi-chevron-down </v-icon>
          </template>
          <span class="text--text elevation-0">
            {{ route.name }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="white">
          <v-img
            class="my-2"
            :aspect-ratio="16 / 9"
            :src="'https://iguide.mobics.gr/' + route.main_image"
          ></v-img>
          <span class="text--text" v-html="route.description"> </span>
          <v-btn
            @click="onRouteSelect(route)"
            color="secondary"
            outlined
            class="ml-auto"
            style="text-transform: none"
            :loading="routeLoader"
          >
            {{ $t("general.continue") }}
            <v-icon> mdi-arrow-right </v-icon>
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-container v-else-if="step == 3 && !loader">
      <v-row>
        <v-col
          v-for="(island, i) in islands"
          :key="i"
          cols="6"
          class="card d-flex flex-column"
          @click="onIslandSelect(island)"
        >
          <div class="card-2 d-flex flex-column align-center justify-center">
            <v-img
              :src="returnImage(island)"
              width="100px"
              height="60px"
            ></v-img>
            <span class="secondary--text body-1 text-center font-weight-bold">
              {{
                island.name.charAt(0).toUpperCase() +
                island.name.slice(1).toLowerCase()
              }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  beforeRouteEnter(to, from, next) {
    console.log(from.path);
    if (
      from?.path.split("/")[0] != "routes" &&
      from?.path.split("/")[2] != "results"
    ) {
      next((vm) => {
        console.log("before enter");
        // if (vm.$store.state.routes.selectedAll)
        //   vm.$store.commit("setRoutesStep", 2);
        // else
        vm.$store.commit("setRoutesStep", 1);

        next();
      });
    }
    next();
  },
  async created() {
    try {
      const areas = await axios.get(`${process.env.VUE_APP_BASE_URL}/areas`);
      const sortedAreas = areas.data.areas.sort((a, b) => {
        return a.weight - b.weight;
      });
      this.initIslands = sortedAreas;
      this.islands = sortedAreas;
      const categories = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/path-categories`
      );
      this.categories = categories.data;

      if (this.step == 3) {
        await this.onCategorySelect({ id: this.filters.category });
      }
    } catch (e) {
      console.log(e);
    }

    this.loader = false;
  },

  data() {
    return {
      loader: true,
      initIslands: [],
      islands: [],
      categories: [],
      filteredAreas: [],
      routes: [],
      currentRoute: null,
      routeLoader: false,
      allRoutes: [],
    };
  },

  computed: {
    selectedAll: {
      get() {
        return this.$store.state.routes.selectedAll;
      },
      set(value) {
        console.log(value);
        this.setSelectedAll(value);
      },
    },

    step: {
      get() {
        return this.$store.state.routes.step;
      },
      set(value) {
        console.log(value);
        this.setRoutesStep(value);
      },
    },

    filters: {
      get() {
        return this.$store.state.routes.filters;
      },
      set(newValue) {
        this.setRoutesFilters(newValue);
      },
    },
  },

  methods: {
    ...mapMutations(["setRoutesFilters", "setRoutesStep", "setSelectedAll"]),

    returnImage(island) {
      switch (island.id) {
        case 5:
          return require("@/assets/island-icons/Kerkira.png");
        case 8:
          return require("@/assets/island-icons/Ithaki.png");
        case 6:
          return require("@/assets/island-icons/Kefalonia.png");
        case 7:
          return require("@/assets/island-icons/Lefkada.png");
        case 13:
          return require("@/assets/island-icons/Meganisi.png");
        case 9:
          return require("@/assets/island-icons/Paxos.png");
        case 4:
          return require("@/assets/island-icons/Zakinthos.png");
        default:
          return "mdi-island";
      }
    },

    async onCategorySelect(category) {
      console.log(category.id);
      if (category.id == 15) {
        console.log("Category selected all");
        this.loader = true;
        const paths = await axios.get(`${process.env.VUE_APP_BASE_URL}/paths`);

        // Initialize an empty object to store unique routes
        let uniqueRoutes = {};
        // Initialize a Set to keep track of unique route names
        let uniqueNames = new Set();

        // Iterate through the routes array
        paths.data.forEach((route) => {
          // Check if the route name already exists in uniqueNames
          if (!uniqueNames.has(route.name)) {
            // If it doesn't exist, add the route to uniqueRoutes and add the name to uniqueNames
            uniqueRoutes[route.name] = {
              ...route,
              area_ids: [{ area_id: route.area_id, route_id: route.id }],
            };
            uniqueNames.add(route.name);
          } else {
            // If it exists, add the area_id to the existing entry in uniqueRoutes
            uniqueRoutes[route.name].area_ids.push({
              area_id: route.area_id,
              route_id: route.id,
            });
          }
        });

        // Convert uniqueRoutes object back to an array
        let filteredRoutes = Object.values(uniqueRoutes);
        this.allRoutes = [...filteredRoutes];

        // Output the filteredRoutes array
        console.log(filteredRoutes, "FILTERED ROUTES");
        this.selectedAll = true;
        // this.islands = this.initIslands;
        this.category = category;
        this.step = 2;
        this.loader = false;

        return;
      }

      this.selectedAll = false;

      this.islands = this.initIslands;
      this.category = category;

      this.category = category;
      if (category.id != 15) {
        this.loader = true;
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/paths`, {
          params: {
            category: category.id,
          },
        });
        const islandsWithPois = this.islands.filter((island) => {
          return res.data.some((poi) => poi.area_id === island.id);
        });
        this.islands = islandsWithPois;
      }
      this.loader = false;

      this.step = 3;
    },

    onIslandSelect(island) {
      if (this.selectedAll) {
        this.$router.push(`/routes/results/${island.route_id}`);
        if (this.category != null)
          this.setRoutesFilters({
            island: island.id,
            category: this.category.id,
          });
        else
          this.setRoutesFilters({
            island: island.id,
            category: this.filters.category,
          });
        return;
      }
      if (this.category != null)
        this.setRoutesFilters({
          island: island.id,
          category: this.category.id,
        });
      else
        this.setRoutesFilters({
          island: island.id,
          category: this.filters.category,
        });

      this.$router.push(`/routes/results`);
    },

    async onRouteSelect(route) {
      if (route.name.includes("25")) {
        this.$router.push("/gastronomy");
        return;
      }

      const filteredIslands = this.initIslands.filter((island) =>
        route.area_ids.some((area) => {
          if (area.area_id === island.id) {
            island.route_id = area.route_id;
            return island;
          }
        })
      );

      console.log(filteredIslands, "filteredIslands");
      console.log(this.islands);
      this.islands = filteredIslands;
      this.step = 3;
      this.routeLoader = false;
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  height: 150px !important;
  width: 100px !important;
}

.card-2 {
  height: 100% !important;
  width: 100% !important;
  background: #e3f3fa;
  border-radius: 8px;
  cursor: pointer;
}
</style>
