<template>
  <div class="d-flex flex-column white" style="width: 100%; max-width: 1200px">
    <div
      v-for="(item, i) in menu"
      :key="i + item.text"
      class="d-flex flex-column align-start px-2 mb-3 mt-1"
      @click="$router.push(`${item.path}`)"
      style="cursor: pointer"
    >
      <span text class="text-h6 text--text flex-grow-0 mb-4">
        {{ item.text }}
      </span>
      <v-divider style="width: 100% !important"></v-divider>
    </div>

    <div
      class="d-flex flex-column align-start px-2 mb-3 mt-1"
      @click="qrDialog = true"
      style="cursor: pointer"
    >
      <span text class="text-h6 text--text flex-grow-0 mb-4">
        App's QR Code
      </span>
      <v-divider style="width: 100% !important"></v-divider>
    </div>

    <!-- <div
      v-if="isLoggedIn"
      class="d-flex flex-column align-start px-2 mb-3 mt-1"
      @click="deleteAccountDialog = true"
    >
      <span text class="text-h6 text--text text flex-grow-0 mb-4">
        Delete Account
      </span>
    </div> -->

    <v-dialog v-model="qrDialog">
      <div
        class="white d-flex align-center justify-center"
        style="height: 500px; width: 100%"
      >
        <img src="@/assets/qr.png" alt="" />
      </div>
    </v-dialog>

    <v-dialog v-model="deleteAccountDialog">
      <div class="white d-flex align-center justify-center flex-column pa-4">
        {{ $t("login.delete_account") }}
        <div class="mt-4">
          <v-btn color="error" class="mr-2" @click="onDeleteAccount">
            Delete
          </v-btn>
          <v-btn color="error" outlined> Cancel </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      qrDialog: false,
      deleteAccountDialog: false,
      dialog: {
        open: false,
        tempLang: null,
      },
    };
  },

  computed: {
    ...mapState({
      languages: (state) => state.base.languages,
      language: (state) => state.base.mobileLang,
      user_id: (state) => state.auth.user_id,
    }),

    componentLang: {
      get() {
        return this.language;
      },
      set(newValue) {
        this.tempLang = newValue;
      },
    },

    menu() {
      return [
        {
          text: this.$t("info_settings.about"),
          path: "/settings/about",
        },
        {
          text: this.$t("info_settings.thanks"),
          path: "/settings/thanks",
        },

        {
          text: this.$t("info_settings.terms"),
          path: "/settings/terms_and_policies",
        },

        {
          text: this.$t("info_settings.contact"),
          path: "/settings/contact",
        },
      ];
    },
  },

  methods: {
    ...mapMutations(["setMobileLang"]),
    ...mapActions(["logout"]),

    async onDeleteAccount() {
      await axios.post(`${process.env.VUE_APP_BASE_URL}/user/delete`, {
        user_id: this.user_id,
      });

      await this.logout();
      this.$router.push("/login");
    },

    openDialog() {
      this.dialog.open = true;
    },

    closeDialog() {
      this.dialog.open = false;
    },

    changeLang() {
      if (this.tempLang != null) this.closeDialog();
    },
  },
};
</script>

<style scoped lang="scss"></style>
