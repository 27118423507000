<template>
  <div
    class="primary d-flex flex-column"
    style="width: 100%; max-width: 1200px"
  >
    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="secondary" size="60">
      </v-progress-circular>
    </div>
    <v-btn icon @click="step = 1" v-if="step == 2 && !loader" small>
      <v-icon color="secondary">mdi-arrow-left</v-icon>
    </v-btn>
    <div v-if="!loader" class="d-flex align-center">
      <h1 class="text--text font-weight-bold text-h5 px-2">
        {{ $t("pois.title") }}
      </h1>
    </div>
    <span v-if="!loader" class="caption text--text px-2 mb-2">
      {{ step == 1 ? $t("pois.step1") : $t("pois.step2") }}
    </span>

    <v-container v-if="step == 1 && !loader">
      <v-row>
        <v-col
          v-for="(category, i) in categories"
          :key="i"
          cols="6"
          class="card d-flex flex-column"
          @click="onCategorySelect(category)"
        >
          <div class="card-2 d-flex flex-column align-center justify-center">
            <v-icon color="secondary" size="45"> {{ category.icon }}</v-icon>
            <span class="secondary--text body-1 text-center font-weight-bold">
              {{ category.name }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else-if="step == 2 && !loader">
      <v-row>
        <v-col
          v-for="(island, i) in islands"
          :key="i"
          cols="6"
          class="card d-flex flex-column"
          @click="onIslandSelect(island)"
        >
          <div class="card-2 d-flex flex-column align-center justify-center">
            <v-img
              :src="returnImage(island)"
              width="100px"
              height="60px"
            ></v-img>
            <!-- <v-icon color="white" size="45">{{ returnImage(island) }}</v-icon> -->
            <span class="secondary--text body-1 text-center font-weight-bold">
              {{
                island.name.charAt(0).toUpperCase() +
                island.name.slice(1).toLowerCase()
              }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  beforeRouteEnter(to, from, next) {
    if (from?.path != "/pois/results") {
      next((vm) => {
        vm.$store.commit("setPoisStep", 1);
        next();
      });
    }
    next();
  },

  async created() {
    try {
      const areas = await axios.get(`${process.env.VUE_APP_BASE_URL}/areas`);
      const sortedAreas = areas.data.areas.sort((a, b) => {
        return a.weight - b.weight;
      });
      this.initialIslands = sortedAreas;
      this.islands = sortedAreas;

      const categories = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/categories`
      );
      this.categories = categories.data.filter((el) => {
        if (el.id != 21) return el;
      });

      if (this.step == 2) {
        await this.onCategorySelect({ id: this.filters.category });
      }
    } catch (e) {
      console.log(e);
    }

    this.loader = false;
  },

  data() {
    return {
      loader: true,
      islands: [],
      categories: [],
      initialIslands: [],
      category: null,
    };
  },

  computed: {
    step: {
      get() {
        return this.$store.state.pois.step;
      },
      set(value) {
        this.setPoisStep(value);
      },
    },

    filters: {
      get() {
        return this.$store.state.pois.filters;
      },
      set(newValue) {
        this.setPoisFilters(newValue);
      },
    },
  },

  methods: {
    ...mapMutations(["setPoisFilters", "setPoisStep"]),

    returnImage(island) {
      switch (island.id) {
        case 5:
          return require("@/assets/island-icons/Kerkira.png");
        case 8:
          return require("@/assets/island-icons/Ithaki.png");
        case 6:
          return require("@/assets/island-icons/Kefalonia.png");
        case 7:
          return require("@/assets/island-icons/Lefkada.png");
        case 13:
          return require("@/assets/island-icons/Meganisi.png");
        case 9:
          return require("@/assets/island-icons/Paxos.png");
        case 4:
          return require("@/assets/island-icons/Zakinthos.png");
        default:
          return "mdi-island";
      }
    },
    async onCategorySelect(category) {
      console.log("on category select");
      this.category = category;
      if (category.id != 17) {
        this.loader = true;
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/pois`, {
          params: {
            category: this.step == 1 ? category.id : this.filters.category,
          },
        });
        const islandsWithPois = this.initialIslands.filter((island) => {
          return res.data.some((poi) => poi.area_id === island.id);
        });
        this.islands = [...islandsWithPois];
      } else {
        this.islands = this.initialIslands;
      }
      this.loader = false;

      this.step = 2;
    },

    onIslandSelect(island) {
      console.log(this.filters);
      if (this.category != null)
        this.setPoisFilters({ island: island.id, category: this.category.id });
      else
        this.setPoisFilters({
          island: island.id,
          category: this.filters.category,
        });

      this.$router.push(`/pois/results`);
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  height: 150px !important;
  width: 100px !important;
}

.card-2 {
  height: 100% !important;
  width: 100% !important;
  background: #e3f3fa;
  border-radius: 8px;
  cursor: pointer;
}
</style>
