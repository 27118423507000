var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"px-10",attrs:{"color":"primary","fixed":"","app":"","height":_vm.height,"elevation":"0"}},[_c('span',{staticClass:"pa-1 mr-auto ml-2 mt-2 logo",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/')}}},[_c('img',{staticStyle:{"width":"126px","height":"auto"},attrs:{"src":require("@/assets/logo.png"),"alt":""}})]),(
        !_vm.$vuetify.breakpoint.sm &&
        !_vm.$vuetify.breakpoint.xs &&
        !_vm.$vuetify.breakpoint.md
      )?_c('div',{staticClass:"d-flex justify-center align-center mt-5"},_vm._l((_vm.menu),function(item,i){return _c('div',{key:i,staticClass:"mb-5 d-flex align-center justify-start active text--text mx-4 nav-item",staticStyle:{"cursor":"pointer","margin-top":"-1.5px"},on:{"click":function($event){return _vm.$router.push(item.path)}}},[(item.path != '/')?_c('v-btn',{staticStyle:{"text-transform":"uppercase !important","padding":"0 0","font-weight":"bold","font-size":"16px","font-family":"OpenSans, sans-serif","letter-spacing":"0px !important"},attrs:{"ripple":false,"lang":"el","text":"","plain":"","to":item.path,"active-class":"button-active"}},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e()],1)}),0):_vm._e(),_c('div',[(
          !_vm.$vuetify.breakpoint.sm &&
          !_vm.$vuetify.breakpoint.xs &&
          !_vm.$vuetify.breakpoint.md
        )?_c('v-menu',{attrs:{"bottom":"","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"text-transform":"uppercase !important"},attrs:{"icon":"","color":"text"}},'v-btn',attrs,false),on),[_c('div',{staticClass:"font-weight-bold",staticStyle:{"margin-bottom":"1px","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.language.code)+" ")])])]}}],null,false,3554760846)},[_c('div',{staticClass:"text"},_vm._l((_vm.languages),function(lang,i){return _c('div',{key:lang.name + i,staticClass:"px-5"},[_c('div',{staticClass:"body-2 pb-2",class:lang.name == _vm.language.name
                  ? 'white--text font-weight-bold'
                  : 'grey--text',staticStyle:{"cursor":"pointer","text-transform":"uppercase"},on:{"click":function($event){return _vm.changeLanguage(lang)}}},[_vm._v(" "+_vm._s(lang.code)+" ")])])}),0)]):_vm._e(),(
          !_vm.$vuetify.breakpoint.sm &&
          !_vm.$vuetify.breakpoint.xs &&
          !_vm.$vuetify.breakpoint.md
        )?_c('v-btn',{staticStyle:{"min-width":"0"},attrs:{"icon":""},on:{"click":function($event){return _vm.openSearchDialog()}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-magnify")])],1):_vm._e(),(
          _vm.$vuetify.breakpoint.sm ||
          _vm.$vuetify.breakpoint.xs ||
          _vm.$vuetify.breakpoint.md
        )?_c('v-app-bar-nav-icon',{attrs:{"mobile-break-point":"1264","color":"secondary"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e()],1)]),_c('v-navigation-drawer',{attrs:{"temporary":true,"app":"","color":"primary","hide-overlay":"","width":"auto"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"d-flex align-center nav-header mb-6",style:(("padding-top: calc(" + (_vm.insets.top) + "px + 30px)"))},[_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","color":"secondary"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v("mdi-arrow-left-thin")])],1),_c('span',{staticClass:"pa-1 logo",staticStyle:{"margin-left":"auto","margin-top":"10px","margin-right":"13.5%"},on:{"click":function($event){return _vm.$router.push('/')}}},[_c('img',{staticStyle:{"width":"100px","height":"60px"},attrs:{"src":require("@/assets/logo.png"),"alt":""}})])],1),_c('div',{staticClass:"d-flex align-start mt-5 px-5"},[_c('div',{staticClass:"d-flex flex-column align-start"},[_vm._l((_vm.menu),function(item,i){return _c('v-btn',{key:i,staticClass:"mb-5 d-flex align-center justify-start active white--text",attrs:{"to":item.path,"color":"secondary","ripple":false,"plain":"","disabled":!_vm.status.connected},nativeOn:{"click":function($event){(item.icon == 'mdi-medal' || item.icon == 'mdi-heart') &&
            !_vm.isLoggedIn
              ? _vm.$router.push('/login')
              : _vm.$router.push(item.path)}}},[_c('v-icon',{staticClass:"mr-2 pa-1",attrs:{"size":"25"}},[_vm._v(_vm._s(item.icon))]),_c('span',{staticClass:"text--text body-1 font-weight-bold"},[_vm._v(_vm._s(item.label))])],1)}),_c('v-btn',{staticClass:"mb-5 d-flex align-center justify-start active white--text",attrs:{"color":"secondary","ripple":false,"plain":""},on:{"click":function($event){_vm.langMenuOpen = !_vm.langMenuOpen}}},[_c('v-icon',{staticClass:"mr-2 pa-1",attrs:{"size":"25"}},[_vm._v(" mdi-translate ")]),_c('span',{staticClass:"text--text body-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("info_settings.language")))]),_c('v-icon',{staticClass:"mr-2 pa-1",attrs:{"size":"25"}},[_vm._v(" mdi-chevron-down ")])],1),(_vm.langMenuOpen)?_c('div',_vm._l((_vm.languages),function(lang,i){return _c('div',{key:lang.name + i,staticClass:"px-14"},[_c('div',{staticClass:"mb-2 body-2",class:lang.name == _vm.language.name
                  ? 'text--text font-weight-bold'
                  : 'secondary--text',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.changeLanguage(lang)}}},[_vm._v(" "+_vm._s(lang.name)+" ")])])}),0):_vm._e(),(
            _vm.$vuetify.breakpoint.sm ||
            _vm.$vuetify.breakpoint.xs ||
            _vm.$vuetify.breakpoint.md
          )?_c('v-btn',{staticClass:"mb-5 d-flex align-center justify-start active white--text",attrs:{"color":"secondary","ripple":false,"plain":""},on:{"click":function($event){return _vm.openSearchDialog()}}},[_c('v-icon',{staticClass:"mr-2 pa-1",attrs:{"size":"25"}},[_vm._v(" mdi-magnify ")]),_c('span',{staticClass:"text--text body-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("general.search")))])],1):_vm._e()],2)])]),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.searchDialog.open),callback:function ($$v) {_vm.$set(_vm.searchDialog, "open", $$v)},expression:"searchDialog.open"}},[_c('SearchDialog',{on:{"close":_vm.closeSearchDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }