<template>
  <div class="component-wrapper d-flex flex-column align-center">
    <v-form @submit.prevent="onSubmit" class="d-flex flex-column mt-16">
      <h1 class="secondary--text font-weight-bold text-h5">Change Password</h1>
      <span class="caption grey--text text--darken-3 mb-4"
        >Change your password</span
      >

      <v-text-field
        v-model="oldPassword"
        solo
        type="password"
        color="primary"
        style="width: 272px"
        :error-messages="oldPasswordErrors"
      >
        <template v-slot:label>
          <div class="body-2">Old password</div>
        </template>
      </v-text-field>

      <v-text-field
        v-model="password"
        solo
        type="password"
        color="primary"
        style="width: 272px"
        :error-messages="passwordErrors"
      >
        <template v-slot:label>
          <div class="body-2">New password</div>
        </template>
      </v-text-field>

      <v-text-field
        v-model="confirmPassword"
        solo
        type="password"
        color="primary"
        :error-messages="confirmPasswordErrors"
      >
        <template v-slot:label>
          <div class="body-2">Confirm new password</div>
        </template>
      </v-text-field>

      <v-btn color="secondary body-2" type="submit">Change Password </v-btn>
    </v-form>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  data() {
    return {
      email: null,
      oldPassword: null,
      password: null,
      confirmPassword: null,
    };
  },

  validations: {
    oldPassword: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },

    password: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },

    confirmPassword: {
      required,
      sameAs: sameAs("password"),
    },
  },

  computed: {
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.oldPassword.$dirty) return errors;
      if (!this.$v.oldPassword.required) errors.push("Required field");
      if (!this.$v.oldPassword.minLength)
        errors.push("The oldPassword should have 4 characters");
      if (!this.$v.password.maxLength)
        errors.push("The password should have 4 characters");
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push("Required field");
      if (!this.$v.password.minLength)
        errors.push("The password should have 4 characters");
      if (!this.$v.password.maxLength)
        errors.push("The password should have 4 characters");
      return errors;
    },

    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      if (!this.$v.confirmPassword.required) errors.push("Required field");
      if (!this.$v.confirmPassword.sameAs)
        errors.push("Please make sure your passwords match");

      return errors;
    },
  },

  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$store.commit("toggleSnackbar", {
        open: true,
        text: "Your password has been successfully changed.",
        color: "success",
      });

      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 0 !important;
}
</style>