import { Geolocation } from "@capacitor/geolocation";
import { Preferences } from "@capacitor/preferences";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import countries from "../utils/countries.json";
import axios from "axios";
export default {
  state: {
    disclaimer: false || localStorage.getItem("disclaimer"),
    isLanguageSupported: null,
    countries: [],
    languages: [
      {
        name: "Ελληνικά",
        engName: "Greek",
        code: "el",
      },

      {
        name: "English",
        engName: "English",
        code: "en",
      },

      {
        name: "Français",
        engName: "French",
        code: "fr",
      },

      {
        name: "Deutsch",
        engName: "German",
        code: "de",
      },

      {
        name: "Italiano",
        engName: "Italian",
        code: "it",
      },

      {
        name: "Pусский",
        engName: "Russian",
        code: "ru",
      },

      {
        name: "繁體中文",
        engName: "Chinese",
        code: "zh", //maybe need prefix zh
      },
    ],

    location: {
      latitude: 0,
      longitude: 0,
    },

    mobileLang: JSON.parse(localStorage.getItem("mobileLang")) || null,
    snackbar: {
      open: false,
      text: null,
      color: null,
    },

    insets: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  mutations: {
    setCountries(state) {
      state.countries = countries.data;
    },

    setInsets(state, insets) {
      state.insets = insets;
    },

    getLocation(state, location) {
      state.location = location;
    },

    setMobileLang(state, lang) {
      if (typeof lang == "string") {
        const langObj = state.languages.find((el) => el.code == lang);
        state.mobileLang = langObj;

        axios.defaults.headers.common["Accept-Language"] = `${langObj.code}`;
      } else {
        state.mobileLang = lang;
        localStorage.setItem("mobileLang", JSON.stringify(lang));
        axios.defaults.headers.common["Accept-Language"] = `${lang.code}`;
      }
    },

    setIsLanguageSupported(state, isLanguageSupported) {
      state.isLanguageSupported = isLanguageSupported;
    },

    toggleSnackbar(state, snackbar) {
      state.snackbar = {
        ...state.snackbar,
        ...snackbar,
      };
    },
  },

  actions: {
    async getLocation({ commit }) {
      try {
        await Geolocation.watchPosition(
          {
            enableHighAccuracy: true,
            timeout: 6000,
            maximumAge: 600,
          },
          (data) => {
            console.log(data);
            commit("getLocation", {
              latitude: data?.coords?.latitude,
              longitude: data?.coords?.longitude,
            });
          }
        );
      } catch (e) {
        console.log(e);
      }
    },

    async isLanguageSupported({ commit, state }) {
      try {
        let isCodeIncluded = false;

        const { languages } = await TextToSpeech.getSupportedLanguages();
        for (let i = 0; i < languages.length; i++) {
          if (languages[i].includes(state.mobileLang.code)) {
            isCodeIncluded = true;
          }
        }
        commit("setIsLanguageSupported", isCodeIncluded);
      } catch (e) {
        throw e;
      }
    },
  },
};
