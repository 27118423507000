<template>
  <div
    class="primary d-flex flex-column"
    style="width: 100%; max-width: 1200px"
  >
    <div class="d-flex align-center">
      <h1 class="text--text font-weight-bold text-h5 px-2">
        {{ $t("menu.islands") }}
      </h1>
      <v-btn icon @click="step = 1" v-if="step == 2" small>
        <v-icon color="text">mdi-arrow-left</v-icon>
      </v-btn>
    </div>
    <span class="caption text--text px-2 mb-2">
      {{ $t("pois.step2") }}
    </span>

    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="secondary" size="60">
      </v-progress-circular>
    </div>

    <v-container v-else>
      <v-row>
        <v-col
          v-for="(island, i) in islands"
          :key="i"
          cols="6"
          class="card d-flex flex-column"
          @click="onIslandSelect(island)"
        >
          <div
            class="card-2 d-flex flex-column align-center justify-center"
            style="border-radius: 20px; cursor: pointer"
          >
            <v-img
              :src="returnImage(island)"
              aspect-ratio="1"
              width="100px"
              height="auto"
            ></v-img>
            <!-- <v-icon color="white" size="45">{{ returnImage(island) }}</v-icon> -->
            <span
              class="secondary--text body-1 text-center font-weight-bold pb-2"
            >
              {{
                island.name.charAt(0).toUpperCase() +
                island.name.slice(1).toLowerCase()
              }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  async created() {
    try {
      this.loader = true;
      const areas = await axios.get(`${process.env.VUE_APP_BASE_URL}/areas`);
      const sortedAreas = areas.data.areas.sort((a, b) => {
        return a.weight - b.weight;
      });
      this.islands = sortedAreas;
    } catch (e) {
      console.log(e);
    }
    this.loader = false;
  },

  data() {
    return {
      step: 1,
      islands: [],
      categories: [],
      loader: false,
    };
  },

  methods: {
    ...mapMutations(["setFilters"]),

    returnImage(island) {
      switch (island.id) {
        case 5:
          return require("@/assets/island-icons/Kerkira.png");
        case 8:
          return require("@/assets/island-icons/Ithaki.png");
        case 6:
          return require("@/assets/island-icons/Kefalonia.png");
        case 7:
          return require("@/assets/island-icons/Lefkada.png");
        case 13:
          return require("@/assets/island-icons/Meganisi.png");
        case 9:
          return require("@/assets/island-icons/Paxos.png");
        case 4:
          return require("@/assets/island-icons/Zakinthos.png");
        default:
          return "mdi-island";
      }
    },
    onCategorySelect(category) {
      this.category = category;
      this.step = 2;
    },

    onIslandSelect(island) {
      this.$router.push(`/islands/area/${island.id}`);
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  height: 150px !important;
  width: 100px !important;
}

.card-2 {
  height: 100% !important;
  width: 100% !important;
  background: #e3f3fa;
  border-radius: 8px;
}
</style>
