<template>
  <v-card color="white">
    <v-text-field
      v-model="keyword"
      solo
      hide-details
      label="Search"
      append-icon="mdi-magnify"
      @keyup.enter="onSearch"
      clearable
      color="primary"
    >
      <template v-slot:prepend-inner>
        <v-icon class="pr-4" @click="$emit('close')">mdi-arrow-left</v-icon>
      </template>
      <template v-slot:append>
        <v-icon class="pr-4" @click="onSearch">mdi-magnify</v-icon>
      </template>

      <template v-slot:label>
        <span class="font-italic body-2 pl-4">
          {{ $t("general.search") }}
        </span>
      </template>
    </v-text-field>
    <div v-if="!loader && pois.length > 0" style="width: 100%">
      <div
        v-for="(poi, i) in pois"
        :key="i"
        class="d-flex flex-column mx-auto"
        style="width: 100%; max-width: 1200px; cursor: pointer"
      >
        <div
          @click="openDialog(poi)"
          class="d-flex justify-start align-center text--text route font-weight-bold body-1 mb-2"
        >
          <span class="mr-auto">{{ poi.name }}</span>
          <v-btn icon color="text">
            <v-icon> mdi-arrow-right</v-icon>
          </v-btn>
        </div>

        <v-divider
          v-if="i != pois.length - 1"
          class="mb-2"
          style="width: 100% !important"
          color="white"
        ></v-divider>
      </div>
    </div>

    <div v-else-if="loaded && pois.length == 0" class="pa-4 white--text">
      {{ $t("validation.no_pois") }}
    </div>

    <v-dialog
      persistent
      v-model="dialog.open"
      max-width="100%"
      fullscreen
      style="z-index: 900 !important"
    >
      <PoiDialog :ppoi="dialog.poi" v-if="dialog.open" @close="closeDialog" />
    </v-dialog>
  </v-card>
</template>

<script>
import PoiDialog from "@/components/PoiDialog.vue";

import axios from "axios";
export default {
  components: {
    PoiDialog,
  },

  created() {
    this.pois = [];
  },

  data() {
    return {
      pois: [],
      keyword: null,
      selectedFilter: null,
      loaded: false,
      loader: false,
      menu: false,

      dialog: {
        open: false,
        poi: null,
      },
    };
  },

  computed: {
    filters() {
      return [
        {
          label: this.$t("menu.islands"),
          value: "islands",
        },
        {
          label: this.$t("menu.pois"),
          value: "pois",
        },
      ];
    },
  },

  methods: {
    async onSearch() {
      this.loader = true;
      this.menu = false;
      try {
        //todo check pois/areas
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/pois`, {
          params: {
            name: this.keyword,
          },
        });
        this.pois = res.data.filter((el) => {
          if (el.category_id != 21) return el;
        });
        this.loaded = true;

        // this.pois = res.data;
      } catch (e) {
        console.log(e);
      }
      this.loader = false;
    },

    openDialog(poi) {
      this.dialog = {
        open: true,
        poi,
      };
    },

    closeDialog() {
      this.dialog = {
        open: false,
        poi: null,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.v-menu__content {
  width: 100% !important;
  border-radius: 0px !important;
}
</style>
