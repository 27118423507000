<template>
  <div class="d-flex flex-column mt-2" style="width: 100%; max-width: 1200px">
    <div class="d-flex flex-column">
      <v-btn icon @click="$router.push('/settings')">
        <v-icon color="secondary">mdi-arrow-left</v-icon>
      </v-btn>
      <h1 class="text--text font-weight-bold text-h5 ml-2">
        {{ $t("info_settings.thanks") }}
      </h1>
    </div>

    <v-divider class="mb-2"></v-divider>
    <div class="d-flex flex-column">
      <p class="body-2 mt-2 pa-2" v-if="mobileLang.code == 'el'">
        Εφορεία Αρχαιοτήτων Κέρκυρας<br />
        Εφορεία Αρχαιοτήτων Κεφαλληνίας και Ιθάκης<br />
        Εφορεία Αρχαιοτήτων Αιτωλοακαρνανίας και Λευκάδας<br />
        Εφορεία Αρχαιοτήτων Ζακύνθου <br />
        Υπηρεσία Νεότερων Μνημείων Ηπείρου, Βόρειου Ιονίου και Δυτικής
        Μακεδονίας <br />
        Εφορεία Παλαιοανθρωπολογίας – Σπηλαιολογίας - Τμήμα Αρχαιοτήτων των
        Προϊστορικών και Ιστορικών Περιόδων<br />
        Πινακοθήκη Δήμου Κεντρικής Κέρκυρας & Διαποντίων Νήσων Ιόνιο
        Πανεπιστήμιο <br />
        Ιερά Μητρόπολη Κέρκυρας, Παξών και Διαποντίων Νήσων <br />
        Ιερά Μητρόπολη Κεφαλληνίας <br />
        Ιερά Μητρόπολη Ζακύνθου <br />
        Ιερά Μητρόπολη Λευκάδος και Ιθάκης<br />
        Μονάδα Διαχείρισης Εθνικών Πάρκων Ζακύνθου, Αίνου και Προστατευόμενων
        Περιοχών Ιονίων Νήσων <br />
        Εθνικό Θαλάσσιο Πάρκο Ζακύνθου <br /><br />

        Δήμο Κεντρικής Κέρκυρας και Διαποντίων Νήσων<br />
        Δήμο Βόρειας Κέρκυρας<br />
        Δήμο Νότιας Κέρκυρας<br />
        Δήμο Παξών<br />
        Δήμο Λευκάδας<br />
        Δήμο Μεγανησίου<br />
        Δήμο Αργοστολίου<br />
        Δήμο Ληξουρίου<br />
        Δήμο Σάμης<br />
        Δήμο Ιθάκης<br />
        Δήμο Ζακύνθου <br /><br />

        Επιμελητήριο Κέρκυρας<br />
        Επιμελητήριο Λευκάδας<br />
        Επιμελητήριο Κεφαλονιάς και Ιθάκης<br />
        Επιμελητήριο Ζακύνθου <br /><br />

        MOBICS AE<br />
        Λέσχη Αρχιμαγείρων Κέρκυρας <br />
        Κοιν.Σ.Επ Leader Tourism<br />
        Ορειβατικό Σύλλογο Κέρκυρας<br />
        Apanemia Villa Lefkada<br />
        Corfu Palace Hotel <br /><br />

        Ευχαριστούμε για την συμβολή τους στην υλοποίηση και επιτυχή ολοκλήρωση
        του Έργου τους:<br />
        Αγραφιώτη Νανέττε - Αντωνία<br />
        Βουκουβάλα Κωνσταντίνο<br />
        Γραμματικού Τριανταφυλλιά<br />
        Κούρτη Λαμπρινή<br />
        Κρεμμύδα Γεώργιο<br />
        Λιάτσο Δημήτριο<br />
        Μοθωναίο Γεώργιο<br />
        Μπιμπή Γεώργιο<br />
        Ντούρο Αλέξανδρο<br />
        Νικολακοπούλου Σοφία<br />
        Ορτέντζιο Χρήστο<br />
        Παπαδέλλη Γεώργιο<br />
        Πατρόνα Νικόλαο<br />
        Πετούση Αθανάσιο<br />
        Προκοπίου Ιωάννη<br />
        Σβορώνο Θωμά<br />
        Λιάπη Σνεζάνα<br />
        Σούλη Γεώργιο<br />
        Σουτσέν Τσάνγκ (Xuchen Zheng)<br />
        Φαφούτη Παναγιώτη<br />
        Φώτου Κωνσταντίνα<br />
        <br /><br />

        Ιδιαίτερες ευχαριστίες για την επιμέλεια των κειμένων στους: <br />
        Σεβασμιώτατο Μητροπολίτη Κεφαλληνίας Δημήτριο<br />
        Βουτσινά Μαρία <br />
        Γαρνέλη Αφροδίτη<br />
        Γέργου Άντζελα<br />
        Ζαπάντη Αντρέα<br />
        Κολοκοτσά Παύλο<br />
        Κουλούρη Κασσιανή<br />
        Κούρτη Λαμπρινή<br />
        Κουβαρά Σπυρίδων<br />
        Λιβιτσάνου Γεωργία<br />
        Μουζάκη Κωνσταντίνο<br />
        Ξένο Σπυρίδων<br />
        Παπαδέλλη Γεώργιο<br />
        Παπαναστασάτου Άρτεμις<br />
        Πατέρα Ιωαννίκιο<br />
        Πατέρα Μουρτζάνο Θεμιστοκλή<br />
        Πετούση Αθανάσιο<br />
        Πολίτη Ματίνα<br />
        Σταματέλου Παναγιώτα<br />
        Τσίνα Καλλιόπη<br />
        Chapman Sue<br />
        Dadenkova Anya<br />
        Di-Val Alexia<br />
        Gallé-Tessonneau Étienne<br />
        Giordano Francesco<br />
        Juptner Carolin<br />
        Ljubomir Saramandic<br />
        Spencer John<br />
        Whittleworth Helen<br /><br />

        Να ευχαριστήσουμε επίσης για την συμμετοχή τους στα βίντεο τους: <br />
        Ανδριώτη Αναστάσιο<br />
        Βαγενά Ευαγγελία<br />
        Βαγενά Μαυρέττα<br />
        Βιτζιλαίο Βασίλη<br />
        Βουλισμά Σπυρίδων<br />
        Γαζή Αποστόλη<br />
        Γιαννάκη Κωνσταντίνα<br />
        Θεοδωράκη Αλέξη<br />
        Κάρκο Ιωάννη<br />
        Κοψιδά Παναγιώτη<br />
        Κούρτη Αθανασία<br />
        Κούρτη Λαμπρινή<br />
        Λάμπουρα Θωμά<br />
        Λεσσάι Καίτη<br />
        Παξινό Γεώργιο<br />
        Σκιαδαρέση Κωνσταντίνα<br />
        Caçador Afonso<br />
        Di-Val Alexia<br />
        Dzjubo Eva<br />
        Hosszu Reka<br />
        Juptner Carolin<br />
        και την Ida🐕.<br /><br />

        Ευχαριστούμε θερμά για τις φωτογραφίες-βίντεο που μας παραχώρησαν
        τους:<br />
        Εθνικό Θαλάσσιο Πάρκο Ζακύνθου<br />
        Κίντζιο Δημήτριο<br />
        Κολοβό Σπυρίδων<br />
        Κυριάκος Γεώργιος<br />
        Λυκούδη Μαίρη<br />
        Μπελεγρίνο Παναγιώτη<br />
        Μυλωνάκη Κώστα<br /><br />

        Ευχαριστούμε ιδιαίτερα τους:<br />
        Αργυρό Βασίλειο<br />
        Αρταβάνη Δημήτριο<br />
        Γαλανό Γεράσιμο<br />
        Γκαβανόζη Τηλέμαχο<br />
        Γράψα Γεράσιμο<br />
        Δρακάτο Γεώργιο<br />
        Κάνδηλα Δημήτριο<br />
        Καραβία Κωνσταντίνο<br />
        Κατωπόδη Αργυρή<br />
        Κασίμη Περικλή<br />
        Κολοκοτσά Αναστασία<br />
        Κώστα Ελισσάβετ<br />
        Κοτσούλης Ιωάννης<br />
        Μαυράκη Αλίκη – Νεφέλη<br />
        Μαυρομάτη Ουρανία<br />
        Παπαπάνο Γεώργιο<br />
        Ραδίτσα Νικόλαο<br />
        Ρίζο Θεόδωρο<br />
        Σκούπουρα Ανδρέα<br />
        Τρούσα Διονύσιο<br />
        Τσέτσο Βασίλειο<br />
        Boscolo Marco<br />
        Sourbes Laurent<br /><br />
      </p>

      <p class="body-2 mt-2 pa-2" v-else>
        Ephorate of Antiquities of Corfu<br />
        Ephorate of Antiquities of Cephalonia and Ithaca<br />
        Ephorate of Antiquities of Aetoloacarnania and Lefkada<br />
        Ephorate of Antiquities of Zakynthos<br />
        Ephorate of Prehistoric and Classical Antiquities of Corfu<br />
        Department of Paleoanthropology and Speleology <br />
        Municipal Art Gallery of Central Corfu and the Diapontian Islands Ionian
        University Holy Metropolis of Corfu<br />
        Holy Metropolis of Kefalonia<br />
        Holy Metropolis of Zakynthos and Strophades<br />
        Holy Metropolis of Lefkas and Ithaca<br />
        Management Unit of Zakynthos and Ainos National Parks and Protected
        Areas of the Ionian islands<br />
        National Marine Park of Zakynthos <br /><br />

        Municipality of Central Corfu and Diapontia Islands<br />
        Municipality of North Corfu<br />
        Municipality of South Corfu<br />
        Municipality of Paxos <br />
        Municipality of Lefkada<br />
        Municipality of Meganisi <br />
        Municipality of Argostoli <br />
        Municipality of Lixouri<br />
        Municipality of Sami <br />
        Municipality of Ithaca<br />
        Municipality of Zakynthos <br /><br />

        Chamber of Corfu<br />
        Chamber of Lefkada<br />
        Chamber of Kefalonia and Ithaca<br />
        Chamber of Zakynthos <br /><br />

        MOBICS AE<br />
        Corfu Chefs Club<br />
        Social Coop. Leader Tourism <br />
        Hiking and Mountaineering Association of Corfu<br />
        Apanemia Villa Lefkada<br />
        Corfu Palace Hotel<br /><br />

        Thank you for their contribution to the implementation and successful
        completion of their project:<br />
        Agrafiote Nanette - Antonia<br />
        Mpimpis Georgios<br />
        Douros Alexandros<br />
        Fafoutis Panagiotis<br />
        Fotou Konstantina<br />
        Grammatikou Triantafyllia<br />
        Kourti Lamprini<br />
        Kremmydas Georgios<br />
        Liatsos Dimitrios<br />
        Mothonaios Georgios<br />
        Nikolakopoulou Sophia<br />
        Ortentzio Christos<br />
        Papadellis Georgios<br />
        Patronas Nikolaos<br />
        Petousis Athanasios<br />
        Prokopiou Ioannis<br />
        Liapi Snezana <br />
        Svoronos Thomas<br />
        Soulis Georgios<br />
        Voukouvalas Konstantinos<br />
        Xuchen Zheng<br /><br />

        Special thanks for the editing of the texts to: <br />
        The archbishop of Kefalonia Dimitrios <br />
        Father Ioannikios <br />
        Farther Mourtzanos Themistoklis <br />
        Chapman Sue <br />
        Dadenkova Anya <br />
        Di-Val Alexia <br />
        Gallé-Tessonneau Étienne <br />
        Garneli Aphrodite <br />
        Gergou Angela <br />
        Giordanos Francescos <br />
        Juptner Carolin <br />
        Kolokotsa Pavlos <br />
        Koulouri Kassiani <br />
        Kouvaras Spyridon <br />
        Kourti Lambrini <br />
        Livitsanou Georgia <br />
        Ljubomir Saramandic <br />
        Mouzakis Konstantinos <br />
        Papadellis Georgios <br />
        Papanastasatou Artemis <br />
        Petousis Athanasios <br />
        Politi Matina <br />
        Spencer John <br />
        Stamatelou Panagiota <br />
        Tsina Kalliopi <br />
        Voutsina Maria <br />
        Whittleworth Helen <br />
        Xeno Spyridon <br />
        Zapantis Andreas <br /><br />

        To thank also for their participation in their videos: <br />
        Andrioti Anastasio<br />
        Caçador Afonso<br />
        Di-Val Alexia<br />
        Dzjubo Eva<br />
        Gazis Apostolis Giannaki Konstantina<br />
        Hosszu Reka<br />
        Juptner Carolin<br />
        Karkos Ioannis<br />
        Kopsidas Panagiotis<br />
        Kourti Athanasia<br />
        Kourti Lambrini<br />
        Lambouras Thomas <br />
        Lessai Kaiti <br />
        Paxinos Georgios<br />
        Skiadaresi Konstantina<br />
        Theodoraki Alexi<br />
        Vagena Evangelia<br />
        Vagena Mauretta<br />
        Vitzilaios Vasilis<br />
        Voulismas Spyridon and Ida🐕.<br /><br />

        Heartfelt thanks for the photos-videos provided by: <br />
        National Marine Park of Zakynthos <br />
        Belegrinos Panagiotis <br />
        Kintzios Dimitrios <br />
        Kolovos Spyridon <br />
        Kyriakos Georgios <br />
        Lykoudi Mairi <br />
        Mylonakis Kostas <br /><br />

        Special thanks to: <br />
        Argyros Vasileios <br />
        Artavanis Dimitrios <br />
        Boscolo Marco <br />
        Drakatos Georgios <br />
        Galanos Gerasimos <br />
        Gavanozi Tilemachos <br />
        Grapsas Gerasimos <br />
        Kandila Dimitrio <br />
        Karavias Konstantinos <br />
        Kasimi Perikli <br />
        Katopodi Argyri <br />
        Kolokotsa Anastasia <br />
        Kosta Elisavet <br />
        Kotsoulis Ioannis <br />
        Mavraki Aliki – Nefeli <br />
        Mavromati Ourania <br />
        Papapanos Georgios <br />
        Raditsa Nikolaos <br />
        Rizos Theodoros <br />
        Skoupara Andrea <br />
        Sourbes Laurent <br />
        Trousas Dionisios <br />
        Tsetsos Vasileios <br /><br />
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      thanks: "",
    };
  },

  computed: {
    ...mapState({
      mobileLang: (state) => state.base.mobileLang,
    }),
  },
};
</script>

<style></style>
